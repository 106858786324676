import React from 'react'
import { Location } from '@reach/router'
// eslint-disable-next-line import/no-unresolved
import { ThemeModePrvider } from 'components/presenter/theme/themeMode'
import { SnackbarProvider } from 'notistack'
// eslint-disable-next-line import/no-unresolved
// import AuthRedirection from 'components/container/router/AuthRedirection'

import { EventTrackerProvider } from 'libs/tracker/provider'
import { trackers } from 'libs/tracker/utils/event'
import Application from 'components/container/app/Application'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
// export const wrapPageElement = ({ element, props }) => {
//   const { location } = props
//   return (
//     <AuthRedirection {...props}>
//       {element}
//     </AuthRedirection>
//   )
// }

export const wrapRootElement = ({ element }) => (
    <ThemeModePrvider>
      <SnackbarProvider 
        maxSnack={3}
      >
        <Location>
          {
            loc => 
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <EventTrackerProvider location={loc.location} trackers={trackers}>
                    <Application>
                      {element}
                    </Application>
                  </EventTrackerProvider>
               </MuiPickersUtilsProvider>
          }
        </Location>
      </SnackbarProvider>
    </ThemeModePrvider>
  )

export const onPreRenderHTML = ({ getHeadComponents, replaceHeadComponents }) => {
  const headComponents = getHeadComponents()
  
  // Reorder material-ui order
  const index = headComponents.findIndex((comp) => comp.type === 'style' && comp.key === 'jss-server-side')
  if (index > 0) {
    const muiStyle = headComponents[index]
    headComponents.splice(index, 1)
    replaceHeadComponents([
      muiStyle,
      ...headComponents
    ])
  }
  
}