import * as React from 'react'
import { Avatar } from '@material-ui/core'
import FillButton from 'components/presenter/buttons/FillButton'
import cn from 'classnames'
import CropperDialog from '../CropperDialog'
import * as styles from './upload.module.css'

const UploadAvatar = ({
  className, name='', url = '', uploadBtnText = '',
  onCrop,
  cropOption = {}
}) => {
  const [isCropOpen, setCropOpen] = React.useState(false)
  const [imgaeUrl, setImageUrl] = React.useState('')

  const inputEl = React.useRef(null)

  const handleClickOpenCropDialog = () => {
    setCropOpen(true)
  }

  const handleCloseCropDialog = () => {
    setCropOpen(false)
  }

  const onSelectImage = (event) => {
    const reader = new FileReader()
    reader.onload = () => {
      setImageUrl(reader.result)
      handleClickOpenCropDialog()
    }
    reader.readAsDataURL(event.target.files[0])
  }

  const triggerOpenFileDialog = () => {
    inputEl.current.click()
  }

  // eslint-disable-next-line no-underscore-dangle
  const _onCrop = (blob) => {
    handleCloseCropDialog()
    onCrop(blob, imgaeUrl)
  }

  return (
    <div className={cn(styles.upload, className)}>
      <Avatar className={styles.profileImage} alt={name} src={url} />
      <FillButton 
        className={cn(styles.btnUpload)}
        onClick={triggerOpenFileDialog}
      >
        {uploadBtnText}
        <input
          ref={inputEl}
          className={styles.inputFile}
          type="file"
          id={name} name={name}
          accept="image/png, image/jpeg"
          onChange={onSelectImage} />
      </FillButton>
      {
        isCropOpen &&
        <CropperDialog
          src={imgaeUrl}
          onClose={handleCloseCropDialog}
          onCrop={_onCrop}
          cropOption={cropOption}
        /> 
      }
    </div>
  )
}

export default UploadAvatar