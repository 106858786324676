exports.FRAGMENT_CORE = `
fragment fragmentCore on Test {
  _id
}
`

exports.FRAGMENT_HOME_AFFILIATE_LIST_CORE = `
fragment fragmentHomeAffiliateListCore on AffiliateProgram {
  _id
  company {
    _id
    name 
    domain
    description
    company_logo {
      _id
      url
    }
  }
  rewards {
    cashback_value
    cashback_value_changed
    cashback_type
    currency_unit
    product_type
  }
  created_at
  updated_at	
}
`

exports.FRAGMENT_AFFILIATE_DEAL_CORE = `
fragment fragmentAffiliateDealCore on AffiliateDeal {
  _id
  affiliate_url
  end_at
  image_url
  start_at
  sub_title
  title
  created_at
}
`

exports.FRAGMENT_AFFILIATE_PROMO_CODE_CORE = `
fragment fragmentAffiliatePromoCodeCore on AffiliatePromoCode {
  _id
  code
  end_at
  terms
  description
  discount
  created_at	
}
`

exports.FRAGMENT_HOME_BANNER_CORE = `
fragment fragmentHomeBannerCore on HomeBanner {
  _id
  url
  start_at
  end_at
  created_at
  order
  image {
    _id
    url
  }
  title
}
`

exports.FRAGMENT_USER_PROFILE_CORE = `
fragment fragmentUserProfileCore on User {
  _id
  name
  email
  is_bank_tax_created
  sale_mode
  profile_image {
    _id
    url
    created_at
    mime
  }
  created_at
  updated_at
}
`

exports.FRAGMENT_COMPANY_PROFILE_CORE = `
fragment fragmentCompanyProfileCore on Company {
  _id
  name
  email
  telephone
  description
  created_at
  profile_image {
    url
  }
  url
}
`

exports.FRAGMENT_LOCATION_CORE = `
fragment fragmentLocationCore on Location {
  _id
  name
  telephone
  address
  geo_location {
    lat
    lng
  }
  regionals {
    name
    level
    short_code
  }
}
`

exports.FRAGMENT_NEWS_CORE = `
fragment fragmentNewsCore on NewsPost {
  _id
  company {
    _id
    name
    profile_image {
      _id
      url
    }
  }
  posters {
    _id
    url
  }
  category {
    name
    parent {
      name
      order
      short_code
    }
    order
    short_code
  }
  keywords {
    name
    short_code
    order
    parent {
      name
      order
      short_code
    }
  }
  tags {
    name
  }
  posters {
    _id
    url
    mime
  }
  locations {
    _id
    name
    telephone
    address
    geo_location {
      lat
      lng
    }
    regionals {
      name
      level
      short_code
    }
  }
  stats {
    views
  }
  meta {
    limited {
      voucher {
        name
        order
        short_code
      }
      artist {
        name
        order
        short_code
      }
    }
    care {
      name
      order
      short_code
    }
    age {
      name
      order
      short_code
    }
    gender {
      name
      order
      short_code
    }
  }
  title
  description
  url
  is_pause
  is_published
  is_banned
  promote_start_at
  promote_end_at
  publish_at
}
`

exports.FRAGMENT_NEWS_STATS_ONLY = `
fragment fragmentNewsStatsOnly on NewsPost {
  category {
    name
    parent {
      name
      order
      short_code
    }
    order
    short_code
  }
  keywords {
    name
    short_code
    order
    parent {
      name
      order
      short_code
    }
  }
  meta {
    limited {
      voucher {
        name
        order
        short_code
      }
      artist {
        name
        order
        short_code
      }
    }
    care {
      name
      order
      short_code
    }
    age {
      name
      order
      short_code
    }
    gender {
      name
      order
      short_code
    }
  }
  stats {
    views
  }
  is_pause
  is_published
  is_banned
}
`


exports.FRAGMENT_NEWS_LIST_CORE = `
fragment fragmentNewsListCore on NewsPost {
  _id
  posters {
    _id
    url
  }
  title
  category {
    name
    parent {
      name
      order
      short_code
    }
    order
    short_code
  }
  publish_at
  stats {
    views
  }
}
`