// Error codes constant
exports.NotMatchPassword = 10001
exports.NotMatchConfirmPassword = 10002
exports.ExceedMaxLengthLocal = 10003
exports.InvalidDate = 10004

exports.InvalidEmail = 20001
exports.NonEmptyField = 20002
exports.MinPasswordLength = 20003
exports.InvalidJwt = 20004
exports.AccountAlreadyExist = 20005
exports.AccountNotFound = 20006
exports.AccountNotMatch = 20007
exports.CanOnlyCreateOneCompany= 20008
exports.NoAccessPermission = 20009
exports.EitherOneHasValue = 20010
exports.WrongOldPassword = 20011
exports.NewPasswordShouldNotBeSameOld = 20012
exports.ExceedMaxLength = 20013
exports.CompanyNotFound = 20014
exports.SomeNewCategoriesCodesNotValid = 20015
exports.SomeRegionalCodesNotValid = 20016
exports.SomeNewKeywordCodesNotValid = 20017
exports.SomeLocationIdsNotValid = 20018
exports.LocationNotFound = 20019
exports.EndTimeShouldAfterStartTime = 20020
exports.NewsNotFound = 20021
exports.InvalidUrl = 20022
exports.InvalidTimePeriod = 20023
exports.SomeNewMetaCodesNotValid = 20024


exports.InternalSystemError = 50000
exports.SQLSyntaxError = 50001
exports.InvalidAuthorization = 50002
exports.AuthorizationNotFound = 50003
exports.FailUploadResource = 50004