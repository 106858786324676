import React from 'react'
import {
  Dialog,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SearchBar from 'components/presenter/form/SearchBar'
import { useSearchBar } from 'app/hook/searchHook'
import * as styles from './styles.module.css'

const PopupSearch = ({
  location,
  onClose = () => {}
}) => {
  const {searchData, onTextChange, handleOnSearch } = useSearchBar(location)
  
  const onSearch = (data) => {
    handleOnSearch(data)
    onClose()
  }

  return (
    <Dialog
      fullScreen
      open>
        <div className={styles.base}>
          <IconButton
            className={styles.icon}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <SearchBar
            classes={{
              root: styles.searchRoot,
              search: styles.search,
            }}
            enableSearchIcon={false}
            enableSearchBtn
            {...searchData}
            onSearch={onSearch}
            onTextChange={onTextChange}
          />
        </div>
    </Dialog>
  )
}

export default PopupSearch