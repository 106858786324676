import React from 'react'
import { TextField } from '@material-ui/core'
import cn from 'classnames'

const InputField = (props) => {
  const { children, className } = props
  return (
    <TextField
      {...props}
      className={cn('mui-textfield', className)}
      size="small"
      variant="outlined"
    >
      {children}
    </TextField>
  )
}   


export default InputField