import React from 'react'
import cn from 'classnames'
import {
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import FillButton from 'components/presenter/buttons/FillButton'


import * as styles from './styles.module.css'

const SearchBar = ({
  classes = {},
  className,
  text,
  isReadOnly = false,
  enableSearchIcon = true,
  enableSearchBtn = false,
  onClick = () => {},
  onTextChange = () => {},
  onSearch = () => {}
}) => {
  const { t } = useTranslation()
  const textRef = React.useRef()

  const handleOnSearch = () => {
    textRef.current.blur()
    onSearch({
      text: textRef.current.value
    })
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      handleOnSearch()
    }
  }

  return (
    <div
      className={cn(styles.search, isReadOnly ? styles.searchBarReadOnly : '', className, classes.root)}
      onClick={isReadOnly ? onClick : null }
    >
      <TextField
        className={cn("mui-textfield", styles.searchBar, classes.search)}
        variant="outlined"
        placeholder={t('search.searchHint')}
        type="text"
        disabled={isReadOnly}
        defaultValue={text || ''}
        inputRef={textRef}
        onKeyPress={handleKeyPress}
        onChange={(event) => onTextChange(event.target.value)}
        InputProps={{
          endAdornment:
            enableSearchIcon ? <InputAdornment position="end">
              <IconButton 
                className={styles.searchIcon} 
                type="submit"
                onClick={isReadOnly ? onClick : handleOnSearch}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment> : null         
        }}
      />
      {
        enableSearchBtn 
        ? <FillButton
            className={cn(styles.btn, classes.searchBtn)}
            onClick={handleOnSearch}
          >
            {t('search.search')}
          </FillButton>
        : <></>
      }
    </div>
  )
}
export default SearchBar