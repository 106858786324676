const fetch = require('node-fetch')
const Sentry = require('@sentry/react')
const { InternalSystemError } = require('../libs/error/errorCodes')

const handleFetchError = (err, bodyJson) =>  {
  // Send exception to sentry
  Sentry.configureScope((scope) => {
    scope.setExtra('body', bodyJson)
  })
  Sentry.captureException(err)

  // Return error response w/ no status code
  return {
    error: {
      isRequestFail: true
    },
  }
}
  


const handleFetchUnauthorized = (url) => ({
  error: {
    isUnauthorized: true
  },
})
  


const handleFetchResponse = (data, bodyJson) => {
  if (!data.errors) {
    // If request success (code *2xx*),
    // return fetch response
    return data
  }

  const isSystemError = data.errors.some(err => err.extensions.code === 'GRAPHQL_VALIDATION_FAILED' || err.extensions.code >= InternalSystemError)
  // If status code greater than threshold,
  // send exception to sentry
  if (isSystemError) {
    Sentry.configureScope((scope) => {
      scope.setExtra('payload', JSON.stringify(data))
      scope.setExtra('body', bodyJson)
    })
    Sentry.captureException(new Error(`Request system error`))
  }

  // Return error response w/ status code
  return {
    error: {
      errors: data.errors,
      isSystemError,
    },
  }
}

function fetchTimeout (url, options, ms) {
  const controller = new AbortController()
  setTimeout(() => controller.abort(), ms)
  return fetch(url, { signal: controller.signal, ...options })
}

const fetchData = async (url, method, bodyJson, headers, options = {}) => {
  const lang = options.lang ? `?lang=${options.lang}` : ''
  const requestUrl = `${url}${lang}`
  try {
    const request = {
      headers,
      method,
    }

    if (options.format && options.format === 'formData') {
      request.body = bodyJson
    } else {
      request.body = JSON.stringify(bodyJson)
    }
    // Send fetch request w/ *application/json* content type
    let res
    if (options.timeout) {
      res = await fetchTimeout(requestUrl, request, options.timeout)
    } else {
      res = await fetch(requestUrl, request)
    }

    if (res.status === 401) {
      return handleFetchUnauthorized(requestUrl)
    }
    // Extract JSON data from fetch response
    const data = await res.json()

    // Return fetch response
    return handleFetchResponse(data, bodyJson)  
  } catch (err) {
    // Handle fetch error
    return handleFetchError(err, bodyJson)
  }
}
exports.fetchData = fetchData

exports.postData = (url, bodyJson, headers, options = {}) => {
  let tempHeaders
  if (options.format && options.format === 'formData') {
    tempHeaders = headers
  } else {
    tempHeaders = {
      ...headers,
      'Content-Type': 'application/json',
    }
  }
  return fetchData(url, 'post', bodyJson, tempHeaders, options)
}
