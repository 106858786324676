import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import BannerForm from 'components/presenter/form/banner/BannerForm'
import BannerFormContent from 'components/presenter/form/banner/BannerFormContent'
import Page from 'components/presenter/page/Page'

const ForgetPasswordSuccessPage = ({ location }) => {
  const { t } = useTranslation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')
  
  return (
    <Page>
      <BannerForm>
        <BannerFormContent
          title={t('forgetSuccess.title')}
          subTitle={t('forgetSuccess.description', {
            email
          })}
        />
      </BannerForm>
    </Page>
  )
}


export default ForgetPasswordSuccessPage