import * as React from 'react'
import cn from 'classnames'
import Page from 'components/presenter/page/Page'
import FillButton from 'components/presenter/buttons/FillButton'
import PopupDetailInvoicePage from './PopupDetailInvoicePage'
import { Paper, Box } from '@material-ui/core'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { DataGrid } from '@mui/x-data-grid'
import { useI18next } from 'gatsby-plugin-react-i18next'
import * as styles from './profile.module.css'
import { displayDateWithTime } from 'libs/date/dateUtil'
import { useSnackbar } from 'libs/ui/snackbar'
import { apiManager } from 'api'
import { useUserStore, useAuthStore } from 'stores'

const minThreshold = 7 // 7 USD

const InvoicePage = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const authStore = useAuthStore()
  const userStore = useUserStore()
  const { showSuccess } = useSnackbar()
  const [accountOverview, setAccountOverview] = React.useState(null)
  const [invoices, setInvoices] = React.useState({
    records: [
      // {
      //   _id: '001',
      //   order_id: 'ORD001',
      //   order_at: new Date('2023-01-15'),
      //   company: { name: 'Tech Corp' },
      //   sale_amount: 1500,
      //   total_commission: 150,
      //   commission_rate: 10,
      //   status: 2,
      //   product_name: 'Gadget Pro',
      //   created_at: new Date('2023-01-10'),
      //   bank_transfer_at: new Date('2023-01-20')
      // },
      // {
      //   _id: '002',
      //   order_id: 'ORD002',
      //   order_at: new Date('2023-02-15'),
      //   company: { name: 'Innovate Ltd' },
      //   sale_amount: 2000,
      //   total_commission: 200,
      //   commission_rate: 10,
      //   status: 1,
      //   product_name: 'Innovation Kit',
      //   created_at: new Date('2023-02-10'),
      //   bank_transfer_at: new Date('2023-02-20')
      // }
    ],
    total: 0
    // total: 2
  })
  const userProfile = userStore.getUserProfile()
  const campaignsPass = userStore.getCampaignPass() || []

  const [loading, setLoading] = React.useState(true)
  const [invoiceId, setInvoiceId] = React.useState(null)
  const [withdrawCampaigns, setWithdrawCampaigns] = React.useState([])

  React.useEffect(() => {
    const filteredCampaigns = campaignsPass.filter(pass => {
      const expiredAt = new Date(pass.expired_at)
      return pass.campaign.type === 2 && pass.quota > 0 && expiredAt > new Date()
    })
    setWithdrawCampaigns(filteredCampaigns)
  }, [campaignsPass])

  const totalClaimableCommission = accountOverview ? (accountOverview?.balance_by_purchase + accountOverview?.balance_by_share) : 0

  const getOverview = async () => {
    setLoading(true)
    const res = await apiManager.getInvoicesOverview()
    setAccountOverview(res.get_account_overview)
    setInvoices(res.get_invoices)
    setLoading(false)
  }

  console.log(withdrawCampaigns)

  const renderWithdrawThreshold = () => {
    if (withdrawCampaigns.length > 0) {
      return (
        <span>
          <Trans
            i18nKey="invoice.withdrawFormat"
            values={{
              old: t('invoice.minWithdraw'),
              new: t('invoice.thresholdFreeWithdraw')
            }}
            components={{
              old: <span className={styles.oldWithdrawal} />,
              new: <span className={styles.newWithdrawal} />,
            }}
          />
        </span>
      )
    }

    return (
      <span>{t('invoice.minWithdraw')}</span>
    )
  }

  React.useEffect(async () => {
    if (authStore.isInitial()) {
      await getOverview()
    }
  }, [authStore.isInitial()])

  const onPaginationModelChange = React.useCallback(async (model) => {
    if (invoices.records.length >= (model.page + 1) * model.pageSize) {
      return
    }
    
    setLoading(true)
    const lastID = invoices.records[invoices.records.length - 1]._id
    const res = await apiManager.getInvoices({
      limit: 15,
      lastID,
    })
    invoices.records = [...invoices.records, ...res.get_invoices.records]
    setInvoices(invoices)
    setLoading(false)
  }, [invoices])

  const onRequestWithdraw = React.useCallback(async () => {
    const res = await apiManager.requestWithdraw()
    if (res.request_withdraw) {
      showSuccess(t('common.success'))
      await getOverview()
    }
  }, [invoices])


  const columns = React.useMemo(() => [
    {
      field: 'created_at',
      headerName: t('report.withdrawAt'),
      valueGetter: (value) => value ? displayDateWithTime(value) : '-',
    },
    {
      field: 'total_commission',
      headerName: t('report.commissionAmount'),
      valueGetter: (value) => value ? `${value} ${accountOverview?.balance_unit}` : '-',
    },
    {
      field: 'bank_transfer_status',
      headerName: t('report.status'),
      valueGetter: (value) => value === 2 ? t('report.statusSettled') : t('report.statusSettling')
    },
    {
      field: 'reference_number',
      headerName: t('report.referenceNumber'),
      valueGetter: (value) => value ? value : '-',
    },
    {
      field: 'bank_transfer_at',
      headerName: t('report.bankTransferAt'),
      valueGetter: (value) => value ? displayDateWithTime(value) : '-',
    },
    {
      field: 'actions',
      headerName: t('common.action'),
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <FillButton onClick={() => setInvoiceId(params.row._id)}>
          {t('common.detail')}
        </FillButton>
      ),
    }
  ], [t, language, accountOverview?.balance_unit])

  const isWithdrawEnable = userProfile?.is_bank_tax_created && (totalClaimableCommission >= minThreshold || (withdrawCampaigns.length > 0 && totalClaimableCommission > 0))

  return (
    <>
      {invoiceId && <PopupDetailInvoicePage invoiceId={invoiceId} onClose={() => setInvoiceId(null)} />}
      <Page className={cn(styles.root, 'container')} seoMeta={{
        title: t('common.invoice')
      }}>
        {accountOverview && (
          <>
            <div className={styles.container}>
              <Paper className={styles.stat} elevation={2}>
                <h2>
                  {t('report.claimableCommission')}
                </h2>
                <div className={styles.content}>
                  <div>
                    <p>{`${totalClaimableCommission} ${accountOverview?.balance_unit}`}</p>
                    {renderWithdrawThreshold()}<br />
                    {!userProfile?.is_bank_tax_created && <span className={styles.error}>{t('invoice.notYetSetupBank')}</span>}
                  </div>
                  <FillButton 
                    disabled={!isWithdrawEnable}
                    onClick={onRequestWithdraw}
                  >
                    {t('invoice.widthdraw')}
                  </FillButton>
                </div>
              </Paper>
            </div>
            <Box className={styles.table}>
              <DataGrid
                rows={invoices?.records}
                columns={columns}
                loading={loading}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 15,
                    },
                  },
                }}
                getRowId={(row) => row._id}
                onPaginationModelChange={onPaginationModelChange}
                disableColumnFilter
                disableColumnSorting
                disableColumnMenu
                disableRowSelectionOnClick
                autoHeight
                autosizeOnMount
                autosizeOptions={{
                  includeHeaders: true,
                  expand: true,
                }}
              />
            </Box>
          </>
        )}
      </Page>
    </>
  )
}

export default InvoicePage