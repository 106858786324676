import React from 'react'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import BannerForm from 'components/presenter/form/banner/BannerForm'
import BannerFormContent from 'components/presenter/form/banner/BannerFormContent'
import FillButton from 'components/presenter/buttons/FillButton'
import cn from 'classnames'
import Page from 'components/presenter/page/Page'
import * as styles from './success.module.css'


const ResetPasswordSuccessPage = ({ location }) => {
  const { t } = useTranslation()
  const { navigate } = useI18next()

  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')

  /**
   *
   */
  function redirect() {
    navigate('/')
  }
  
  return (
    <Page seoMeta={{
      title: t('resetPasswordSuccess.title')
    }}>
      <BannerForm>
        <BannerFormContent
          title={t('resetPasswordSuccess.title')}
          subTitle={t('email-verify.description', {
            email
          })}
        >
          <FillButton
            className={cn(styles.btnPost)}
            onClick={redirect}
          >
            {t('email-verify.navigate')}
          </FillButton>
        </BannerFormContent>
      </BannerForm>
    </Page>
  )
}

export default ResetPasswordSuccessPage