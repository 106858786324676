import React, { useContext, useEffect, useState } from 'react'
import {Helmet} from 'gatsby-plugin-react-i18next'
import { setLocalCache, getLocalCache, themeLocalKey } from 'stores/localStorage'

/** @type {string} */
export const LightMode = 'light'
/** @type {string} */
export const DarkMode = 'dark'

const ThemeModeContext = React.createContext({
  theme: LightMode,
  setThemeMode: () => {},
  toggleThemeMode: () => {}
})

export const ThemeModePrvider = ({children}) => {
  const [themeMode, _setThemeMode] = useState(null)

  const setThemeMode = (mode) => {
    setLocalCache(themeLocalKey, mode)
    _setThemeMode(mode)
  }

  const toggleThemeMode = () => {
    setThemeMode(themeMode === LightMode ? DarkMode : LightMode)
  }

  let cacheMode = LightMode
  cacheMode = getLocalCache(themeLocalKey) || cacheMode

  const mode = themeMode || cacheMode
  const value = { themeMode: mode, toggleThemeMode, setThemeMode }

  const getModeClass = (_mode) => _mode === 'dark' ? 'themeDark' : 'themeLight'
  
  useEffect(() => {
    // Init to set theme mode
    if (!themeMode) {
      setThemeMode(mode)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <ThemeModeContext.Provider value={value}>
      <>
        <Helmet>
          <body className={getModeClass(mode)} />
        </Helmet>
        {children}
      </>
    </ThemeModeContext.Provider>
  )
}

export const useThemeMode = () => useContext(ThemeModeContext)