// extracted by mini-css-extract-plugin
export const loginAppIcon = "signup-module--loginAppIcon--yzPSD";
export const signupGroup = "signup-module--signupGroup--Rv8MI";
export const txtTitle = "signup-module--txtTitle--O7OBo";
export const txtSubTitle = "signup-module--txtSubTitle--gpvy6";
export const input = "signup-module--input--pWA+c";
export const txtTermPrivacy = "signup-module--txtTermPrivacy--fuVwz";
export const btnSignup = "signup-module--btnSignup--RW2Lf";
export const txtHaveAcc = "signup-module--txtHaveAcc--Y+Sl7";
export const tAndCGroup = "signup-module--tAndCGroup--YlGTL";
export const errorMsg = "signup-module--errorMsg--lgsNG";