import * as React from 'react'
import LoadingWrapper from 'components/presenter/form/LoadingWrapper'

const Form = ({className, classes = {}, children, isLoading = false}) => (
    <LoadingWrapper 
      classes={classes}
      isLoading={isLoading} 
    >
      <form className={className}>
        {children}
      </form>
    </LoadingWrapper>
  )

export default Form