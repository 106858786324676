import React from 'react'
import EventTracker from './eventTracker'
import { requestIdleCallback } from '../timeout'

const EventTrackerContext = React.createContext(null)

export const EventTrackerProvider = ({children, location, trackers}) => {
  const [eventTracker,] = React.useState(new EventTracker(trackers))
  const [prevLocation, setPreLocation] = React.useState(null)
  React.useEffect(() => {
    if(typeof window !== "undefined") {
      setTimeout(() => {
        requestIdleCallback(() => {
          eventTracker.deferInit()
        })
      }, 1500)
    }
  }, [])

  React.useEffect(() => {
    const {pathname} = location
    const prePathname = prevLocation?.pathname

    if (pathname !== prePathname) {
      eventTracker.sendPageViewEvent({
        pagePath: pathname
      })
      setPreLocation(location)
    }
  }, [location])
  return (
    <EventTrackerContext.Provider value={eventTracker}>
      {children}
    </EventTrackerContext.Provider>
  )
}

export const useEventTracker = () => React.useContext(EventTrackerContext)