import React from 'react'
import { Button } from '@material-ui/core'
import cn from 'classnames'
import * as styles from './button.module.css' 


const FillButton = ({ children, className = '', disabled, onClick = null}) => (
    <Button 
      className={cn(styles.fillButton, className)}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  )

export default FillButton