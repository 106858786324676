import React from 'react'
import cn from 'classnames'
import * as styles from './styles.module.css'

const TermPage = () => (
  <div className={cn(styles.root, 'container')}>
    <h1><strong>Coyns General Terms of Use</strong></h1>
    <p>&nbsp;</p>
    <p><span style="font-weight: 400;">Coyns offers, amongst other things, cashback to Coyns account holders for purchases made at Coyns’s merchant partners through Coyns’s various platforms including but not limited to its website, browser extension and mobile application (the “Coyns Platforms”). The use of the services (the “Services”) offered on the Coyns Platforms and accounts maintained with Coyns shall be subject to these terms and conditions (the “Agreement”). This Agreement constitutes a legally binding agreement between each individual who uses the Services or who maintains an account with Coyns. By using any of the Services or maintaining an account with Coyns, you acknowledge that you have read and understood this Agreement and agree to be bound by all of its terms. Coyns may modify this Agreement from time to time, with or without notice, and your continued use of any of the Services or continued maintenance of an account with Coyns shall constitute and be deemed to be your acceptance of such modification and your consent to abide by any terms thereof.</span></p>
    <h2> Service License</h2>
    <p><span style="font-weight: 400;">Subject to this Agreement, Coyns hereby grants you a revocable, non-exclusive, non-transferable license (without the right to sublicense) to access and use the Coyns Platforms and the Services solely for your personal use. You agree that you obtain no rights other than the rights and licenses expressly granted under this Agreement. Coyns reserves the right to change, upgrade or discontinue any or all of the Services or the Coyns Platforms or any feature of the Services or the Coyns Platforms, at any time, with or without notice. All rights not expressly granted under this Agreement are reserved by Coyns and its licensors.</span></p>
    <h2> Coyns Account</h2>
    <p><span style="font-weight: 400;">Some of the Services require the user to maintain an account with Coyns. A user may create an account free of charge by providing the relevant details requested for. Use of the account and the Services (including the opportunity to earn cashback) are offered at the sole discretion of Coyns and subject to your compliance with this Agreement.</span></p>
    <p><span style="font-weight: 400;">An individual shall not create or have control and/or custody over multiple Coyns accounts. In particular:</span></p>
    <p><span style="font-weight: 400;">(a) Each mobile number may only be linked to one Coyns account;</span></p>
    <p><span style="font-weight: 400;">(b) Each payment account (e.g. each bank account; each paypal account) may only be linked to one Coyns account'</span></p>
    <p><span style="font-weight: 400;">A failure to abide by any terms and conditions, any fraud or abuse relating to the accrual or receipt of cashback and/or other rewards, and/or any misrepresentation of any information furnished to Coyns or its affiliates may result in your Coyns account being flagged for suspicious activity and suspended or terminated and any cashback accumulated being forfeited.</span></p>
    <p><span style="font-weight: 400;">You are responsible for regularly checking your account to ensure cashback has been properly credited and that your account balance is up to date. You must notify Coyns of any omissions, incorrect entries or discrepancies concerning any transaction within 30 days after that transaction. Coyns reserves the right not to correct any omissions, incorrect entries or discrepancies brought to its attention after the 30-day period.</span></p>
    <h2> Earning Cashback</h2>
    <p><span style="font-weight: 400;">Among services offered by Coyns is the opportunity to earn cashback on purchases made at our various merchant partners through our various Coyns Platforms. Creating and maintaining an account with Coyns is required before a user can start earning cashback. Crediting of cashback is also subject to the various terms, conditions and requirements, express or implied, associated with the crediting of cashback in general or terms, condition and requirements associated with the individual promotions that would be applicable. These terms, conditions and requirements include but are not limited to:</span></p>
    <p><span style="font-weight: 400;">(c) Requiring the user to have cookies enabled throughout when accessing the Coyns Platforms and subsequently the merchant partner’s website;</span></p>
    <p><span style="font-weight: 400;">(d) Not opening a separate tab or browser while accessing the Coyns Platforms and subsequently the merchant partner’s website;</span></p>
    <p><span style="font-weight: 400;">(e) Ensuring that after being redirected to the merchant partner’s website, the user completes the transaction before proceeding to any other website;</span></p>
    <p><span style="font-weight: 400;">(f) Completing the purchase on the merchant’s website within a given period of time;</span></p>
    <p><span style="font-weight: 400;">(g) Not engaging in any fraudulent or dishonest conduct and/or abiding by the terms of this Agreement or any other terms and conditions stipulated by Coyns or the applicable merchant partner;</span></p>
    <p><span style="font-weight: 400;">In addition, certain purchases may be excluded from cashback. In general, cashback is earned on net purchase amount excluding shipping and handling. However, cashback amounts and exclusions vary from merchant partner to merchant partner and individual promotions may contain specific exclusions. Please review these terms carefully. These exclusions are subject to change without notice, and Coyns hereby disclaims any and all liability in connection with any incorrect information or failure to include information on the list of exclusions. Some examples of typical exclusions include (but are not limited to):</span></p>
    <p><span style="font-weight: 400;">(a) Cashback not being eligible when purchasing promotional items or using other discount codes, coupons or other promotions;</span></p>
    <p><span style="font-weight: 400;">(b) Cashback only being eligible when the transaction is completed using specified payment methods; and<br />(c) Cashback not being eligible when a legitimate or valid transaction is not deemed by Coyns to have been made (e.g. where a user makes a fraudulent or dishonest transaction to earn and/or redeem cashback); <br />and<br /></span></p>
    <p><span style="font-weight: 400;">(d) Different tiers of eligible cashback for different users (e.g. different tiers of cashback for new users and existing users)</span></p>
    <h2> Merchant Partner Policies</h2>
    <p><span style="font-weight: 400;">Any products or services purchased from any merchant partner, whether through Coyns or otherwise, is governed by and subject to the applicable merchant partner’s policies and terms and conditions, including but not limited to the applicable return, cancelation, exchange, and shipping policies. You agree that we are not agents of any merchant partner and that the merchant partner operates independently and not under our control. Accordingly, your participation in offers or promotions of, or correspondence with, any merchant partner is solely between you and that merchant partner. We do not assume any liability, obligation or responsibility for any part of such correspondence, offer or promotion, including, without limitation, the withdrawal or modification of any such offer or promotion. Coyns is not responsible for changes to, or discontinuance of any merchant partner from any of Coyns’s platforms or the termination of any promotion offered by any merchant partner on any of Coyns’s platforms, or for any effect on accrual of cashback caused by such changes, discontinuance, or withdrawal. Coyns assumes no responsibility and shall not be liable for any content, product and/or services on the merchant’s platforms (including but not limited to the quality, merchantability or fitness of such products or services or that such content, product and/or services does not breach any laws or regulations).</span></p>
    <h2> Top-ups and Deductions</h2>
    <p><span style="font-weight: 400;">Coyns reserves the right from time to make top-ups and deductions from the balance in a user’s account where it is necessary, reasonable, or expedient to do so. This includes (but is not limited to) situations where cashback has been incorrectly credited into a user’s account and/or where a user is found to have acted fraudulently, dishonestly, or in contravention of the terms of this Agreement.</span></p>
    <h2> Redeeming Cashback</h2>
    <p><span style="font-weight: 400;">You may redeem your Cashback at any time subject to the terms and conditions of this Agreement or the specific conditions that relate to the individual entries in your account. You must have accumulated cashback relating to a dollar value more than or equal to the minimum redemption amount indicated and communicated from time to time. In order to redeem your Cashback, you must maintain an active account and provide the minimum relevant information required to process the redemption (e.g. a valid bank account number or a Paypal account). For security purposes, redemption of cashback is limited to HKD1500 per 24h period.</span></p>
    <h2> Nature of Cashback</h2>
    <p><span style="font-weight: 400;">For the avoidance of doubt, you do not gain any proprietary right over any monies or assets held by Coyns when you earn cashback in your account. The cashback accrued in your account does not constitute monies held on trust by Coyns for your benefit. Your rights and entitlements are solely limited to such personal or contractual rights of repayment as may arise out of this Agreement.</span></p>
    <h2> Termination of Account</h2>
    <p><span style="font-weight: 400;">The Cashback accumulated in your Coyns account shall expire if your account remains inactive (i.e. no successful transactions are made through Coyns) for a period of more than a year. Coyns reserves the right to suspend or terminate your account in accordance with Clause 13 below or where it is necessary or reasonable to do so in order to comply or satisfy and law, regulation or court order.</span></p>
    <h2> Personal Data Protection</h2>
    <p><span style="font-weight: 400;">Personal Data collected is subject to our Privacy Policy, which is hereby incorporated by reference, and copy of which is located on Coyns’s website.</span></p>
    <h2> Bonuses and Reward</h2>
    <p><span style="font-weight: 400;">Coyns periodically offers bonuses or rewards for performing certain actions (such as referring new users to Coyns). The terms and conditions accompanying such offers will govern how they are earned and paid. You are prohibited from bidding on keywords containing “Coyns” or other similar words or marks for the purpose of driving traffic to you page. You shall not send any paid traffic directly to your referral link. You may not include your referral link or distribute your referral link on any website or platform that does not belong to you, such as social media pages belonging to merchants or forum pages belonging to third parties.</span></p>
    <h2> Taxes</h2>
    <p><span style="font-weight: 400;">You are responsible for any and all taxes that may apply to amounts earned from cashback you have redeemed. Coyns makes no representation as to the tax treatment of such amounts.</span></p>
    <h2> Fraud</h2>
    <p><span style="font-weight: 400;">Coyns may suspend or terminate your account if it has reason to believe are involved in any fraudulent or dishonest activities. Any failure to comply with this Agreement, any fraud, dishonesty or abuse relating to the use of the services or your account, or any misrepresentation of any information furnished to Coyns or its affiliates by you or anyone acting on your behalf may result in the termination of your account and forfeiture of your accrued cashback rewards.</span></p>
    <h2> Third Party Rights</h2>
    <p><span style="font-weight: 400;">A person who is not party to this Agreement has no rights to enforce any terms of this Agreement.</span></p>
    <h2> Communications</h2>
    <p><span style="font-weight: 400;">By becoming a Coyns user, you agree to receive communications that are account and user related (e.g. that we’ve added cashback to your account, that we have paid out money to your bank account) as well as periodic shopping related e-mails that highlight coupons or special deals available to Coyns users. We may communicate with you regarding your account by electronic communications or direct mail using information you provide during the registration process. Your consent to receive electronic communications include any notices or other information that we may be required by law to provide you in writing or otherwise. You agree to keep us appraised of your current e-mail address should the same change subsequent to the date you become a Member. We may also send you push notifications if you install the mobile application.</span></p>
    <h2> Ownership</h2>
    <p><span style="font-weight: 400;">All rights, title, and interest in the Services, the Coyns Platforms and the Content associated with or on the same belong to Coyns or its licensors. Additionally, Coyns shall maintain all rights, title, and interest in “Coyns”, the Happy Bag logo, and any other marks, service marks, trademarks, or logos of Coyns (the “Coyns Marks”) that are registered in Australia and other markets. The Coyns Marks may not be used in connection with any non-Coyns product or service in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Coyns. You shall not by any means bid on, apply for, register, own or control any keywords or marks containing “Coyns” or anything substantially similar to “Coyns” or any other Coyns Mark including, without limitation, Coyns.co and Coyns.com with any search engine, domain service provider or any intellectual property office whatsoever. You shall not mention or use Coyns in any ad text, extension, or banner ad without express written consent of Coyns. All other trademarks not owned by Coyns that are used in providing the Services are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Coyns.</span></p>
    <h2> Community Standards</h2>
    <p><span style="font-weight: 400;">By creating a Coyns account, you are becoming a member of a community that depends on the goodwill and responsible behavior of each of our users. Users are required to refrain from transmission or communication of images or text constituting ethnic slurs, obscenities, sexually explicit material, inflammatory or derogatory comments, or anything else that may be construed as harassing or offensive, which is targeted at the Services, the Coyns Platforms, our employees, contractors or agents, our merchant partners, or other users. This includes communications by means of social media or other internet posts which violate the above community standards or promote or encourage gaming or fraudulent behavior. Users who violate this provision, as determined by us in our absolute discretion, may have the accounts suspended or terminated without prior notice.</span></p>
    <h2> Indemnification</h2>
    <p><span style="font-weight: 400;">You agree to indemnify Coyns, any merchant partners, as well as their respective officers, directors, employees, successors, agents, and affiliates, for any and all claims, damages, losses and causes of action (including attorneys’ fees and court costs) arising out of or relating to your breach of this Agreement or for any materials in any form whatsoever that are provided by you (or through your username and/or password). You agree to cooperate as fully as reasonably required in our defense and/or settlement of any claim. We reserve the right, in our reasonable discretion, to assume exclusive control over the defense and settlement of any matter subject to indemnification by you.</span></p>
    <h2> Warranty Disclaimer</h2>
    <p><span style="font-weight: 400;">The Services, content, and the Coyns Platforms are provided “as is” and without warranty of any kind, express or implied, including without limitation, warranties of title, merchantability, fitness for a particular purpose or non-infringement. We make no warranty as to the quality, accuracy, currentness, completeness, reliability or validity of the Services, content or the Coyns Platforms, including without limitation, any product search results, product descriptions, product availability, pricing information advice, opinion, statement, recommendations, or other information displayed, uploaded or distributed in connection with the Services. Coyns does not warrant that the functionality of the Coyns Platforms will be uninterrupted or error-free, that defects will be corrected, or that they will be free of viruses or other harmful content. Further, Coyns does not warrant, guarantee or make any representations regarding the quality of, or accuracy of advertisements for, any products or services offered or provided by its affiliate stores in conjunction with the services.</span></p>
    <h2> Use of Biometric, Phone or Third-Party Logins</h2>
    <p><span style="font-weight: 400;">You may choose to allow access to your Coyns account via third-party logins (e.g. Facebook or Google) or through your phone (by using a device passcode or your biometric data).&nbsp; You understand the need to protect your mobile device, its passcode and any biometric data login functions, as well as the need to protect your passwords used at these third-party sites (such as your Facebook account).&nbsp;</span></p>
    <p><span style="font-weight: 400;">The biometrics recognition feature is designed and owned by the respective mobile device. You acknowledge and understand that if you allow third parties’ biometrics being registered as part of the biometrics of your mobile device, they will be able to access your account.&nbsp; You acknowledge and understand that if possession of the mobile device you registered with Coyns has changed for any reason and the device login service has not been disabled, others will be able to access your account.&nbsp; You acknowledge and understand that if your password to your Facebook or Google accounts are compromised or provided to others, that others will be able to access your account.&nbsp; In such events of unauthorized access, Coyns shall not be held responsible for any loss.</span></p>
    <p><span style="font-weight: 400;">Please understand that the Coyns service does not collect or store your biometric ID or Facebook or Google passwords, and relies on the third-party service or your mobile device to verify your identity. Coyns does not guarantee that the service will be error-free and uninterrupted. If there is (through no fault of Coyns) a transmission interruption or malfunction of this service, which causes inconvenience or inability to use the service, loss of data, errors, tampering by others, or other economic loss, Coyns shall not be held liable for the same.</span></p>
    <h2> Limitation of Liability</h2>
    <p><span style="font-weight: 400;">To the maximum extent permitted by applicable law, in no event shall Coyns be liable for any special, incidental, consequential, exemplary, punitive or other indirect damages or for any loss profits, loss data or loss of use damages, even if it has been advised of the possibility of such damages. To the maximum extent permitted by applicable law, Coyns’s maximum aggregate liability arising out of this agreement will not exceed HKD$500.</span></p>
    <h2> Governing Law and Arbitration</h2>
    <p><span style="font-weight: 400;">This Agreement, including any amendments thereto that Coyns may from time to time make at its discretion, are governed by and construed in accordance with the laws of the Republic of Singapore. Any dispute arising out of or in connection with this contract, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by reference in this clause. The seat of the arbitration shall be Singapore. The Tribunal shall consist of one arbitrator. The language of the arbitration shall be English. You agree that all issues are for the arbitrator to decide. This includes all issues related to the scope, application, interpretation and enforceability of this Agreement and this arbitration provision. The arbitrator shall also decide whether any claim is subject to arbitration.</span></p>
    <h2> Assignment</h2>
    <p><span style="font-weight: 400;">You may not assign your rights and obligations under this Agreement, in whole or in part, without prior written consent, and any such assignment without such consent will be null and void.</span></p>
    <h2> Headings</h2>
    <p><span style="font-weight: 400;">Headings under this Agreement are intended only for convenience and shall not affect the interpretation of this Agreement.</span></p>
    <h2> Waivers / Severability</h2>
    <p><span style="font-weight: 400;">Any failure to enforce any provision of this Agreement on the part of Coyns will not be deemed a waiver of that provision. Any waiver of any provision of this Agreement by Coyns on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion. If any provision of this Agreement is held to be invalid, such invalidity shall not affect the remaining provisions.</span></p>
    <h2> Entire Agreement</h2>
    <p><span style="font-weight: 400;">This Agreement (together with the additional terms and conditions referred hereto or generally) represents the entire agreement of the parties with respect to the subject matter hereof, and supersedes all prior or contemporaneous agreements and representations, written or oral (including without limitation, earlier version of this Agreement that may have been accepted by you). We reserve the right to modify this Agreement at any time. Your continued use of any of the Coyns services or continued maintenance of an account with Coyns shall constitute and be deemed to be your acceptance of such modification and your consent to abide by any terms thereof.</span></p>
    <p>&nbsp;</p>
    <p><em><span style="font-weight: 400;">Last Updated:  August 1, 2024</span></em></p>
  </div>
)

export default TermPage