import React from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import BannerForm from 'components/presenter/form/banner/BannerForm'
import BannerFormContent from 'components/presenter/form/banner/BannerFormContent'
import Page from 'components/presenter/page/Page'

const SigupSuccessPage = ({ location }) => {
  const { t } = useTranslation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')

  return (
    <Page seoMeta={{
        title: t('signup-success.title')
      }}>
      <BannerForm>
        <BannerFormContent
          title={t('signup-success.title')}
          subTitle={<Trans
            i18nKey={process.env.GATSBY_RELEASE_TYPE === 'beta' ? 'signup-success.descriptionBeta' : 'signup-success.description'}
            values={{
              email
            }}
            components={{
              discord: <a
                href='https://discord.gg/sgMSdBu79p'
                target='_blank'
                alt='Discord - Coyns Community'>
              </a>,
            }}
          />}
        />
      </BannerForm>
    </Page>
  )
}

export default SigupSuccessPage