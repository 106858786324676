// Required for gatsby-plugin-react-i18next
import { graphql } from 'gatsby'
import TermsPage from 'components/container/page/TermsPage'

export default TermsPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`