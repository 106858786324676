module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/kingwu/King/project/coyns/source/coyns-audience/src/components/container/layout/LayoutSwitcher/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en_US","zh_HK"],"defaultLanguage":"en_US","siteUrl":"https://www.coyns.com","i18nextOptions":{"debug":false,"interpolation":{"escapeValue":false},"keySeparator":"."},"pages":[{"matchPath":"/ignored-page","languages":["en_US"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
