const {
  FRAGMENT_NEWS_STATS_ONLY,
  FRAGMENT_NEWS_LIST_CORE,
  FRAGMENT_NEWS_CORE,
  FRAGMENT_HOME_BANNER_CORE,
  FRAGMENT_HOME_AFFILIATE_LIST_CORE,
  FRAGMENT_AFFILIATE_PROMO_CODE_CORE,
  FRAGMENT_AFFILIATE_DEAL_CORE,
  FRAGMENT_USER_PROFILE_CORE
} = require('./fragments')

exports.GET_HOME_DATA = `
query GetHomeData {
  list_home_banners {
    ...fragmentHomeBannerCore
  }
  list_featured_news(limit: 10, page: 1) {
    records {
      ...fragmentNewsListCore
    }
    total
  }
  list_home_news {
    name
    slug
    data {
      ...fragmentNewsListCore
    }
  }
}

${FRAGMENT_NEWS_LIST_CORE}
${FRAGMENT_HOME_BANNER_CORE}
`

exports.GET_FEATURED_LIST = `
query GetFeaturedList(
  $limit: Int
  $page: Int
) {
  list_featured_news(limit: $limit, page: $page) {
    records {
      ...fragmentNewsListCore
    }
    total
  }
}

${FRAGMENT_NEWS_LIST_CORE}
`

exports.GET_HOME_AFFILIATE_PROGRAM_LIST = `
query SearchAffiliateProgram {
  search_affiliate_program(
    limit: 100,
  ) {
    records {
      ...fragmentHomeAffiliateListCore
    }
    total
  }
}

${FRAGMENT_HOME_AFFILIATE_LIST_CORE}
`

exports.GET_AFFILIATE_PROGRAM_DETAIL = `
query GetAffiliateProgramDetail(
  $affiliateId: ID!
  $countryCode: TargetCountry!
  $limit: Int
){
  get_affiliate_program(
    affiliateId: $affiliateId,
  ) {
    ...fragmentHomeAffiliateListCore
  }
  get_affiliate_deals (
		affiliateId: $affiliateId,
		limit: $limit
	) {
    records {
      ...fragmentAffiliateDealCore
    }
    total
  }
  get_affiliate_promo_codes (
		affiliateId: $affiliateId,
    countryCode: $countryCode,
		limit: $limit
	) {
    records {
      ...fragmentAffiliatePromoCodeCore
    }
    total
  }
}

${FRAGMENT_HOME_AFFILIATE_LIST_CORE}
${FRAGMENT_AFFILIATE_DEAL_CORE}
${FRAGMENT_AFFILIATE_PROMO_CODE_CORE}
`

exports.GET_AFFILIATE_PROMO_CODE = `
query GetAffiliatePromoCode(
  $affiliateId: ID!
  $countryCode: TargetCountry!
  $lastId: ID
  $limit: Int
){
  get_affiliate_promo_codes (
		affiliateId: $affiliateId,
    countryCode: $countryCode,
    lastID: $lastId,
		limit: $limit
	) {
    records {
      ...fragmentAffiliatePromoCodeCore
    }
    total
  }
}
${FRAGMENT_AFFILIATE_PROMO_CODE_CORE}
`

exports.GET_COUNTRY_CODES = `
query GetCountryCodes(
  $locale: String!
) {
	get_country_list(locale: $locale) {
		alpha_2
		area
		phone_prefix
		name
	}
}
`

exports.GET_AFFILIATE_DEALS = `
query GET_AFFILIATE_DEALS(
  $affiliateId: ID!
  $lastId: ID
  $limit: Int
){
  get_affiliate_deals (
		affiliateId: $affiliateId,
    lastID: $lastId,
		limit: $limit
	) {
    records {
      ...fragmentAffiliateDealCore
    }
    total
  }
}

${FRAGMENT_AFFILIATE_DEAL_CORE}
`

exports.SEARCH_NEWS = `
query SearchNewsList(
  $text: String
  $regions: [ID!]
  $category: [ID!]
  $keyword: [ID!]
  $meta: [ID!]
  $order_by: NewsOrderBy
  $limit: Int
  $page: Int
) {
  search_news(
    text: $text,
    regions: $regions,
    category: $category,
    keyword: $keyword,
    meta: $meta,
    order_by: $order_by,
    limit: $limit,
    page: $page,
  ) {
    records {
      ...fragmentNewsListCore
    }
    total
  }
}

${FRAGMENT_NEWS_LIST_CORE}
`

exports.GET_NEWS_POST = `
query GetNewsPost (
  $news_id: ID!
){
  get_news(
    news_id: $news_id
  ) {
    ...fragmentNewsCore
  }
}
${FRAGMENT_NEWS_CORE}
`

exports.GET_NEWS_POST_STATS_ONLY = `
query GetNewsPost (
  $news_id: ID!
){
  get_news(
    news_id: $news_id
  ) {
    ...fragmentNewsStatsOnly
  }
}
${FRAGMENT_NEWS_STATS_ONLY}
`

exports.GET_MY_CAMPAIGN_PASS = `
query GetMyCampaignPass {
	get_my_campaign_pass {
		_id
		campaign {
			_id
			name
			created_at
			expired_at
			ratio
			type
		}
    is_applied
    quota
		created_at
		expired_at
	}
}
`

exports.GET_USER_INFORMATION = `
query GetUserInformation {
	get_my_campaign_pass {
		_id
		campaign {
			_id
			name
			created_at
			expired_at
			ratio
			type
		}
    is_applied
    quota
		created_at
		expired_at
	}
  get_user_profile {
    ...fragmentUserProfileCore
  }
}
${FRAGMENT_USER_PROFILE_CORE}
`

exports.GET_USER_PROFILE = `
query GetUserProfile {
  get_user_profile {
    ...fragmentUserProfileCore
  }
}

${FRAGMENT_USER_PROFILE_CORE}
`

exports.GET_USER_BANK_N_TAX = `
query {
	get_user_bank_n_tax {
		bank {
			account_name
      account_number
			country_code
			bank_name
			swift_code
			branch_code
			bank_code
			branch_name
			bank_address
			updated_at
		}
		tax {
			account_type
			id_type
			id_number
			name_on_id
			country_code
			phone_number
			gender
			date_of_birth
			residential_address
			business_license_id
			tax_id
      nationality
			updated_at
		}
	}
}
`

exports.GET_ACCOUNT_OVERVIEW = `
query GetAccountOverview {
  get_account_overview {
    balance_by_share
    balance_by_purchase
    incoming_by_share
    incoming_by_purchase
    balance_unit
    incoming_unit
    number_of_network_distributor
    number_of_direct_distributor
  }
  get_commission_report {
		records {
			_id
			company {
				name
				domain
			}
			activity_end_at
			activity_start_at
			commission_amount
			commission_rate
			commission_amount_currency
      commission_type
			sale_amount
			sale_amount_currency
			order_id
			order_at
			product_id
      product_type
			product_name
			product_destination
			status
			is_share
			transcation_state
			created_at
		}
		total
	}
}
`

exports.GET_INVOICES_OVERVIEW = `
query GetInvoicesOverview {
  get_account_overview {
    balance_by_share
    balance_by_purchase
    incoming_by_share
    incoming_by_purchase
    balance_unit
    incoming_unit
    number_of_network_distributor
    number_of_direct_distributor
  }
  get_invoices {
    records {
      _id
      reference_number
      bank_transfer_at
      bank_transfer_status
      total_commission
      updated_at
      created_at
    }
    total
  }
}
`

exports.GET_INVOICES = `
query GetInvoices(
  $lastID: ID!
  $limit: Int
) {
  get_invoices(
    lastID: $lastID
    limit: $limit
  ) {
    records {
      _id
      reference_number
      bank_transfer_at
      bank_transfer_status
      total_commission
      updated_at
      created_at
    }
    total
  }
}
`

exports.GET_INVOICE_DETAIL = `
query GetInvoiceDetail(
  $invoiceID: ID!
) {
	get_invoice_detail (invoiceID: $invoiceID) {
			_id
      reference_number
			bank_transfer_status
			bank_transfer_at
			created_at
			commission_reports {
				_id
				company {
					name
					domain
				}
				activity_end_at
				activity_start_at
				commission_amount
				commission_rate
				commission_amount_currency
        commission_type
				sale_amount
				sale_amount_currency
				order_id
				order_at
				product_id
        product_type
				product_name
				product_destination
				status
				is_share
				transcation_state
				created_at
			}
	}
}
`

exports.GET_COMMISSION_REPORT = `
query GetCommissionReport(
  $lastID: ID!
  $limit: Int
) {
  get_commission_report(
    lastID: $lastID
    limit: $limit
  ) {
    records {
      _id
      company {
        name
        domain
      }
      activity_end_at
      activity_start_at
      commission_amount
      commission_rate
      commission_amount_currency
      commission_type
      sale_amount
      sale_amount_currency
      order_id
      order_at
      product_id
      product_type
      product_name
      product_destination
      status
      is_share
      transcation_state
      created_at
    }
    total
  }
}
`

exports.CHECK_CASHBACK = `
query CheckCashback(
  $url: String!
) {
  check_cashback(
    url: $url
  ) {
    affiliate_program {
			_id
			company {
				name
        domain
				company_logo {
					_id
					url
				}
			}
		}
    reward {
      cashback_value
      cashback_value_changed
      cashback_type
      currency_unit
      product_type
    }
  }
}
`

exports.GET_CAMPAIGN_BY_CODE = `
query GetCampaignByCode(
  $code: String!
) {
	get_campaign_by_code(code: $code) {
		_id
		name
    pass_life_time
    pass_quota
		created_at
		expired_at
		ratio
		type
	}
}
`

exports.GET_CAMPAIGN_BY_PASS_ID = `
query GetCampaignByPassId(
  $passId: ID!
) {
	get_campaign_by_passId(id: $passId) {
		_id
		name
    pass_life_time
    pass_quota
		created_at
		expired_at
		ratio
		type
	}
}
`

exports.GET_PROMO_CODES = `
query GetPromoCodes(
  $id: ID!
) {
  get_promo_codes(id: $id) {
    ...fragmentAffiliatePromoCodeCore
  }
}

${FRAGMENT_AFFILIATE_PROMO_CODE_CORE}
`