import ReactGA from 'react-ga4'
import Tracker from './tracker'

/**
 * Google Analytics 4 tracker,
 * ref: https://developers.google.com/analytics/devguides/collection/ga4
 */
export default class GoogleAnalytics4Tracker extends Tracker {
  constructor (id, screenNameConvertFunc) {
    super()
    this.id = id
    this._screenNameConvertFunc = screenNameConvertFunc
  }

  init() {
    super.init()
    ReactGA.initialize(this.id)
  }

  /**
   * Send custom event,
   * wrapped w/ defer init handling
   * @param {string} eventName
   * @param {*} eventVariables
   */
  sendCustomEvent (eventName, eventVariables) {
    this.executeOrQuery(() => {
      ReactGA.event(eventName, eventVariables)
    })
  }

  /**
   * Send screen view event,
   * ref: https://developers.google.com/analytics/devguides/collection/ga4/screen-view
   */
  sendPageViewEvent ({pagePath}) {
    this.executeOrQuery(() => {
      ReactGA.send({ hitType: "pageview", page: pagePath })
    })
  }

  setAppVersion (version) {
    this.setUserProperties({
      version,
    })
  }

  setCurrentScreen (screenName) {
    this.sendCustomEvent('screen_view', {
      screen_name: screenName,
    })
  }

  /**
   * Set user ID,
   * ref: https://developers.google.com/analytics/devguides/collection/ga4/cookies-user-id
   * @param {string} userId
   */
  setUserId (userId) {
    this.setUserProperties({
      user_id: userId,
    })
  }

  /**
   * Set user properties,
   * ref: https://developers.google.com/analytics/devguides/collection/ga4/user-properties
   * @param {*} properties
   */
  setUserProperties (properties = {}) {
    this.executeOrQuery(() => {
      ReactGA.gtag('set', 'user_properties', properties)
    })
  }
}
