import React from 'react'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import cn from 'classnames'
import * as styles from './loading.module.css'

const LoadingWrapper = ({children, className, classes = {}, isLoading, variant = 'circle'}) => {
  const widget = (
    variant === 'linear' ? 
      <LinearProgress className={cn(styles.linearLoading, classes.linear)} /> :
      <CircularProgress className={cn(styles.circleLoading, classes.circle)} size={null} />
  )
  const overlay = (
    <div className={cn(styles.overlay, classes.overlay)}>{widget}</div>
  )
  return (
    <div className={cn(styles.wrapper, classes.root, className)}>
      {children}
      {isLoading && overlay}
    </div>
  )
}
export default LoadingWrapper