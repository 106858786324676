import * as React from 'react'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import cn from 'classnames'
import FillButton from 'components/presenter/buttons/FillButton'
import * as styles from './styles.module.css'

const SloganSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className={cn(styles.sectionRoot)}>
        <h3>
          {t('landing.exploreDiscord')}
        </h3>
        <span className={styles.txtSubTitle}>{t('landing.exploreDiscordSub')}</span>
        <FillButton
          className={cn(styles.btnPromote, 'mui-btn-link')}>
          <a
            href='https://discord.gg/sgMSdBu79p'
            target='_blank'
            alt='Discord - Coyns Community'>
            {t('landing.joinDiscord')}
          </a>
        </FillButton>
      </div>
    </>
  )
}
export default SloganSection