import * as React from 'react'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import cn from 'classnames'
import HKFlag from 'images/flags/hk.jpg'
// import FacebookIcon from 'assets/svg/ic_facebook.inline.svg'
// import InstagramIcon from 'assets/svg/ic_instagram.inline.svg'
import DiscordIcon from 'assets/svg/ic_discord.inline.svg'
import categoryFullData from 'assets/category'
import _ from 'lodash'
import * as styles from './styles.module.css'

const langMap = {
  'zh_HK': '繁體中文',
  'en_US': 'English'
}

const followUsMao = [
  {
    component: DiscordIcon,
    name: 'discord',
    url: 'https://discord.gg/sgMSdBu79p',
    alt: 'Discord - Coyns Community'
  }
]

const Footer = () => {
  const { t } = useTranslation()
  const {language} = useI18next()
  const categoryLangData = categoryFullData[language]
  const parentLink = (subCat) => {
    const searchParams = new URLSearchParams()
    searchParams.append('title', subCat.name)
    searchParams.append('category', subCat.sub_category.map(c => c.short_code).join(','))
    return `/jetso/search/?${searchParams.toString()}`
  }
  const category = _.flatMap(categoryLangData.map(p => p.sub_category))

  return (
    <footer className={cn(styles.footer)}>
      <div className={styles.separator} />
      <div className={styles.content}>
        <div className={styles.followSection}>
          <span className={styles.contentSectionTitle}>{t('footer.followUs')}</span>
          <div className={styles.linkContainer}>
            {
              followUsMao.map(item => (
                <a
                  key={item.name}
                  target="_blank"
                  href={item.url}
                  className={cn("mui-btn-link", styles.link)}
                  title={item.alt}>
                  <item.component key={item.name} className="svgFill" />
                </a>
              ))
            }
          </div>
        </div>
        <div className={styles.menuSection}>
          <span className={styles.contentSectionTitle}>{t('footer.aboutUs')}</span>
          <div className={styles.linkContainer}>
            <a className={styles.link} href={`/${language}/`} title={t('footer.portal')}>
              {t('footer.portal')}
            </a>
          </div>
        </div>
        {/* <div>
          <span className={styles.contentSectionTitle}>{t('footer.jetsoCategory')}</span>
          <div className={styles.linkContainer}>
            {
              category.map(c => (
                <Link
                  key={c.short_code}
                  className={styles.link}
                  to={parentLink(c)}
                  title={`${c.name} ${t(`common.jetso`)}`}
                >
                  {c.name}
                </Link>
              ))
            }
          </div>
        </div> */}
      </div>
      <div className={styles.separator} />
      <div className={styles.section}>
        <p>{t('footer.license')}</p>
        <div className={styles.langSection}>
          <img className={styles.flag} src={HKFlag} alt={t('common.appName')} />
          <p>{t('footer.hk')}</p>
          <p>{`(${langMap[language]})`}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer