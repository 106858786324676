export * from './coyns'

import React from 'react'
import { postPortalGQL, postPortalGQLFile } from 'api/coyns/graphql'
import { useSnackbar } from 'libs/ui/snackbar'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useLoading } from 'libs/form/loadingHook'
import { setLocalCache, accessTokenKey } from 'stores/localStorage'
import { 
  AUTH_CREATE_ANONYMOUS, 
  AUTH_VERIFY_REGISTER, 
  AUTH_LOGIN, 
  AUTH_LOGOUT, 
  AUTH_RESET_PASSWORD, 
  UPDATE_USER_PROFILE,
  UPDATE_USER_PASSWORD,
  GENERATE_AFFILIATE_LINK,
  CREATE_BANK_AND_TAX,
  JOIN_CAMPAIGN,
  LINK_CAMPAIGN_PASS,
  REQUEST_WITHDRAW
} from 'api/coyns/graphql/mutations'
import { 
  GET_HOME_AFFILIATE_PROGRAM_LIST, 
  GET_USER_PROFILE,
  GET_COUNTRY_CODES,
  GET_USER_BANK_N_TAX,
  GET_ACCOUNT_OVERVIEW,
  GET_COMMISSION_REPORT,
  CHECK_CASHBACK,
  GET_CAMPAIGN_BY_CODE,
  GET_CAMPAIGN_BY_PASS_ID,
  GET_INVOICES_OVERVIEW,
  GET_INVOICE_DETAIL,
  GET_USER_INFORMATION,
  GET_MY_CAMPAIGN_PASS,
  GET_PROMO_CODES
} from 'api/coyns/graphql/queries'

import { EventEmitter } from 'events'

const eventType = 'httpError'
export const errorEventEmitter = new EventEmitter()

const sendRequest = async (query, variables, options) => {
    const response = await postPortalGQL(query, variables, _accessToken, options)
    if (response.error) {
        errorEventEmitter.emit(eventType, response)
    }
    return response
}
const sendFileRequest = async (query, variables, fileObj, options) => {
  const response = await postPortalGQLFile(query, variables, fileObj, _accessToken, options)
  if (response.error) {
      errorEventEmitter.emit(eventType, response)
  }
  return response
}


let _accessToken = null

export const apiManager = {
    setAccessToken: (accessToken) => {
      _accessToken = accessToken
    },
    createAnonymous: async () => {
      return await sendRequest(AUTH_CREATE_ANONYMOUS, {})
    },
    login: async ({userName, password, loginMethod}) => {
      return await sendRequest(AUTH_LOGIN, {
        input: {
          username: userName,
          password,
          login_method: loginMethod
        }
      })
    },
    verifyEmail: async(token) => {
      return await sendRequest(AUTH_VERIFY_REGISTER, {
        token
      })
    },
    logout: async () => {
      return await sendRequest(AUTH_LOGOUT, {})
    },
    resetPassword: async ({token, password, lang}) => {
      return await sendRequest(AUTH_RESET_PASSWORD, {
        input: {
          token,
          password
        }
      }, {
        lang
      })
    },
    fetchHomeList: async ({lang}) => {
      return await sendRequest(GET_HOME_AFFILIATE_PROGRAM_LIST, {}, {
        lang
      })
    },
    fetchUser: async () => {
      return await sendRequest(GET_USER_PROFILE, {})
    },
    // Bug: No locale include, can't get campaign name
    fetchUserInformation: async () => {
      return await sendRequest(GET_USER_INFORMATION, {})
    },
    fetchMyCampaignPass: async ({lang}) => {
      return await sendRequest(GET_MY_CAMPAIGN_PASS, {}, {
        lang
      })
    },
    fetchUserBankNTax: async () => {
      return await sendRequest(GET_USER_BANK_N_TAX, {})
    },
    updateProfile: async (fullName) => {
      return await sendRequest(UPDATE_USER_PROFILE, {
        input: {
          full_name: fullName,
        }
      })
    }, 
    uploadProfileImage: async (file) => {
      return await sendFileRequest(UPDATE_USER_PROFILE, {input:{}}, {
        fieldName: 'profileFile',
        file
      }, {
        format: 'formData'
      })
    },
    updateUserPassword: async ({ oldPassword, newPassword }) => {
      return await sendRequest(UPDATE_USER_PASSWORD, {
        input: {
          old_password: oldPassword,
          new_password: newPassword,
        }
      })
    },
    generateAffiliateLink: async (affiliateId) => {
      return await sendRequest(GENERATE_AFFILIATE_LINK, {
        affiliateId
      })
    },
    getCountryCodes: async (locale) => {
      return await sendRequest(GET_COUNTRY_CODES, {
        locale
      })
    },
    getCampaignByPassId: async (passId, lang) => {
      return await sendRequest(GET_CAMPAIGN_BY_PASS_ID, {
        passId
      }, {
        lang
      })
    },
    requestWithdraw: async () => {
      return await sendRequest(REQUEST_WITHDRAW, {})
    },
    createBankAndTax: async ({bank, tax}) => {
      return await sendRequest(CREATE_BANK_AND_TAX, {
        bank,
        tax
      })
    },
    getPromoCodes: async (id) => {
      return await sendRequest(GET_PROMO_CODES, {
        id
      })
    },
    getAccountOverview: async () => {
      return await sendRequest(GET_ACCOUNT_OVERVIEW, {})
    },
    getInvoicesOverview: async () => {
      return await sendRequest(GET_INVOICES_OVERVIEW, {})
    },
    getCommissionReport: async ({lastID, limit}) => {
      return await sendRequest(GET_COMMISSION_REPORT, {
        lastID,
        limit
      })
    },
    getInvoices: async ({lastID, limit}) => {
      return await sendRequest(GET_INVOICES, {
        lastID,
        limit
      })
    },
    getInvoiceDetail: async (invoiceId) => {
      return await sendRequest(GET_INVOICE_DETAIL, {
        invoiceID: invoiceId
      })
    },
    getCampaignByCode: async (code, lang) => {
      return await sendRequest(GET_CAMPAIGN_BY_CODE, {
        code
      }, {
        lang
      })
    },
    joinCampaign: async (code) => {
      return await sendRequest(JOIN_CAMPAIGN, {
        code
      })
    },
    linkCampaignPass: async (passId) => {
      return await sendRequest(LINK_CAMPAIGN_PASS, {
        passId
      })
    },
    checkCashback: async (url) => {
      return await sendRequest(CHECK_CASHBACK, {
        url
      })
    }
}

export const getFirstErrorCode = (response) => {
  if (response && response.error && Array.isArray(response.error.errors)) {
    return response.error.errors[0].extensions.code
  }
  return null
}

export const useApiCall = () => {
  return {
    requestGraphQL: sendRequest,
    requestGraphQLFiles: sendFileRequest,
  }
}

export const useApiCallLoading = () => {
  const { requestGraphQL, requestGraphQLFiles } = useApiCall()
  const { isLoading, processAsyc } = useLoading()
  const wrapApiCall = async (query, variables, options) => {
    return await processAsyc(requestGraphQL(query, variables, _accessToken, options))
  }

  const wrapFileApiCall = async (query, variables, fileObj, options) => {
    return await processAsyc(requestGraphQLFiles(query, variables, fileObj, _accessToken, options))
  }

  return {
    isRequestLoading: isLoading,
    requestGraphQL: wrapApiCall,
    requestGraphQLFiles: wrapFileApiCall
  }
}


export const APIErrorHandler = () => {
    const { t } = useTranslation()
    const { showError } = useSnackbar()

    const handleError = React.useCallback((response) => {
        if (response.error) {
          let errMsg
          if (response.error.isSystemError) {
            errMsg = t('errorCodes.50000')
          } else if (response.error.isRequestFail) {
            errMsg = t('error.checkNetwork')
          } else if (response.error.isUnauthorized) {
            errMsg = t('error.unauthorized')
    
            // Hot Fix: Clear local token
            setLocalCache(accessTokenKey, '')
          }
    
          if (errMsg) {
            showError(errMsg)
          }
        }
        return response
      }, [])
    
    React.useEffect(() => {
        errorEventEmitter.on(eventType, (response) => {
            handleError(response)
        })
    }, [])
    
    return (
        <>
        </>
    )
}