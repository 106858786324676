import React from 'react'
import Header from 'components/container/nav/Header'
import Footer from 'components/container/nav/Footer'
import cn from 'classnames'
import * as styles from './default.module.css'

const DefaultLayout = ({ children, location }) => (
    <div className={cn(styles.mainLayout)}>
      <Header location={location} />
      {children}
      <Footer />
    </div>
  )




export default DefaultLayout