// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-campaign-join-index-js": () => import("./../../../src/pages/campaign/join/index.js" /* webpackChunkName: "component---src-pages-campaign-join-index-js" */),
  "component---src-pages-campaign-link-pass-index-js": () => import("./../../../src/pages/campaign/link-pass/index.js" /* webpackChunkName: "component---src-pages-campaign-link-pass-index-js" */),
  "component---src-pages-campaign-pass-index-js": () => import("./../../../src/pages/campaign-pass/index.js" /* webpackChunkName: "component---src-pages-campaign-pass-index-js" */),
  "component---src-pages-forget-password-index-js": () => import("./../../../src/pages/forget-password/index.js" /* webpackChunkName: "component---src-pages-forget-password-index-js" */),
  "component---src-pages-forget-password-success-index-js": () => import("./../../../src/pages/forget-password/success/index.js" /* webpackChunkName: "component---src-pages-forget-password-success-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoice-index-js": () => import("./../../../src/pages/invoice/index.js" /* webpackChunkName: "component---src-pages-invoice-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-payment-index-js": () => import("./../../../src/pages/payment/index.js" /* webpackChunkName: "component---src-pages-payment-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-report-index-js": () => import("./../../../src/pages/report/index.js" /* webpackChunkName: "component---src-pages-report-index-js" */),
  "component---src-pages-reset-password-index-js": () => import("./../../../src/pages/reset-password/index.js" /* webpackChunkName: "component---src-pages-reset-password-index-js" */),
  "component---src-pages-reset-password-success-index-js": () => import("./../../../src/pages/reset-password/success/index.js" /* webpackChunkName: "component---src-pages-reset-password-success-index-js" */),
  "component---src-pages-signup-email-verify-index-js": () => import("./../../../src/pages/signup/email-verify/index.js" /* webpackChunkName: "component---src-pages-signup-email-verify-index-js" */),
  "component---src-pages-signup-index-js": () => import("./../../../src/pages/signup/index.js" /* webpackChunkName: "component---src-pages-signup-index-js" */),
  "component---src-pages-signup-success-index-js": () => import("./../../../src/pages/signup/success/index.js" /* webpackChunkName: "component---src-pages-signup-success-index-js" */),
  "component---src-pages-store-detail-index-js": () => import("./../../../src/pages/store/detail/index.js" /* webpackChunkName: "component---src-pages-store-detail-index-js" */),
  "component---src-pages-store-link-index-js": () => import("./../../../src/pages/store/link/index.js" /* webpackChunkName: "component---src-pages-store-link-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */)
}

