import React from 'react'

export const usePageLoad = ({
  onPageLoad = async () => {},
  pageLoadDeps = [],
}) => {
  const [ isPageLoaded, setPageLoaded ] = React.useState(false)

  const triggerPageLoad = async () => {
    await onPageLoad()
    setPageLoaded(true)
  }
  React.useEffect(() => {
    if(pageLoadDeps.every(dep => !!dep)) {
      triggerPageLoad()
    }
  }, pageLoadDeps)

  return {
    isPageLoaded
  }
}
