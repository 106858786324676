/* eslint-disable no-restricted-syntax */

export default class EventTracker {
  constructor (trackerMap) {
    this._trackerMap = trackerMap
    this._isSSR = typeof window === "undefined"
  }

  // /**
  //  * Initialize event trackers synchronously,
  //  * i.e. process blocking
  //  */
  // init () {
  //   for (const tracker of Object.values(this._trackerMap)) {
  //     tracker.init()
  //   }
  // }

  /**
   * Initialize event trackers asynchronously,
   * i.e. when browser is idle
   */
  deferInit () {
    if (this._isSSR) {
      return
    }
    for (const tracker of Object.values(this._trackerMap)) {
      tracker.deferInit()
    }
  }

  sendConversion (id, keys = []) {
    if (this._isSSR) {
      return
    }
    for (const tracker of this.getTrackers(keys)) {
      tracker.sendConversion(id)
    }
  }

  sendCustomEvent (eventName, eventVariables = {}, keys = []) {
    if (this._isSSR) {
      return
    }
    if (eventName && eventVariables) {
      for (const tracker of this.getTrackers(keys)) {
        tracker.sendCustomEvent(eventName, eventVariables)
      }
    }
  }

  sendPageViewEvent (pageData, keys = []) {
    if (this._isSSR) {
      return
    }
    for (const tracker of this.getTrackers(keys)) {
      tracker.sendPageViewEvent(pageData)
    }
  }

  setUserId (userId, keys = []) {
    if (this._isSSR) {
      return
    }
    for (const tracker of this.getTrackers(keys)) {
      tracker.setUserId(userId)
    }
  }

  setAppVersion (version, keys = []) {
    if (this._isSSR) {
      return
    }
    for (const tracker of this.getTrackers(keys)) {
      tracker.setAppVersion(version)
    }
  }

  setUserProperties (properties, keys = []) {
    if (this._isSSR) {
      return
    }
    for (const tracker of this.getTrackers(keys)) {
      tracker.setUserProperties(properties)
    }
  }

  getTrackers (keys) {
    if (Array.isArray(keys) && keys.length > 0) {
      const trackers = []
      for (const key of keys) {
        const tracker = this._trackerMap[key]
        if (tracker) {
          trackers.push(tracker)
        }
      }
      return trackers
    }
    return Object.values(this._trackerMap)
  }
}
