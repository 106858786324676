import React from 'react'
import cn from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { displayDate } from 'libs/date/dateUtil'
import FillButton from 'components/presenter/buttons/FillButton'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import * as styles from './styles.module.css'

const styles2 = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles2)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const useCopyPromoCodeDialog = () => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = React.useCallback(() => {
        setOpen(true)
    })
    const handleClose = React.useCallback(() => {
        setOpen(false)
    })
    
    return {
        open,
        handleClickOpen,
        onClose: handleClose
    }
}

const TermButton = ({record}) => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
      setOpen(false);
  }

  const handleTooltipOpen = () => {
      setOpen(true);
  }

return (
  <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
          PopperProps={{
              disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableHoverListener
          disableTouchListener
          title={record.terms}
      >
          <Button className={cn(styles.promoCodeTerms)} onClick={handleTooltipOpen}>{t('common.terms')}</Button>
      </Tooltip>
  </ClickAwayListener>
)
}


export function CopyPromoCodeDialog({promoCodeRecord, open, onClose}) {
    const { t } = useTranslation()
    const [isCopied, setIsCopied] = React.useState(false);

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
          setIsCopied(true)
      }).catch(err => {
          console.error('Failed to copy text: ', err)
      })
      return false
    }

    if (!promoCodeRecord) {
      return (<></>)
    }


    return (
        <Dialog className={styles.dialog} onClose={onClose} open={open}>
            <DialogTitle onClose={onClose} />
            <MuiDialogContent className={styles.dialogContent}>
                <span className={styles.promoCodeCardTitle}>{promoCodeRecord.discount}</span>
                <span className={styles.promoCodeCardExpired}>{t('affiliate.validDateFormat', {
                    endAt: displayDate(promoCodeRecord.end_at)
                })}</span>
                <span className={styles.promoCodeCardBody}>{promoCodeRecord.description}</span>
                <Button
                   variant='outlined'
                   className={cn(styles.promoCodeButton)}
                   onClick={() => copyToClipboard(promoCodeRecord.code)}
                >
                    {isCopied ? t('common.copied') : promoCodeRecord.code}
                </Button>
                <TermButton record={promoCodeRecord} />
            </MuiDialogContent>
        </Dialog>
    )
}