import React from 'react'
import { Card } from '@material-ui/core'
import cn from 'classnames'
import MaxCashbackReward from 'components/presenter/text/MaxCashbackReward'
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next'

import * as styles from './styles.module.css'

const ExploreSection = ({homeData}) => {
  const { t } = useTranslation()

  const search_affiliate_program = homeData?.search_affiliate_program
  const records = search_affiliate_program?.records || []  

  const renderCompanyCardList = () => {
    return records.map((record) => (
      renderCompanyCard(record)
    ))
  }

  const renderCompanyCard = (record) => {
    const companyName = record.company?.name
    const logoUrl = record.company?.company_logo?.url

    const reward = record.rewards.filter(reward => reward.cashback_type === 1).reduce((max, current) => current.cashback_value > max.cashback_value ? current : max, {
        cashback_value: 0
    })
    
    return (
        <Card key={record._id} className={cn(styles.card)} elevation={2}>
          <Link 
            className={cn('mui-btn-link', styles.cardLink)}
            to={'/store/detail/?id=' + record._id}
            title={t('seo.companyCardLinkFormat', {
              name: companyName,
              cashback: reward.cashback_value
            })}
          >
            <img className={styles.logo} src={logoUrl} />
            <div className='flex-1 w-full'/>
            <span className={styles.txtCompanyName}>{companyName}</span>
            <MaxCashbackReward rewards={record.rewards} />
          </Link>
        </Card>
    )
  }

  return (
    <div className={cn(styles.sectionRoot)}>
      <h3>
        {t('landing.exploreTitle')}
      </h3>
      <div className={cn(styles.container, 'container')}>
        { renderCompanyCardList() }
      </div>
    </div>
  )
}
export default ExploreSection