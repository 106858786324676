import { useSnackbar as _useSnackbar } from 'notistack'

export const useSnackbar = () => {
  const { enqueueSnackbar } = _useSnackbar()

  const showSuccess = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'success',
    })
  }

  const showError = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'error',
    })
  }

  const showWarning = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'warning',
    })
  }

  const showInfo = (msg) => {
    enqueueSnackbar(msg, {
      variant: 'info',
    })
  }
  
  return {
    showSuccess,
    showError,
    showWarning,
    showInfo
  }
}