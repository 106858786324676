import * as React from 'react'
import { Trans, useTranslation, Link } from 'gatsby-plugin-react-i18next'
import cn from 'classnames'
import Card from '@material-ui/core/Card'
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import FillButton from 'components/presenter/buttons/FillButton'
import MaxCashbackText from 'components/presenter/text/MaxCashbackText'
import { useAuthStore } from 'stores'
import { apiManager } from 'api'
import { LoginDialog, useLoginDialog } from 'components/container/page/StoreDetailPage/LoginDialog'
import { useStoreLink } from 'libs/affiliate'
import * as styles from './styles.module.css'


const SuccessResult = ({result, onClick}) => {
  const { t } = useTranslation()
  const domain = result.affiliate_program.company.domain
  const reward = result.reward
  return (
    <div className={styles.success}>
      <p className={styles.txtResult}>
        <Trans
          className={styles.txtResult}
          i18nKey="home.checkCashbackSuccess"
          values={{
            category: t(`product_category.${domain}.${reward.product_type}`), 
            cashback: result.cashback
          }}
          components={{
            cashback: <MaxCashbackText className={styles.txtMaxCashback} reward={reward} />,
            category: <span className={styles.txtHighlight} />,
          }}
        />
      </p>
      <FillButton onClick={onClick}>{t('affiliate.shopNow')}</FillButton>
    </div>
  )
}

const CheckCachbackBox = ({className, onChange}) => {
  const { t } = useTranslation()
  const [url, setUrl] = React.useState('')
  const authStore = useAuthStore()
  const [errorCode, setErrorCode] = React.useState(null)
  const [result, setResult] = React.useState(null)
  const { open, handleClickOpen, onClose, onLogin } = useLoginDialog()
  const [isLoading, setIsLoading] = React.useState(false)

  const { generateStoreLink } = useStoreLink()

  const handleClick = (event) => {
    event.preventDefault()
    if (authStore.isLoggedIn()) {
      const storeLink = generateStoreLink({
        storeId: result.affiliate_program._id,
        companyName: result.affiliate_program.company.name,
        logoUrl: result.affiliate_program.company.company_logo.url,
        redirect_url: url
      })
      window.open(storeLink, '_blank')
    } else {
        handleClickOpen()
    }
  }

  const onCheckCashback = React.useCallback(async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    setResult(null)
    setErrorCode(null)

    if (onChange) {
      onChange(null, null)
    }

    const result = await apiManager.checkCashback(url)
    if (result.error) {
      const errorCode = result?.error?.errors?.[0]?.extensions?.code;
      setResult(null)
      setErrorCode(errorCode)
    } else {
      setResult(result.check_cashback)
      setErrorCode(null)
    }

    setIsLoading(false)

    if (onChange) {
      onChange(result, errorCode)
    }
  }, [url])

  const errorMessage = React.useMemo(() => {
    switch (errorCode) {
      case 20035:
        return t('home.checkCashbackErrorUnsupportPlatform')
      case 20034:
        return t('home.checkCashbackErrorNotFound')
      case 20033:
        return t('home.checkCashbackErrorProductExpired')
      case 20032:
        return t('home.checkCashbackErrorSystemError')
      case 20031:
        return t('home.checkCashbackError')
    }
    return ''
  }, [errorCode])
  return (
    <>
      <Card className={cn(styles.card, className)}>
        <h2>{t('home.checkCashback')}</h2>
        <p className={styles.subTitle}>{t('home.checkCashbackSub')}</p>
        <div className={styles.search}>
          <InputBase
            className={styles.input}
            onChange={(e) => setUrl(e.target.value)}
            placeholder={t('home.checkCashbackInputHint')}
            inputProps={{ 'aria-label': t('home.checkCashbackInputHint') }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onCheckCashback();
              }
            }}
          />
          {isLoading && <CircularProgress size={48} />}
          {!isLoading && (
            <IconButton type="submit" aria-label="search" onClick={onCheckCashback}>
              <SearchIcon />
            </IconButton>
          )}
        </div>
        {(errorMessage || result) && <Divider className={styles.divider} />}
        {errorMessage && <p className={styles.txtError}>{errorMessage}</p>}
        {result && <SuccessResult result={result} onClick={handleClick} />}
      </Card>
      <LoginDialog 
        open={open}
        onClose={onClose}
        onLogin={onLogin}
      />
    </>
  )
}

export default CheckCachbackBox