import React from 'react'
import { Button } from '@material-ui/core'
// import cn from 'classnames'
// import * as styles from './button.module.css'

const PlatButton = (props) => {
  const {children} = props
  return (
    <Button
      {...props}
      color="inherit"
    >
      {children}
    </Button>
  )
}

export default PlatButton