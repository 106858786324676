import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import * as styles from './styles.module.css'

const MimeTypeJpg = 'image/jpeg'
const MimeTypePng = 'image/png'

const mimeMapping = {
  'jpg': MimeTypeJpg,
  'jpeg': MimeTypeJpg,
  'png': MimeTypePng
}

/**
 * Get image extension
 *
 * @param {*} url The image url
 * @returns {string} extension
 */
function getExtension(url) {
  if (url.match(/(.jpg|.png|.jpeg)/)) {
    return url.split('.').pop()
  }
  return null
}

const WebpImage = ({className, src, alt, ...props}) => {
  const extension = getExtension(src)
  const mimeType = extension ? mimeMapping[extension] : null
  const webpUrl = extension ? src.replace(`.${extension}`, '.webp') : `${src}.webp`

  return (
    <picture className={cn(styles.picture, className)} {...props}>
      <source srcSet={webpUrl} type="image/webp" />
      { mimeType ? <source srcSet={src} type={mimeType} /> : '' }
      <img src={src} alt={alt} loading="lazy" />
    </picture>
  )
}

WebpImage.propTypes = {
  className: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

export default WebpImage