import * as React from 'react'
import { useTranslation, useI18next  } from 'gatsby-plugin-react-i18next'
import { Paper } from '@material-ui/core'
import WebpImage from 'components/presenter/image/WebpImage'
import AppIcon from 'assets/svg/app_icon_white.inline.svg'
import Form from 'components/presenter/form/Form'
import cn from 'classnames'
import * as styles from './bannerForm.module.css'

const BannerForm = ({children, className = '', isLoading = false}) => {
  const { t } = useTranslation()
  const { navigate } = useI18next()
  return (
    <Paper className={cn(styles.card, className)} elevation={2}>
      <div className={cn(styles.leftSection)}>
        <div className={cn(styles.bannerContainer)}>
          <WebpImage className={cn(styles.banner)} src="/images/welcome.jpg" alt={t('common.appIconAlt')} />
        </div>
        <div className={cn(styles.bannerContentContainer)}>
          <AppIcon className={cn(styles.bannerAppIcon)} alt={t('login.welcomeAlt')} onClick={() => navigate('/')} />
          <div className={cn(styles.bannerSpace)} />
          <h1 className={cn(styles.bannerSlogan1)}>{t('login.bannerSlogan1')}</h1>
          <h2 className={cn(styles.bannerSlogan2)}>{t('login.bannerSlogan2')}</h2>
        </div>
      </div>
      <div className={cn(styles.rightSection)}>
        <Form
          className={styles.form}
          classes={{
            root: styles.loading,
            overlay: styles.overlay,
          }}
          isLoading={isLoading} 
        >
          {children}
        </Form>
      </div>
    </Paper>
  )
}

export default BannerForm