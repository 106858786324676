import * as React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import cn from 'classnames'
import ExploreShopIcon from 'assets/svg/ic_explore_shop.inline.svg'
import ClickActivateIcon from 'assets/svg/ic_click_activate.inline.svg'
import ShopConnectIcon from 'assets/svg/ic_shop_connect.inline.svg'
import ShoppingIcon from 'assets/svg/ic_shopping.inline.svg'
import AwaitCashbackIcon from 'assets/svg/ic_await_cashback.inline.svg'

import * as styles from './styles.module.css'


const iconArr = [
  {
    component: ExploreShopIcon
  },
  {
    component: ClickActivateIcon
  },
  {
    component: ShopConnectIcon
  },
  {
    component: ShoppingIcon
  },
  {
    component: AwaitCashbackIcon
  }
]

const steps = [
  'SearchPartner',
  'BuildPurchaseLink',
  'RedirectShop',
  'Shopping',
  'WaitCashBack'
]

const HowItWorkSection = () => {
  const { t } = useTranslation()

  const icon = (index, type) => {
    const value = iconArr[index]
    return <value.component className={styles.stepIcon} alt={t(`landing.howItWork${type}Alt`)} />
  }
  const stepItem = (index, type) => (
      <div className={styles.stepRoot}>
        {icon(index, type)}
        <p className={styles.txtStep}>{t(`landing.howItWorkStep${index + 1}`)}</p>
        <p className={styles.stepTitle}>{t(`landing.howItWork${type}`)}</p>
        <p className={styles.stepSubTitle}>{t(`landing.howItWork${type}Sub`)}</p>
      </div>
    )
  return (
    <>
      <div className={cn(styles.sectionRoot, 'container')}>
        <h3>
          {t('landing.howItWork')}
        </h3>
        <span className={styles.txtSubTitle}>{t('landing.howItWorkSubTitle')}</span>
        <div className={styles.contentRoot}>
          {
            steps.map((item, index) => (
              stepItem(index, item)
            ))
          }
        </div>
      </div>
    </>
  )
}

export default HowItWorkSection