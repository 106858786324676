import { useState, useEffect } from 'react'
import { useApiCall, getFirstErrorCode } from 'api'
import { useLoading } from 'libs/form/loadingHook'
// const fields = {
//   fieldId: {
//     value: '',
//     validators: [],
//     isRequired: false,
//     isChanged: false,
//     hasError: true,
//     errorCode: null,
//   }
// }

// const validateGroups = [
//   {
//     isCondition: true,
//     rule: {
//       targetFieldId: '',
//       condition: x,
//     },
//     deps: [],
//   }
// ]

// formType = 'create' | 'update'
export const useForm = (initFieldsMap, validateGroups = [], formType = 'create') => {
  const [state, setState] = useState({
    form: {
      formType,
      hasError: false,
      errorCode: null
    },
    validateGroups: validateGroups,
    fields: {
      ...initFieldsMap, 
    },
  })

  const { isLoading, processAsyc } = useLoading() 
  const { requestGraphQL, requestGraphQLFiles } = useApiCall()

  /**
   * @param fieldId
   */
  const getFormFieldState = (fieldId) => {
    return state.fields[fieldId] || {}
  }

  /**
   * @param fields
   * @param fieldId
   * @param newValue
   */
  const validateField = (fields, fieldId, newValue, isForce = false) => {
    let hasError = false
    let errorCode = null

    if (!fields[fieldId].isOptional || newValue || isForce) {
      const oldValue = fields[fieldId].value
      if (fields[fieldId].validators) {
        fields[fieldId].validators.some((validator) => {
          errorCode = validator(newValue, oldValue, fields)
          hasError = !!errorCode
          return hasError
        })
      }
    }

    return {
      hasError,
      errorCode,
      isChanged: true,
    }
  }

  const filterFields = (fields, validateGroups) => {
    if (validateGroups.length === 0) {
      return fields
    }
    const filterDepIds = []
    for (const validateGroup of validateGroups) {
      if (validateGroup.isCondition) {
        const field = fields[validateGroup.rule.targetFieldId]
        if (validateGroup.rule.condition === field.value) {
          filterDepIds.push(...validateGroup.deps)
        }
      } else {
        filterDepIds.push(...validateGroup.deps)
      }
    }
    const filteredFields = Object.keys(fields)
      .filter(fieldId => filterDepIds.includes(fieldId))
      .reduce((acc, fieldId) => {
        acc[fieldId] = fields[fieldId];
        return acc;
      }, {});
    fields = filteredFields

    return fields
  }

  /**
   * @param targetFieldId
   * @param newFieldHasError
   */
  const validateFormState = () => {
    const fields = filterFields(state.fields, state.validateGroups)
    const isHasError = Object.entries(fields).some(([, field]) => field.hasError)
    return isHasError
  }

  /**
   * @param fieldId
   * @param newValue
   */
  const onChangeValidation = (fieldId, newValue) => {
    const newFieldState = validateField(state.fields, fieldId, newValue)
    let newChangeHasError = newFieldState.hasError

    // Update specific field
    state.fields[fieldId] = {
      ...state.fields[fieldId],
      ...newFieldState,
      value: newValue,
    }

    // Validate Dependency
    const { dependency } = state.fields[fieldId]
    if (dependency && Array.isArray(dependency)) {
      const errs = dependency.map(depFieldId => {
        const _newFieldState = validateField(state.fields, depFieldId, state.fields[depFieldId].value, true)
        state.fields[depFieldId] = {
          ...state.fields[depFieldId],
          ..._newFieldState,
        }
        return _newFieldState.hasError
      })
      const hasError = errs.some(err => err)
      newChangeHasError = newChangeHasError || hasError
    }
    
    const newFormHasError = validateFormState()

    state.form.hasError = newFormHasError
    setState({
      ...state
    })
  }

  /**
   *
   */
  const isFormReady = () => {
    let isReady = true
    const fields = filterFields(state.fields, state.validateGroups)

    if (state.form.formType === 'create') {
      isReady = Object.entries(fields).every(([, field]) => field.isChanged || field.isOptional)
    } else if (state.form.formType === 'update') {
      isReady = Object.entries(fields).some(([, field]) => field.isChanged)
    }

    return !state.form.hasError && isReady && !isLoading
  }

  /**
   * @param fieldsMap
   */
  const initFormFields = (fieldsMap) => {
    const fields = {}
    Object.entries(fieldsMap).forEach(([key, field]) => {
      let fieldState = {
        hasError: false,
        errorCode: null,
        isChanged: false,
      }
      if (field.value) {
        fieldState = validateField(fieldsMap, key, field.value)
        fieldState.isChanged = false
      }

      fields[key] = {
        ...field,
        ...fieldState,
      }
    })
    return fields
  }

  /**
   * @param promiseRequest
   */
  const requestAsyc = async (promiseRequest) => {
    const response = await processAsyc(promiseRequest)
    const hasError = response.error && !response.error.isSystemError && !response.error.isRequestFail
    state.form = {
      hasError,
      errorCode: hasError ? getFirstErrorCode(response) : null
    }
    setState({
      ...state
    })
    return response
  }

  /**
   * @param query
   * @param variables
   * @param token
   * @param options
   */
  const callApi = async (query, variables, options) => {
    const response = await requestAsyc(requestGraphQL(query, variables, options))
    return response
  }

  const callApiFiles = async (query, variables, fileObj, options) => {
    const response = await requestAsyc(requestGraphQLFiles(query, variables, fileObj, options))
    return response
  }

  const initForm = (fieldsMap) => {
    Object.entries(fieldsMap).forEach(([key, field]) => {
      state.fields[key].value = field.value
    })
    setState({
      ...state
    })
  }

  // init fields map
  useEffect(() => {
    setState({
      ...state,
      fields: initFormFields(initFieldsMap)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return {
    formState: state,
    hasError: state.form.hasError,
    requestGraphQL: callApi,
    requestGraphQLFiles: callApiFiles,
    isLoading,
    requestAsyc,
    formErrorCode: state.form.errorCode,
    isFormReady,
    initForm,
    getFormFieldState,
    onChangeValidation,
  }
}