import React from 'react'
import cn from 'classnames'
import * as styles from './styles.module.css'

const PrivacyPage = () => (
    <div className={cn(styles.root, 'container')}>
      <h1>Privacy Policy</h1>
      <p>Coyns wants to ensure that your privacy is protected and this privacy policy relates how uses and protects any information provided to Coyns when you use the Service. Any information you provide will only be used in accordance with this privacy statement.</p>
      <p>This Privacy Policy governs the manner in which Coyns collects, uses, maintains and discloses information collected from users (each, a "User") ofthe Trigether.com online service, our mobile, tablet applications (the "Service"). This privacy policy applies to the Service and all products and services offered by Coyns Limited.</p>
      <h3>Personal identification information</h3>
      <p>We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site fill out a form respond to a survey subscribe to the newsletter and in connection with other activities, services, features or resources we make available on our Service. Users may be asked for, as appropriate:</p>
      <ul>
      <li>name</li>
      <li>contact information including email address</li>
      <li>demographic information such as postcode, preferences and interests</li>
      <li>additional information related to customer surveys or offers</li>
      </ul>
      <h3>Security</h3>
      <p>To stop any unauthorized access or disclosure of your information, appropriate electronic, physical and managerial procedures have been implemented to protect the information we gather online and ensure its security.</p>
      <p>We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.</p>
      <h3>Non-personal identification information</h3>
      <p>We may collect non-personal identification information about Users whenever they interact with ourService. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to ourService, such as the operating system and the Internet service providers utilized and other similar information.</p>
      <h3>Web Browser Cookies</h3>
      <p>Our Service may use &quot;cookies&quot; to enhance User experience. User&apos;s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Service may not function properly.</p>
      <h3>How We Use Collected Information</h3>
      <p>Coyns collects and uses Users personal information for the following purposes:</p>
      <ul>
      <li>To improve customer service. Your information helps us to more effectively respond to your customer service requests and support needs.</li>
      <li>To personalize user experience. We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Service.</li>
      <li>To improve our Service. We continually strive to improve our website offerings based on the information and feedback we receive from you.</li>
      </ul>
      <p>The email address Users provide for order processing, will only be used to send them information and updates pertaining to their order. It may also be used to respond to their inquiries, and/or other requests or questions. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, we include User may contact us via our Service.</p>
      <h3>How We Protect Your Information</h3>
      <p>We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Service. Sensitive and private data exchange between the Service and its Users happens over a SSL secured communication channel.</p>
      <h3>Sharing Your Personal Information</h3>
      <p>We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
      <h3>Third Party Websites.</h3>
      <p>Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Service. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Service, is subject to that website&apos;s own terms and policies.</p>
      <h3>Advertising</h3>
      <p>Ads appearing on our site may be delivered to Users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile non personal identification information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This privacy policy does not cover the use of cookies by any advertisers.</p>
      <h3>Google AdSense</h3>
      <p>Some of the ads may be served by Google. Google&apos;s use of the DART cookie enables it to serve ads to Users based on their visit to our Service and other sites on the Internet. DART uses &quot;non personally identifiable information&quot; and does NOT track personal information about you, such as your name, email address, physical address, etc. You may opt out of the use of the DART cookie by visiting the Google ad and content network privacy policy at <a href="http://www.google.com/privacy_ads.html">http://www.google.com/privacy_ads.html</a>,</p>
      <h3>Compliance with children&apos;s online privacy protection act</h3>
      <p>Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at our Service from those we actually know are under 13, and no part of our website is structured to attract anyone under 13.</p>
      <h3>Changes to This Privacy Policy</h3>
      <p>Coyns has the discretion to update this privacy policy at any time. When we do, revise the updated date at the bottom of this page. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>
      <h3>Your Acceptance of These Terms</h3>
      <p>By using this Service, you signify your acceptance of this policy and https://www.Coyns.com/terms. If you do not agree to this policy, please do not use our Service. Your continued use of the Service following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
      <h3>Contacting Us</h3>
      <p>If you have any questions about this Privacy Policy, the practices of this service, or your dealings with this service, please contact us at:</p>
      <p>Coyns<br /><a href="https://www.coyns.co">https://www.coyns.co</a><br /><a href="mailto:hello@coyns.co?Subject=Enquiry:%20">hello@coyns.co</a><br />Last Updated: August 1, 2024</p>
    </div>
  )

  export default PrivacyPage