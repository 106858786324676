import React, { useEffect, useState } from 'react'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import FillButton from 'components/presenter/buttons/FillButton'
import FormTextField from 'components/presenter/form/FormTextField'
import BannerForm from 'components/presenter/form/banner/BannerForm'
import BannerFormContent from 'components/presenter/form/banner/BannerFormContent'
import { EmptyValidator, PasswordValidator, EqualFieldsValidator } from 'libs/form/validator'
import { useForm } from 'libs/form/formHook'
import jwt from 'libs/crypt/jwt'
import cn from 'classnames'
import { useAuthStore } from 'stores'
import Page from 'components/presenter/page/Page'
import * as styles from './reset.module.css'

const passwordId = 'password'
const confirmPasswordId = 'confirm-password'

const ResetPasswordPage = ({ location }) => {
  const { t } = useTranslation()
  const { language, navigate } = useI18next()

  const authStore = useAuthStore()

  const [ state, setState ] = useState({
    isActivating: true,
    email: '',
    token: '',
  })

  const { onChangeValidation, getFormFieldState, isFormReady, requestAsyc, isLoading, formErrorCode } = useForm({
    [passwordId]: {
      validators: [EmptyValidator, PasswordValidator],
    },
    [confirmPasswordId]:{
      validators: [EmptyValidator, PasswordValidator, EqualFieldsValidator([passwordId])],
    },
  })

  /**
   *
   */
  const callResetPasswordApi = React.useCallback(async () => {
    const response = await requestAsyc(authStore.resetPassword({
      token: state.token,
      password: getFormFieldState(passwordId).value,
      lang: language
    }))

    if (!response.error) {
      const urlParams = new URLSearchParams({
        email: state.email,
      })
      navigate(`/reset-password/success/?${urlParams.toString()}`)
    }
  }, [language, getFormFieldState])

  /**
   *
   */
  const decodeToken = () => {
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')
    if (!token) {
      setState({
        ...state,
        isActivating: false,
      })
      return
    }
    const decoded = jwt.getPayload(token)
    state.email = decoded.username
    state.token = token
    setState({
      ...state,
    })
  }

  useEffect(() => {
    decodeToken()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Page seoMeta={{
      title: t('resetPassword.title')
    }}>
      <BannerForm isLoading={isLoading}>
        <BannerFormContent
          title={t('resetPassword.title')}
          subTitle={t('resetPassword.description', {
            email: state.email
          })}
        >
          <FormTextField
            id={passwordId}
            fieldData={getFormFieldState(passwordId)}
            className={cn(styles.input)}
            label={t('common.password')}
            type="password"
            onChange={onChangeValidation}
          />
          <FormTextField
            id={confirmPasswordId}
            fieldData={getFormFieldState(confirmPasswordId)}
            className={cn(styles.input)}
            label={t('common.confirmPassword')}
            type="password"
            onChange={onChangeValidation}
          />
          <p className={cn(styles.errorMsg)}>{formErrorCode ? `*${t(`errorCodes.${formErrorCode}`)}` : ''}</p>
          <FillButton
            className={cn(styles.btnReset)}
            disabled={!isFormReady() && !!state.token}
            onClick={callResetPasswordApi}
          >
            {t('common.reset')}
          </FillButton>
        </BannerFormContent>
      </BannerForm>
    </Page>
  )
}

export default ResetPasswordPage