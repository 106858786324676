import * as React from 'react'
import FillButton from 'components/presenter/buttons/FillButton'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { EmptyValidator } from 'libs/form/validator'
import { useSnackbar } from 'libs/ui/snackbar'
import FormTextField from 'components/presenter/form/FormTextField'
import TextField from 'components/presenter/input/TextField'
import Form from 'components/presenter/form/Form'
import cn from 'classnames'
import { useUserStore } from 'stores'
import { useForm } from 'libs/form/formHook'
import UploadAvatar from 'components/presenter/form/UploadAvatar'
import * as styles from './editProfile.module.css'

const nameId = 'name'

const EditProfileSection = () => {
  const { t } = useTranslation()
  const userStore = useUserStore()
  const { showSuccess } = useSnackbar()

  const { onChangeValidation, getFormFieldState, requestAsyc, isFormReady, isLoading, formErrorCode, initForm } = useForm({
    [nameId]: {
      validators: [EmptyValidator],
    },
  })
  
  const [userProfile, setUserProfile] = React.useState(null)
  React.useEffect(() => {
    const profile = userStore.getUserProfile()
    if (profile) {
      setUserProfile(profile)

      initForm({
        [nameId]: {
          value: profile.name
        }
      })
    }
  }, [userStore.getUserProfile()])

  async function updateProfile() {
    const response = await requestAsyc(userStore.updateProfile(
      getFormFieldState(nameId).value
    ))
    if (!response.error) {
      showSuccess(t('common.success'))
    }
  }

  const uploadProfileImage = async (file) => {
    const response = await requestAsyc(userStore.uploadProfileImage(file))
    if (!response.error) {
      showSuccess(t('common.success'))
    }
  }
  
  const name = !userProfile ? '' : userProfile.name 
  const url = !userProfile || !userProfile.profile_image ? null : userProfile.profile_image.url

  return (
    !userProfile 
    ? <></>
    : <Form className={styles.form} isLoading={isLoading}>
        <h1 className={styles.title}>{t('profile.editYourProfile')}</h1>
        <UploadAvatar
          className={styles.profileSection}
          name={name}
          url={url}
          uploadBtnText={t('profile.uploadAPhoto')}
          onCrop={uploadProfileImage}
          cropOption={{
            croppedImageWidth: 600,
            croppedImageHeight: 600,
          }}
        />
        <FormTextField
          className={cn(styles.input, 'mui-textfield-readonly')}
          fieldData={{
            value: userProfile.email,
            isOptional: true,
          }}
          label={t('common.email')}
          type="email"
          disabled
        />
        <FormTextField
          id={nameId}
          fieldData={getFormFieldState(nameId)}
          className={cn(styles.input)}
          label={t('profile.fullName')}
          type="text"
          onChange={onChangeValidation}
        />
        <p className={cn(styles.errorMsg)}>{formErrorCode ? `*${t(`errorCodes.${formErrorCode}`)}` : ''}</p>
        <FillButton 
          className={cn(styles.btnUpdate)}
          disabled={!isFormReady()}
          onClick={updateProfile}
        >
          {t('common.update')}
        </FillButton>
      </Form>
  )
}

export default EditProfileSection