const {
  FRAGMENT_USER_PROFILE_CORE,
  FRAGMENT_COMPANY_PROFILE_CORE,
  FRAGMENT_LOCATION_CORE,
  FRAGMENT_NEWS_LIST_CORE
} = require('./fragments')

exports.AUTH_CREATE_ANONYMOUS = `
mutation {
  create_anonymous {
    access_token
  }
}
`

exports.AUTH_REGISTER = `
mutation AuthRegister (
  $input: AuthRegisterInput!
) {
  auth_register (
    input: $input
  )
}
`

exports.AUTH_VERIFY_REGISTER = `
mutation AuthVerifyRegister (
  $token: String!
) {
  auth_verify_register (
    token: $token
  ) {
    access_token
  }
}
`

exports.AUTH_LOGIN = `
mutation AuthLogin (
  $input: AuthLoginInput!
) {
  auth_login (
    input: $input
  ) {
    access_token
  }
}
`

exports.AUTH_LOGOUT = `
mutation {
  auth_logout
}
`

exports.AUTH_FORGET_PASSOWRD = `
mutation AuthForgetPassword (
  $userName: String!
) {
  auth_forget_password (
    username: $userName
  )
}
`

exports.AUTH_RESET_PASSWORD = `
mutation AuthResetPassword (
  $input: AuthResetPasswordInput!
) {
  auth_reset_password (
    input: $input
  ) {
    access_token
  }
}
`

exports.CREATE_COMPANY_PROFILE = `
mutation CreateCompanyProfile (
  $input: CreateCompanyProfileInput!
  $profileFile: Upload
) {
  create_company_profile (
    input: $input
    profileFile: $profileFile
  ) {
    ...fragmentCompanyProfileCore
  }
}
${FRAGMENT_COMPANY_PROFILE_CORE}
`

exports.UPDATE_USER_PROFILE = `
mutation UpdateUserProfile (
  $input: UpdateUserProfile
  $profileFile: Upload
) {
  update_user_profile (
    input: $input
    profileFile: $profileFile
  ) {
    ...fragmentUserProfileCore
  }
}
${FRAGMENT_USER_PROFILE_CORE}
`

exports.UPDATE_USER_PASSWORD = `
mutation UpdateUserPassword (
  $input: UpdateUserPassword!
) {
  update_user_password(
    input: $input
  )
}
`

exports.VIEW_NEWS = `
mutation ViewNews (
  $news_id: ID!
) {
  view_news(news_id: $news_id)
}
`

exports.GENERATE_AFFILIATE_LINK = `
mutation GenerateAffiliateLink (
  $affiliateId: ID!
) {
  generate_affiliate_link(affiliateId: $affiliateId) {
		domain
		params
	}
}
`

exports.CREATE_BANK_AND_TAX = `
mutation CreateBankAndTax (
  $bank: CreateUserBankAccount!
  $tax: CreateUserTaxation!
) {
  create_bank_and_tax(bank: $bank, tax: $tax) {
		bank {
			account_name
      account_number
			country_code
			bank_name
			swift_code
			branch_code
			bank_code
			branch_name
			bank_address
			updated_at
		}
		tax {
			account_type
			id_type
			id_number
      nationality
			name_on_id
			country_code
			phone_number
			gender
			date_of_birth
			residential_address
			business_license_id
			tax_id
			updated_at
		}
	}
}
`

exports.JOIN_CAMPAIGN = `
mutation JoinCampaign (
  $code: String!
) {
  join_campaign(code: $code) {
    _id
		campaign {
			_id
			name
			created_at
			expired_at
			pass_life_time
      pass_quota
			ratio
			type
		}
		created_at
		expired_at
	}
}
`

exports.REQUEST_WITHDRAW = `
mutation RequestWithdraw {
	request_withdraw {
		_id
		bank_transfer_at
		bank_transfer_status
		updated_at
		created_at
	}
}
`

exports.LINK_CAMPAIGN_PASS = `
mutation LinkCampaignPass (
  $passId: ID!
) {
  link_campaign_pass(passId: $passId) {
		_id
		campaign {
			_id
			name
			created_at
			expired_at
			pass_life_time
      pass_quota
			ratio
			type
		}
		created_at
		expired_at
	}
}
`