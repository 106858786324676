import React from 'react'
// import { useI18next } from 'gatsby-plugin-react-i18next'
import { useAuthStore } from 'stores'
import { APIErrorHandler} from 'api'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby' 

// BUG: Cannot detect language change
const authPaths = [
  '/store/link',
  '/profile'
]

const Application = ({children}) => {
  const authStore = useAuthStore()
  const location = useLocation()

  React.useEffect(() => {
    // First Load code
    // BUG: Need hook to know i18next initialied
    // authStore.actions.bootApp()
    authStore.bootApp()
  }, [])


  // Handle auto redirect if not login
  React.useEffect(() => {
    if (authStore.isInitial() && authStore.isAnonymous()) {
      for(const path of authPaths) {
        if(location.pathname.includes(path)) {
          navigate("/")
          break
        }
      }
    }
  }, [authStore.isInitial(), authStore.isAnonymous()])
  

  return (
    <>
      {children}
      <APIErrorHandler />
    </>
  )
}
export default Application