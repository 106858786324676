// extracted by mini-css-extract-plugin
export const root = "profile-module--root--te+5v";
export const paper = "profile-module--paper--RG2PK";
export const form = "profile-module--form--imEab";
export const input = "profile-module--input--GOUiH";
export const inputFile = "profile-module--inputFile--trhNg";
export const errorMsg = "profile-module--errorMsg--FY3Gh";
export const btnUpdate = "profile-module--btnUpdate--Mxn6e";
export const title = "profile-module--title--Bt0t1";
export const profileImage = "profile-module--profileImage--J7cMa";
export const profileSection = "profile-module--profileSection--d7KM5";
export const btnUpload = "profile-module--btnUpload--yx9Ca";
export const passwordTitle = "profile-module--passwordTitle--6tRsE";