import * as React from 'react'
import Page from 'components/presenter/page/Page'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useAuthStore } from 'stores'
import { apiManager } from 'api'
import { usePageLoad } from 'libs/ui/pageLoad'
// import BannerSection from './BannerSectionOld'
// import HorizontalNewsList from './HorizontalNewsList'
// import LandingHeader from './LandingHeader'
import cn from 'classnames'
import BannerSection from './BannerSection'
import HowItWorkSection from './HowItWorkSection'
import ExploreSection from './ExploreSection'
import CheckCachbackBox from './CheckCachbackBox'
// import PromotionSolutionSection from './PromotionSolutionSection'
import SloganSection from './SloganSection'
import * as styles from './styles.module.css'

const HomePage = ({location, pageContext}) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const authStore = useAuthStore()
  const [ homeDataSet, setHomeDataSet] = React.useState(null)
  const [checkCashbackResult, setCheckCashbackResult] = React.useState(false)

  const loadHomeData = async () => {
    if (!homeDataSet) {
      const response = await apiManager.fetchHomeList({
        lang: language
      })
      if (!response.error) {
        setHomeDataSet(response)
      }
    }
  }
  const homeData = homeDataSet || pageContext.homeSSR
  // const listFeaturedNews = homeData?.list_featured_news
  // const listHomeNews = homeData?.list_home_news
  // const listHomeBanners = homeData?.list_home_banners

  const { isPageLoaded } = usePageLoad({
    pageLoadDeps: [authStore.isInitial()],
    onPageLoad: loadHomeData
  })

  return (
    <Page 
      className={styles.page}
      location={location}
      isLoading={!isPageLoaded}>
      <div className={styles.heroSection} style={{paddingBottom: checkCashbackResult ? '166px' : '105px'}}>
        <BannerSection className={styles.hero}/>
        <CheckCachbackBox 
          className={cn(styles.checkCachbackBox, 'container')} 
          onChange={(result, errorCode) => {
            setCheckCashbackResult(!!result || !!errorCode)
          }}
        />
      </div>
      <ExploreSection homeData={homeData}/>
      <HowItWorkSection />
      {/* <HowItDesignSection /> */}
      {/* <PromotionSolutionSection /> */}
      <SloganSection />
    </Page>
  )
}

export default HomePage