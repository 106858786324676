import { getLocalCache, setLocalCache, accessTokenKey } from 'stores/localStorage'
import jwt from 'libs/crypt/jwt'
import { AUTH_CREATE_ANONYMOUS, AUTH_VERIFY_REGISTER, AUTH_LOGIN, AUTH_LOGOUT, AUTH_RESET_PASSWORD } from 'api/coyns/graphql/mutations'
import { apiManager } from 'api'

/**
 * @param token
 */
function generateTokenObject(token) {
    const payload = jwt.getPayload(token)
    return {
        token: jwt.isExpired(payload) ? null : token,
        payload
    }
}

/**
 * Auth State
 * 
 * @typedef {object} AuthState
 * @property {string} token - Acess Token
 * @property {object} payload - Jwt payload
 */

const initState = {
    ...generateTokenObject(getLocalCache(accessTokenKey)),
    _isInitial: false,
}


const isAnonymous = (state) => state.payload ? state.payload.is_anonymous : true
const isLoggedIn = (state) => state.token && !jwt.isExpired(state.payload) && !isAnonymous(state)
const isTokenExpired = (payload) => jwt.isExpired(payload)
const setInitialFinish = (set) => {
    set((state) => ({
        auth: {
            ...state.auth,
            _isInitial: true,
        }
    }))
}

const handleClearApp = async (set, get) => {
    setAccessToken(null, set)
    get().user.clear()
  }

const handleInitApp = async (set, get) => {
    const state = get().auth
    if (isAnonymous(state)) {
        setInitialFinish(set)
        return
    }

    const responses = await Promise.all([
        get().user.fetchUserInformation()
    ])
    const hasError = responses.some(response => !!response.error)
    if (!hasError) {
        setInitialFinish(set)
    }
}

const setAccessToken = (token, set) => {
    setLocalCache(accessTokenKey, token)
    apiManager.setAccessToken(token)
    set((state) => ({
        auth: {
            ...state.auth,
            ...generateTokenObject(token)
        }
    }))
}

const createAnonymous = async (set) => {
    const response = await apiManager.createAnonymous()
    if (!response.error) {
      const payload = response.create_anonymous
      setAccessToken(payload.access_token, set)
    }
    return response
}

export const createAuthSlice = (set, get) => ({
    auth: {
        ...initState,
        isLoggedIn: () => isLoggedIn(get().auth),
        isAnonymous: () => isAnonymous(get().auth),
        isInitial: () => get().auth._isInitial,
        getAccessToken: () => get().auth.token,
        isAccessTokenExpired: () => isTokenExpired(get().auth.payload),
        bootApp: async () => {
            const state = get().auth
            if (!state.payload || isTokenExpired(state.payload)) {
                await createAnonymous(set)
            } else {
                apiManager.setAccessToken(state.token)
            }
            await handleInitApp(set, get)
        },
        login: async (input) => {
            const response = await apiManager.login(input)
            if (!response.error) {
                const payload = response.auth_login
                setAccessToken(payload.access_token, set)
                await handleInitApp(set, get)
            }
            return response
        },
        verifyEmail: async(token) => {
            const response = await apiManager.verifyEmail(token)
            if (!response.error) {
              const payload = response.auth_verify_register
              setAccessToken(payload.access_token, set)
              await handleInitApp(set, get)
            }
            return response
        },
        logout: async () => {
            const response = await apiManager.logout()
            if (!response.error) {
                handleClearApp(set, get)
                await createAnonymous(set)
            }
            return response
        },
        resetPassword: async (input) => {
            const response = await apiManager.resetPassword(input)
            if (!response.error) {
              const payload = response.auth_reset_password
              setAccessToken(payload.access_token, set)
              await handleInitApp(set, get)
            }
            return response
        }
    }
})