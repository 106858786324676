import React from 'react'
import Page from 'components/presenter/page/Page'
import { useTranslation, useI18next, Trans } from 'gatsby-plugin-react-i18next'
import { useAuthStore } from 'stores'
import { Paper } from '@material-ui/core'
import cn from 'classnames'
import { usePageLoad } from 'libs/ui/pageLoad'
import AppIcon from 'assets/svg/app_icon.inline.svg'
import Lottie from 'react-lottie';
import { apiManager } from 'api'
import * as animationData from 'assets/lottie/linkage.json'
import * as styles from './styles.module.css'


const handleRedirect = (domain, linkParams, redirectUrl) => {
    const appendParmsIntoUrl = (redirectUrl) => {
        let url = new URL(redirectUrl)
        let params = new URLSearchParams(url.search)

        Object.entries(linkParams).forEach(([key, value]) => {
            if (params.has(key)) {
                params.set(key, value);
            } else {
                params.append(key, value);
            }
        })

        // Update the URL search query
        url.search = params.toString()
        return url.toString()
    }

    window.location.replace(appendParmsIntoUrl(redirectUrl || domain))
}

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: animationData
}

const StoreLinkPage = ({location}) => {
  const { t } = useTranslation()
  const { navigate } = useI18next()
  const authStore = useAuthStore()

  const searchParams = new URLSearchParams(location.search)
  const _affiliateId = searchParams.get('id')
  const logoUrl = searchParams.get('logo') ? decodeURIComponent(searchParams.get('logo')) : null
  const companyName = searchParams.get('name')
  const redirectUrl = searchParams.get('redirect_url') ? decodeURIComponent(searchParams.get('redirect_url')) : null
  const skipLink = searchParams.get('skip_link') ? decodeURIComponent(searchParams.get('skip_link')) : null

  const { isPageLoaded } = usePageLoad({
    pageLoadDeps: [authStore.isInitial()],
    onPageLoad: () => {},
  })

  React.useEffect(async () => {
    if (isPageLoaded) {
        if (authStore.isLoggedIn()){
            const response = await apiManager.generateAffiliateLink(_affiliateId)
            if (!response.error) {
                const { domain, params } = response.generate_affiliate_link
                if (skipLink) {
                    window.open(skipLink, '_blank')
                    handleRedirect(domain, params, redirectUrl)
                } else {
                    const randomTime = Math.floor(Math.random() * 2001) + 2000;
                    setTimeout(() => {
                        handleRedirect(domain, params, redirectUrl)
                    }, randomTime) 
                }
            } else {
                navigate("/")
            }
        }
    }
  }, [isPageLoaded])

  if (skipLink) {
    return (
      <Page 
        className={cn(styles.page, 'container')}
        location={location}
        seoMeta={{
          title: t('affiliate.connecting')
        }}
      >
      </Page>
    )
  }

  return (
    <Page 
      className={cn(styles.page, 'container')}
      location={location}
      seoMeta={{
        title: t('affiliate.connecting')
      }}>
        <Paper className={cn(styles.card)} elevation={2}>
            <span className={cn(styles.activatingCashback)}>
                {t('affiliate.activatingCashback')}
            </span>
            <div className={cn(styles.iconGroup)}>
                <AppIcon className={cn(styles.logo)} alt={t('common.appName')} />
                
                <div className={cn(styles.linkLogo)}>
                    <Lottie
                        options={defaultOptions}
                        isClickToPauseDisabled={true} />
                </div>
                
                {logoUrl && <img className={cn(styles.logo)} src={logoUrl} alt={companyName || 'Company logo'} />}
            </div>
            <span className={cn(styles.content)}>
                <Trans
                    i18nKey="affiliate.generateStoreLinkFormat"
                    components={[<strong/>]}
                    values={{ name: companyName }}
                />
            </span>
        </Paper>
    </Page>
  )
}

export default StoreLinkPage