import React from 'react'
import DefaultLayout from 'components/presenter/layout/DefaultLayout'
import PlainLayout from 'components/presenter/layout/PlainLayout'


const maping = {
  'default': DefaultLayout,
  'plain': PlainLayout,
}

export const LayoutSwitcher = (props) => {
  const { pageContext } = props
  const Layout = maping[pageContext.layout] || PlainLayout
  return <Layout {...props} />
}

export default LayoutSwitcher