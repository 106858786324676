// Required for gatsby-plugin-react-i18next
import { graphql } from 'gatsby'
import PrivacyPage from 'components/container/page/PrivacyPage'

export default PrivacyPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`