import * as React from 'react'
import TextField from 'components/presenter/input/TextField'
import { useTranslation  } from 'gatsby-plugin-react-i18next'
import cn from 'classnames'

const FormTextField = ({id, fieldData = {}, className, label, helperText = '', type, onChange, multiline = false, placeholder, disabled, InputProps = {}}) => {
  const { t } = useTranslation()
  return (
    <TextField
      id={id}
      name={id}
      className={cn(className)}
      label={label}
      type={type}
      disabled={disabled}
      required={!fieldData.isOptional}
      multiline={multiline}
      value={fieldData.value}
      onChange={(e) => onChange(id, e.target.value)}
      placeholder={placeholder}
      helperText={fieldData.hasError ? t(`errorCodes.${fieldData.errorCode}`) : helperText}
      error={fieldData.hasError}
      InputProps={InputProps}
    />
  )
}

export default FormTextField