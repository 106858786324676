import GoogleAnalytics4 from 'libs/tracker/platform/googleAnalytics4'

export const PLATFORM_GOOGLE_ANALYTICS_4 = 'google-analytics-4'

export const trackers = {
  [PLATFORM_GOOGLE_ANALYTICS_4]: new GoogleAnalytics4(process.env.GATSBY_GA_MEASUREMENT_ID)
}


function sendEvent (eventTracker, eventName, eventParams) {
  eventTracker.sendCustomEvent(eventName, eventParams, [ PLATFORM_GOOGLE_ANALYTICS_4 ])
}

export function sendGetJetso (eventTracker, {newsId, companyId}) {
  sendEvent(eventTracker, 'get_jetso', {
    'news_id': newsId,
    'company_id': companyId
  })
}
