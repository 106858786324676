import * as React from 'react'
import cn from 'classnames'
import { Backdrop, CircularProgress } from '@material-ui/core'

const LoadingBackdrop = ({
  className,
  open = false
}) => (
    <Backdrop className={cn('mui-backdrop', className)} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )

export default LoadingBackdrop