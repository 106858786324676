module.exports = {
  "en_US": [
    {
      "name": "Eat & Fun & Travel",
      "order": 1,
      "short_code": "a",
      "sub_category": [
        {
          "name": "Diet",
          "order": 1,
          "short_code": "a1",
          "sub_category": [
            {
              "name": "Buffet",
              "order": 1,
              "short_code": "a11"
            },
            {
              "name": "Chinese Food",
              "order": 2,
              "short_code": "a12"
            },
            {
              "name": "Japanese Korean Food",
              "order": 3,
              "short_code": "a13"
            },
            {
              "name": "Hot Pot",
              "order": 4,
              "short_code": "a14"
            },
            {
              "name": "Dessert",
              "order": 5,
              "short_code": "a15"
            },
            {
              "name": "Hotel Restaurant",
              "order": 6,
              "short_code": "a16"
            },
            {
              "name": "Steak House",
              "order": 7,
              "short_code": "a17"
            },
            {
              "name": "Social Enterprise Restaurant",
              "order": 8,
              "short_code": "a18"
            },
            {
              "name": "Hong Kong Style",
              "order": 9,
              "short_code": "a19"
            },
            {
              "name": "Noodles",
              "order": 10,
              "short_code": "a110"
            },
            {
              "name": "BBQ",
              "order": 11,
              "short_code": "a111"
            },
            {
              "name": "Seafood",
              "order": 12,
              "short_code": "a112"
            },
            {
              "name": "Coffee/Beverage",
              "order": 13,
              "short_code": "a113"
            },
            {
              "name": "Bread",
              "order": 14,
              "short_code": "a114"
            },
            {
              "name": "Pizza",
              "order": 15,
              "short_code": "a115"
            },
            {
              "name": "Private Kitchen",
              "order": 16,
              "short_code": "a116"
            },
            {
              "name": "Curry",
              "order": 17,
              "short_code": "a117"
            },
            {
              "name": "Fast Food",
              "order": 18,
              "short_code": "a118"
            },
            {
              "name": "Healthy Food / Vegetarian",
              "order": 19,
              "short_code": "a119"
            },
            {
              "name": "Seasonal / Traditional Food",
              "order": 20,
              "short_code": "a120"
            },
            {
              "name": "Food Truck",
              "order": 21,
              "short_code": "a121"
            },
            {
              "name": "Family Friendly",
              "order": 22,
              "short_code": "a122"
            },
            {
              "name": "Theme Restaurant",
              "order": 23,
              "short_code": "a123"
            },
            {
              "name": "Pet Friendly",
              "order": 24,
              "short_code": "a124"
            },
            {
              "name": "Nostalgic",
              "order": 25,
              "short_code": "a125"
            },
            {
              "name": "No Shark Fin",
              "order": 26,
              "short_code": "a126"
            },
            {
              "name": "Other",
              "order": 27,
              "short_code": "a1or"
            }
          ]
        },
        {
          "name": "Entertainment",
          "order": 2,
          "short_code": "a2",
          "sub_category": [
            {
              "name": "Toy",
              "order": 1,
              "short_code": "a21"
            },
            {
              "name": "Game",
              "order": 2,
              "short_code": "a22"
            },
            {
              "name": "Movie",
              "order": 3,
              "short_code": "a23"
            },
            {
              "name": "Party Room",
              "order": 4,
              "short_code": "a24"
            },
            {
              "name": "Playground",
              "order": 5,
              "short_code": "a25"
            },
            {
              "name": "Theme Park",
              "order": 6,
              "short_code": "a26"
            },
            {
              "name": "VR",
              "order": 7,
              "short_code": "a27"
            },
            {
              "name": "Other",
              "order": 8,
              "short_code": "a2or"
            }
          ]
        },
        {
          "name": "Tourism",
          "order": 3,
          "short_code": "a3",
          "sub_category": [
            {
              "name": "Air-Tickets",
              "order": 1,
              "short_code": "a31"
            },
            {
              "name": "Hotel",
              "order": 2,
              "short_code": "a32"
            },
            {
              "name": "Tourist Hotspot",
              "order": 3,
              "short_code": "a33"
            },
            {
              "name": "Tour Group",
              "order": 4,
              "short_code": "a34"
            },
            {
              "name": "Guided Tour",
              "order": 5,
              "short_code": "a35"
            },
            {
              "name": "Other",
              "order": 6,
              "short_code": "a3or"
            }
          ]
        }
      ]
    },
    {
      "name": "Life",
      "order": 2,
      "short_code": "b",
      "sub_category": [
        {
          "name": "Life Department Store",
          "order": 1,
          "short_code": "b1",
          "sub_category": [
            {
              "name": "Department Store",
              "order": 1,
              "short_code": "b11"
            },
            {
              "name": "Supermarket",
              "order": 2,
              "short_code": "b12"
            },
            {
              "name": "Convenience Store",
              "order": 3,
              "short_code": "b13"
            },
            {
              "name": "Groceries",
              "order": 4,
              "short_code": "b14"
            },
            {
              "name": "Market",
              "order": 5,
              "short_code": "b15"
            },
            {
              "name": "Shopping Mall",
              "order": 6,
              "short_code": "b16"
            },
            {
              "name": "Other",
              "order": 7,
              "short_code": "b1or"
            }
          ]
        },
        {
          "name": "Food",
          "order": 2,
          "short_code": "b2",
          "sub_category": [
            {
              "name": "Health",
              "order": 1,
              "short_code": "b21"
            },
            {
              "name": "Fine Wine",
              "order": 2,
              "short_code": "b22"
            },
            {
              "name": "Coffee",
              "order": 3,
              "short_code": "b23"
            },
            {
              "name": "Meat",
              "order": 4,
              "short_code": "b24"
            },
            {
              "name": "Fruit",
              "order": 5,
              "short_code": "b25"
            },
            {
              "name": "Snacks",
              "order": 6,
              "short_code": "b26"
            },
            {
              "name": "Drug",
              "order": 7,
              "short_code": "b27"
            },
            {
              "name": "Cake",
              "order": 8,
              "short_code": "b28"
            },
            {
              "name": "Tea",
              "order": 9,
              "short_code": "b29"
            },
            {
              "name": "Fish",
              "order": 10,
              "short_code": "b210"
            },
            {
              "name": "Other",
              "order": 11,
              "short_code": "b2or"
            }
          ]
        },
        {
          "name": "Clothing",
          "order": 3,
          "short_code": "b3",
          "sub_category": [
            {
              "name": "Outerwear",
              "order": 1,
              "short_code": "b31"
            },
            {
              "name": "Tops",
              "order": 2,
              "short_code": "b32"
            },
            {
              "name": "Bottoms",
              "order": 3,
              "short_code": "b33"
            },
            {
              "name": "Shoe",
              "order": 4,
              "short_code": "b34"
            },
            {
              "name": "Bags",
              "order": 5,
              "short_code": "b35"
            },
            {
              "name": "Dresses / Jumpsuits",
              "order": 6,
              "short_code": "b36"
            },
            {
              "name": "Ornaments",
              "order": 7,
              "short_code": "b37"
            },
            {
              "name": "Boutique",
              "order": 8,
              "short_code": "b38"
            },
            {
              "name": "Innerwear",
              "order": 9,
              "short_code": "b39"
            },
            {
              "name": "Watch",
              "order": 10,
              "short_code": "b310"
            },
            {
              "name": "Glasses",
              "order": 11,
              "short_code": "b311"
            },
            {
              "name": "Other",
              "order": 12,
              "short_code": "b3or"
            }
          ]
        },
        {
          "name": "Digital Product",
          "order": 4,
          "short_code": "b4",
          "sub_category": [
            {
              "name": "Mobile Communication",
              "order": 1,
              "short_code": "b41"
            },
            {
              "name": "Mobile Communication Accessories",
              "order": 2,
              "short_code": "b42"
            },
            {
              "name": "Photographic Equipment",
              "order": 3,
              "short_code": "b43"
            },
            {
              "name": "Computer",
              "order": 4,
              "short_code": "b44"
            },
            {
              "name": "Computer Accessories",
              "order": 5,
              "short_code": "b45"
            },
            {
              "name": "Acoustics",
              "order": 6,
              "short_code": "b46"
            },
            {
              "name": "Other",
              "order": 7,
              "short_code": "b4or"
            }
          ]
        },
        {
          "name": "Electronic",
          "order": 5,
          "short_code": "b5",
          "sub_category": [
            {
              "name": "Large Home Appliances",
              "order": 1,
              "short_code": "b51"
            },
            {
              "name": "Small Home Appliances",
              "order": 2,
              "short_code": "b52"
            },
            {
              "name": "Kitchen Appliances",
              "order": 3,
              "short_code": "b53"
            },
            {
              "name": "AV",
              "order": 4,
              "short_code": "b54"
            },
            {
              "name": "Health & Beauty",
              "order": 5,
              "short_code": "b55"
            },
            {
              "name": "Other",
              "order": 6,
              "short_code": "b5or"
            }
          ]
        },
        {
          "name": "Beauty",
          "order": 6,
          "short_code": "b6",
          "sub_category": [
            {
              "name": "Product",
              "order": 1,
              "short_code": "b61"
            },
            {
              "name": "Massage",
              "order": 2,
              "short_code": "b62"
            },
            {
              "name": "Treatment",
              "order": 3,
              "short_code": "b63"
            },
            {
              "name": "Manicure",
              "order": 4,
              "short_code": "b64"
            },
            {
              "name": "Other",
              "order": 5,
              "short_code": "b6or"
            }
          ]
        },
        {
          "name": "Other",
          "order": 7,
          "short_code": "b7",
          "sub_category": [
            {
              "name": "Baby Products",
              "order": 1,
              "short_code": "b71"
            },
            {
              "name": "Bed Linings",
              "order": 2,
              "short_code": "b72"
            },
            {
              "name": "Other",
              "order": 3,
              "short_code": "b7or"
            }
          ]
        }
      ]
    },
    {
      "name": "Education",
      "order": 3,
      "short_code": "c",
      "sub_category": [
        {
          "name": "Education",
          "order": 1,
          "short_code": "c1",
          "sub_category": [
            {
              "name": "Lanuage",
              "order": 1,
              "short_code": "c11"
            },
            {
              "name": "Maths",
              "order": 2,
              "short_code": "c12"
            },
            {
              "name": "Science",
              "order": 3,
              "short_code": "c13"
            },
            {
              "name": "Early Childhood Education",
              "order": 4,
              "short_code": "c14"
            },
            {
              "name": "Preschool Education",
              "order": 5,
              "short_code": "c15"
            },
            {
              "name": "Art",
              "order": 6,
              "short_code": "c16"
            },
            {
              "name": "Music",
              "order": 7,
              "short_code": "c17"
            },
            {
              "name": "Sport",
              "order": 8,
              "short_code": "c18"
            },
            {
              "name": "STEM",
              "order": 9,
              "short_code": "c19"
            },
            {
              "name": "Dance",
              "order": 10,
              "short_code": "c110"
            },
            {
              "name": "Drama",
              "order": 11,
              "short_code": "c111"
            },
            {
              "name": "Cooking",
              "order": 12,
              "short_code": "c112"
            },
            {
              "name": "Experience Class",
              "order": 13,
              "short_code": "c113"
            },
            {
              "name": "Medical",
              "order": 14,
              "short_code": "c114"
            },
            {
              "name": "Special education",
              "order": 15,
              "short_code": "c115"
            },
            {
              "name": "Woodwork",
              "order": 16,
              "short_code": "c116"
            },
            {
              "name": "Other",
              "order": 17,
              "short_code": "c1or"
            }
          ]
        }
      ]
    },
    {
      "name": "Event",
      "order": 4,
      "short_code": "d",
      "sub_category": [
        {
          "name": "Activity",
          "order": 1,
          "short_code": "d1",
          "sub_category": [
            {
              "name": "Festival",
              "order": 1,
              "short_code": "d11"
            },
            {
              "name": "Drama",
              "order": 2,
              "short_code": "d12"
            },
            {
              "name": "Concert",
              "order": 3,
              "short_code": "d13"
            },
            {
              "name": "Lecture",
              "order": 4,
              "short_code": "d14"
            },
            {
              "name": "Busking",
              "order": 5,
              "short_code": "d15"
            },
            {
              "name": "Exhibition",
              "order": 6,
              "short_code": "d16"
            },
            {
              "name": "Competition",
              "order": 7,
              "short_code": "d17"
            },
            {
              "name": "Other",
              "order": 8,
              "short_code": "d1or"
            }
          ]
        },
        // {
        //   "name": "Wedding",
        //   "order": 2,
        //   "short_code": "d2",
        //   "sub_category": [
        //     {
        //       "name": "Jewelry",
        //       "order": 1,
        //       "short_code": "d21"
        //     },
        //     {
        //       "name": "Venue",
        //       "order": 2,
        //       "short_code": "d22"
        //     },
        //     {
        //       "name": "Photography",
        //       "order": 3,
        //       "short_code": "d23"
        //     },
        //     {
        //       "name": "Make up",
        //       "order": 4,
        //       "short_code": "d24"
        //     },
        //     {
        //       "name": "Clothing",
        //       "order": 5,
        //       "short_code": "d25"
        //     },
        //     {
        //       "name": "Performance",
        //       "order": 6,
        //       "short_code": "d26"
        //     },
        //     {
        //       "name": "Decoration",
        //       "order": 7,
        //       "short_code": "d27"
        //     },
        //     {
        //       "name": "Float",
        //       "order": 8,
        //       "short_code": "d28"
        //     },
        //     {
        //       "name": "Other",
        //       "order": 9,
        //       "short_code": "d2or"
        //     }
        //   ]
        // }
      ]
    },
    // {
    //   "name": "Finance",
    //   "order": 5,
    //   "short_code": "e",
    //   "sub_category": [
    //     {
    //       "name": "Finance",
    //       "order": 1,
    //       "short_code": "e1",
    //       "sub_category": [
    //         {
    //           "name": "Financing",
    //           "order": 1,
    //           "short_code": "e11"
    //         },
    //         {
    //           "name": "Insurance",
    //           "order": 2,
    //           "short_code": "e12"
    //         },
    //         {
    //           "name": "Other",
    //           "order": 3,
    //           "short_code": "e1or"
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
      "name": "Property",
      "order": 6,
      "short_code": "f",
      "sub_category": [
        {
          "name": "Decoration",
          "order": 1,
          "short_code": "f1",
          "sub_category": [
            {
              "name": "Furniture",
              "order": 1,
              "short_code": "f11"
            },
            {
              "name": "Decoration",
              "order": 2,
              "short_code": "f12"
            },
            {
              "name": "Repair",
              "order": 3,
              "short_code": "f13"
            },
            {
              "name": "Lighting",
              "order": 5,
              "short_code": "f15"
            },
            {
              "name": "Hardware",
              "order": 6,
              "short_code": "f16"
            },
            {
              "name": "Other",
              "order": 7,
              "short_code": "f1or"
            }
          ]
        },
        {
          "name": "Professional Service",
          "order": 2,
          "short_code": "f3",
          "sub_category": [
            {
              "name": "Interior Design",
              "order": 1,
              "short_code": "f31"
            },
            {
              "name": "Building Inspection",
              "order": 2,
              "short_code": "f32"
            },
            {
              "name": "Window Inspection",
              "order": 3,
              "short_code": "f33"
            },
            {
              "name": "Pest Control",
              "order": 4,
              "short_code": "f34"
            },
            {
              "name": "Cleaning",
              "order": 5,
              "short_code": "f35"
            },
            {
              "name": "Other",
              "order": 6,
              "short_code": "f3or"
            }
          ]
        },
        // {
        //   "name": "Estate",
        //   "order": 3,
        //   "short_code": "f2",
        //   "sub_category": [
        //     {
        //       "name": "Property For Rent",
        //       "order": 1,
        //       "short_code": "f21"
        //     },
        //     {
        //       "name": "Property For Sale",
        //       "order": 2,
        //       "short_code": "f22"
        //     },
        //     {
        //       "name": "Other",
        //       "order": 3,
        //       "short_code": "f2or"
        //     }
        //   ]
        // }
      ]
    },
    {
      "name": "Other",
      "order": 7,
      "short_code": "g",
      "sub_category": [
        {
          "name": "Sport & Outdoor",
          "order": 1,
          "short_code": "g1",
          "sub_category": [
            {
              "name": "Sports products",
              "order": 1,
              "short_code": "g11"
            },
            {
              "name": "Fitness",
              "order": 2,
              "short_code": "g12"
            },
            {
              "name": "Outdoor Camping",
              "order": 3,
              "short_code": "g13"
            },
            {
              "name": "Bicycle",
              "order": 4,
              "short_code": "g14"
            },
            {
              "name": "Football",
              "order": 5,
              "short_code": "g15"
            },
            {
              "name": "Sword Fencing",
              "order": 6,
              "short_code": "g16"
            },
            {
              "name": "Water Sports",
              "order": 7,
              "short_code": "g17"
            },
            {
              "name": "Basketball",
              "order": 8,
              "short_code": "g18"
            },
            {
              "name": "Badminton",
              "order": 9,
              "short_code": "g19"
            },
            {
              "name": "Volleyball",
              "order": 10,
              "short_code": "g110"
            },
            {
              "name": "Table Tennis",
              "order": 11,
              "short_code": "g111"
            },
            {
              "name": "Archery",
              "order": 12,
              "short_code": "g112"
            },
            {
              "name": "Other",
              "order": 13,
              "short_code": "g1or"
            }
          ]
        },
        // {
        //   "name": "Professional Service",
        //   "order": 2,
        //   "short_code": "g2",
        //   "sub_category": [
        //     {
        //       "name": "Law",
        //       "order": 2,
        //       "short_code": "g22"
        //     },
        //     {
        //       "name": "Graphic Design",
        //       "order": 3,
        //       "short_code": "g23"
        //     },
        //     {
        //       "name": "Software Development",
        //       "order": 4,
        //       "short_code": "g24"
        //     },
        //     {
        //       "name": "Confinement",
        //       "order": 5,
        //       "short_code": "g25"
        //     },
        //     {
        //       "name": "Medical",
        //       "order": 6,
        //       "short_code": "g26"
        //     },
        //     {
        //       "name": "Business",
        //       "order": 7,
        //       "short_code": "g27"
        //     },
        //     {
        //       "name": "Marketing",
        //       "order": 8,
        //       "short_code": "g28"
        //     },
        //     {
        //       "name": "Hourly Service",
        //       "order": 9,
        //       "short_code": "g29"
        //     },
        //     {
        //       "name": "Other",
        //       "order": 10,
        //       "short_code": "g2or"
        //     }
        //   ]
        // },
        {
          "name": "Information",
          "order": 3,
          "short_code": "g3",
          "sub_category": [
            {
              "name": "Other",
              "order": 1,
              "short_code": "g3or"
            }
          ]
        },
        {
          "name": "Other",
          "order": 4,
          "short_code": "g4",
          "sub_category": [
            {
              "name": "Pet",
              "order": 1,
              "short_code": "g41"
            },
            {
              "name": "Aquarium Products",
              "order": 2,
              "short_code": "g42"
            },
            {
              "name": "Charity",
              "order": 3,
              "short_code": "g43"
            },
            {
              "name": "Other",
              "order": 4,
              "short_code": "g4or"
            }
          ]
        }
      ]
    }
  ],
  "zh_HK": [
    {
      "name": "食玩遊",
      "order": 1,
      "short_code": "a",
      "sub_category": [
        {
          "name": "飲食",
          "order": 1,
          "short_code": "a1",
          "sub_category": [
            {
              "name": "自助餐",
              "order": 1,
              "short_code": "a11"
            },
            {
              "name": "中式食品",
              "order": 2,
              "short_code": "a12"
            },
            {
              "name": "日韓食品",
              "order": 3,
              "short_code": "a13"
            },
            {
              "name": "火鍋",
              "order": 4,
              "short_code": "a14"
            },
            {
              "name": "甜品/糖水",
              "order": 5,
              "short_code": "a15"
            },
            {
              "name": "酒店餐廳",
              "order": 6,
              "short_code": "a16"
            },
            {
              "name": "扒房",
              "order": 7,
              "short_code": "a17"
            },
            {
              "name": "社企餐廳",
              "order": 8,
              "short_code": "a18"
            },
            {
              "name": "港式",
              "order": 9,
              "short_code": "a19"
            },
            {
              "name": "麵食",
              "order": 10,
              "short_code": "a110"
            },
            {
              "name": "燒烤",
              "order": 11,
              "short_code": "a111"
            },
            {
              "name": "海鮮",
              "order": 12,
              "short_code": "a112"
            },
            {
              "name": "咖啡/飲品",
              "order": 13,
              "short_code": "a113"
            },
            {
              "name": "麵包",
              "order": 14,
              "short_code": "a114"
            },
            {
              "name": "薄餅",
              "order": 15,
              "short_code": "a115"
            },
            {
              "name": "私房菜",
              "order": 16,
              "short_code": "a116"
            },
            {
              "name": "咖喱",
              "order": 17,
              "short_code": "a117"
            },
            {
              "name": "快餐",
              "order": 18,
              "short_code": "a118"
            },
            {
              "name": "健康食品/素食",
              "order": 19,
              "short_code": "a119"
            },
            {
              "name": "季節/傳統食品",
              "order": 20,
              "short_code": "a120"
            },
            {
              "name": "美食車",
              "order": 21,
              "short_code": "a121"
            },
            {
              "name": "親子餐廳",
              "order": 22,
              "short_code": "a122"
            },
            {
              "name": "主題餐廳",
              "order": 23,
              "short_code": "a123"
            },
            {
              "name": "動物友善餐廳",
              "order": 24,
              "short_code": "a124"
            },
            {
              "name": "懷舊老店",
              "order": 25,
              "short_code": "a125"
            },
            {
              "name": "無翅餐廳",
              "order": 26,
              "short_code": "a126"
            },
            {
              "name": "其他",
              "order": 27,
              "short_code": "a1or"
            }
          ]
        },
        {
          "name": "娛樂",
          "order": 2,
          "short_code": "a2",
          "sub_category": [
            {
              "name": "玩具",
              "order": 1,
              "short_code": "a21"
            },
            {
              "name": "遊戲",
              "order": 2,
              "short_code": "a22"
            },
            {
              "name": "電影",
              "order": 3,
              "short_code": "a23"
            },
            {
              "name": "包場派對",
              "order": 4,
              "short_code": "a24"
            },
            {
              "name": "遊樂場",
              "order": 5,
              "short_code": "a25"
            },
            {
              "name": "主題公園",
              "order": 6,
              "short_code": "a26"
            },
            {
              "name": "虛擬現實",
              "order": 7,
              "short_code": "a27"
            },
            {
              "name": "其他",
              "order": 8,
              "short_code": "a2or"
            }
          ]
        },
        {
          "name": "旅遊",
          "order": 3,
          "short_code": "a3",
          "sub_category": [
            {
              "name": "機票",
              "order": 1,
              "short_code": "a31"
            },
            {
              "name": "酒店",
              "order": 2,
              "short_code": "a32"
            },
            {
              "name": "旅遊熱點",
              "order": 3,
              "short_code": "a33"
            },
            {
              "name": "旅行團",
              "order": 4,
              "short_code": "a34"
            },
            {
              "name": "導賞團",
              "order": 5,
              "short_code": "a35"
            },
            {
              "name": "其他",
              "order": 6,
              "short_code": "a3or"
            }
          ]
        }
      ]
    },
    {
      "name": "生活",
      "order": 2,
      "short_code": "b",
      "sub_category": [
        {
          "name": "生活百貨",
          "order": 1,
          "short_code": "b1",
          "sub_category": [
            {
              "name": "百貨公司",
              "order": 1,
              "short_code": "b11"
            },
            {
              "name": "超市",
              "order": 2,
              "short_code": "b12"
            },
            {
              "name": "便利店",
              "order": 3,
              "short_code": "b13"
            },
            {
              "name": "雜貨店",
              "order": 4,
              "short_code": "b14"
            },
            {
              "name": "市集",
              "order": 5,
              "short_code": "b15"
            },
            {
              "name": "商場",
              "order": 6,
              "short_code": "b16"
            },
            {
              "name": "其他",
              "order": 7,
              "short_code": "b1or"
            }
          ]
        },
        {
          "name": "食品",
          "order": 2,
          "short_code": "b2",
          "sub_category": [
            {
              "name": "健康",
              "order": 1,
              "short_code": "b21"
            },
            {
              "name": "美酒",
              "order": 2,
              "short_code": "b22"
            },
            {
              "name": "咖啡",
              "order": 3,
              "short_code": "b23"
            },
            {
              "name": "肉類",
              "order": 4,
              "short_code": "b24"
            },
            {
              "name": "水果",
              "order": 5,
              "short_code": "b25"
            },
            {
              "name": "零食",
              "order": 6,
              "short_code": "b26"
            },
            {
              "name": "藥品",
              "order": 7,
              "short_code": "b27"
            },
            {
              "name": "蛋糕",
              "order": 8,
              "short_code": "b28"
            },
            {
              "name": "茶",
              "order": 9,
              "short_code": "b29"
            },
            {
              "name": "魚類",
              "order": 10,
              "short_code": "b210"
            },
            {
              "name": "其他",
              "order": 11,
              "short_code": "b2or"
            }
          ]
        },
        {
          "name": "服裝",
          "order": 3,
          "short_code": "b3",
          "sub_category": [
            {
              "name": "外套",
              "order": 1,
              "short_code": "b31"
            },
            {
              "name": "上身類",
              "order": 2,
              "short_code": "b32"
            },
            {
              "name": "下身類",
              "order": 3,
              "short_code": "b33"
            },
            {
              "name": "鞋",
              "order": 4,
              "short_code": "b34"
            },
            {
              "name": "袋",
              "order": 5,
              "short_code": "b35"
            },
            {
              "name": "連身裙 / 連身褲",
              "order": 6,
              "short_code": "b36"
            },
            {
              "name": "飾物",
              "order": 7,
              "short_code": "b37"
            },
            {
              "name": "精品",
              "order": 8,
              "short_code": "b38"
            },
            {
              "name": "內衣",
              "order": 9,
              "short_code": "b39"
            },
            {
              "name": "手錶",
              "order": 10,
              "short_code": "b310"
            },
            {
              "name": "眼鏡",
              "order": 11,
              "short_code": "b311"
            },
            {
              "name": "其他",
              "order": 12,
              "short_code": "b3or"
            }
          ]
        },
        {
          "name": "數碼產品",
          "order": 4,
          "short_code": "b4",
          "sub_category": [
            {
              "name": "行動通訊",
              "order": 1,
              "short_code": "b41"
            },
            {
              "name": "行動通訊配件",
              "order": 2,
              "short_code": "b42"
            },
            {
              "name": "攝影器材",
              "order": 3,
              "short_code": "b43"
            },
            {
              "name": "電腦",
              "order": 4,
              "short_code": "b44"
            },
            {
              "name": "電腦配件",
              "order": 5,
              "short_code": "b45"
            },
            {
              "name": "音響",
              "order": 6,
              "short_code": "b46"
            },
            {
              "name": "其他",
              "order": 7,
              "short_code": "b4or"
            }
          ]
        },
        {
          "name": "電器",
          "order": 5,
          "short_code": "b5",
          "sub_category": [
            {
              "name": "大型家電",
              "order": 1,
              "short_code": "b51"
            },
            {
              "name": "小型家電",
              "order": 2,
              "short_code": "b52"
            },
            {
              "name": "廚房電器",
              "order": 3,
              "short_code": "b53"
            },
            {
              "name": "影音視廳",
              "order": 4,
              "short_code": "b54"
            },
            {
              "name": "健康美容",
              "order": 5,
              "short_code": "b55"
            },
            {
              "name": "其他",
              "order": 6,
              "short_code": "b5or"
            }
          ]
        },
        {
          "name": "美容",
          "order": 6,
          "short_code": "b6",
          "sub_category": [
            {
              "name": "產品",
              "order": 1,
              "short_code": "b61"
            },
            {
              "name": "按摩",
              "order": 2,
              "short_code": "b62"
            },
            {
              "name": "療程",
              "order": 3,
              "short_code": "b63"
            },
            {
              "name": "美甲",
              "order": 4,
              "short_code": "b64"
            },
            {
              "name": "其他",
              "order": 5,
              "short_code": "b6or"
            }
          ]
        },
        {
          "name": "其他",
          "order": 7,
          "short_code": "b7",
          "sub_category": [
            {
              "name": "母嬰用品",
              "order": 1,
              "short_code": "b71"
            },
            {
              "name": "床上用品",
              "order": 2,
              "short_code": "b72"
            },
            {
              "name": "其他",
              "order": 3,
              "short_code": "b7or"
            }
          ]
        }
      ]
    },
    {
      "name": "教育",
      "order": 3,
      "short_code": "c",
      "sub_category": [
        {
          "name": "教育",
          "order": 1,
          "short_code": "c1",
          "sub_category": [
            {
              "name": "語文",
              "order": 1,
              "short_code": "c11"
            },
            {
              "name": "數學",
              "order": 2,
              "short_code": "c12"
            },
            {
              "name": "科學",
              "order": 3,
              "short_code": "c13"
            },
            {
              "name": "幼兒教育",
              "order": 4,
              "short_code": "c14"
            },
            {
              "name": "學前教育",
              "order": 5,
              "short_code": "c15"
            },
            {
              "name": "藝術",
              "order": 6,
              "short_code": "c16"
            },
            {
              "name": "音樂",
              "order": 7,
              "short_code": "c17"
            },
            {
              "name": "運動",
              "order": 8,
              "short_code": "c18"
            },
            {
              "name": "STEM",
              "order": 9,
              "short_code": "c19"
            },
            {
              "name": "舞蹈",
              "order": 10,
              "short_code": "c110"
            },
            {
              "name": "戲劇",
              "order": 11,
              "short_code": "c111"
            },
            {
              "name": "烹飪",
              "order": 12,
              "short_code": "c112"
            },
            {
              "name": "體驗班",
              "order": 13,
              "short_code": "c113"
            },
            {
              "name": "醫療",
              "order": 14,
              "short_code": "c114"
            },
            {
              "name": "特殊教育",
              "order": 15,
              "short_code": "c115"
            },
            {
              "name": "木工",
              "order": 16,
              "short_code": "c116"
            },
            {
              "name": "其他",
              "order": 17,
              "short_code": "c1or"
            }
          ]
        }
      ]
    },
    {
      "name": "盛事",
      "order": 4,
      "short_code": "d",
      "sub_category": [
        {
          "name": "活動",
          "order": 1,
          "short_code": "d1",
          "sub_category": [
            {
              "name": "節日活動",
              "order": 1,
              "short_code": "d11"
            },
            {
              "name": "戲劇",
              "order": 2,
              "short_code": "d12"
            },
            {
              "name": "音樂會",
              "order": 3,
              "short_code": "d13"
            },
            {
              "name": "講座",
              "order": 4,
              "short_code": "d14"
            },
            {
              "name": "街頭表演",
              "order": 5,
              "short_code": "d15"
            },
            {
              "name": "展覽",
              "order": 6,
              "short_code": "d16"
            },
            {
              "name": "比賽",
              "order": 7,
              "short_code": "d17"
            },
            {
              "name": "其他",
              "order": 8,
              "short_code": "d1or"
            }
          ]
        },
        // {
        //   "name": "婚禮",
        //   "order": 2,
        //   "short_code": "d2",
        //   "sub_category": [
        //     {
        //       "name": "珠寶",
        //       "order": 1,
        //       "short_code": "d21"
        //     },
        //     {
        //       "name": "場地",
        //       "order": 2,
        //       "short_code": "d22"
        //     },
        //     {
        //       "name": "攝影",
        //       "order": 3,
        //       "short_code": "d23"
        //     },
        //     {
        //       "name": "化妝",
        //       "order": 4,
        //       "short_code": "d24"
        //     },
        //     {
        //       "name": "服裝",
        //       "order": 5,
        //       "short_code": "d25"
        //     },
        //     {
        //       "name": "表演",
        //       "order": 6,
        //       "short_code": "d26"
        //     },
        //     {
        //       "name": "佈置",
        //       "order": 7,
        //       "short_code": "d27"
        //     },
        //     {
        //       "name": "花車",
        //       "order": 8,
        //       "short_code": "d28"
        //     },
        //     {
        //       "name": "其他",
        //       "order": 9,
        //       "short_code": "d2or"
        //     }
        //   ]
        // }
      ]
    },
    // {
    //   "name": "金融",
    //   "order": 5,
    //   "short_code": "e",
    //   "sub_category": [
    //     {
    //       "name": "金融",
    //       "order": 1,
    //       "short_code": "e1",
    //       "sub_category": [
    //         {
    //           "name": "理財",
    //           "order": 1,
    //           "short_code": "e11"
    //         },
    //         {
    //           "name": "保險",
    //           "order": 2,
    //           "short_code": "e12"
    //         },
    //         {
    //           "name": "其他",
    //           "order": 3,
    //           "short_code": "e1or"
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
      "name": "物業",
      "order": 6,
      "short_code": "f",
      "sub_category": [
        {
          "name": "裝修",
          "order": 1,
          "short_code": "f1",
          "sub_category": [
            {
              "name": "傢俬",
              "order": 1,
              "short_code": "f11"
            },
            {
              "name": "裝修工程",
              "order": 2,
              "short_code": "f12"
            },
            {
              "name": "維修",
              "order": 3,
              "short_code": "f13"
            },
            {
              "name": "燈飾",
              "order": 5,
              "short_code": "f15"
            },
            {
              "name": "五金",
              "order": 6,
              "short_code": "f16"
            },
            {
              "name": "其他",
              "order": 7,
              "short_code": "f1or"
            }
          ]
        },
        {
          "name": "專業服務",
          "order": 2,
          "short_code": "f3",
          "sub_category": [
            {
              "name": "室內設計",
              "order": 1,
              "short_code": "f31"
            },
            {
              "name": "驗樓",
              "order": 2,
              "short_code": "f32"
            },
            {
              "name": "驗窗",
              "order": 3,
              "short_code": "f33"
            },
            {
              "name": "滅蟲",
              "order": 4,
              "short_code": "f34"
            },
            {
              "name": "清潔",
              "order": 5,
              "short_code": "f35"
            },
            {
              "name": "其他",
              "order": 6,
              "short_code": "f3or"
            }
          ]
        },
        // {
        //   "name": "地產",
        //   "order": 3,
        //   "short_code": "f2",
        //   "sub_category": [
        //     {
        //       "name": "樓盤出租",
        //       "order": 1,
        //       "short_code": "f21"
        //     },
        //     {
        //       "name": "樓盤出售",
        //       "order": 2,
        //       "short_code": "f22"
        //     },
        //     {
        //       "name": "其他",
        //       "order": 3,
        //       "short_code": "f2or"
        //     }
        //   ]
        // }
      ]
    },
    {
      "name": "其他",
      "order": 7,
      "short_code": "g",
      "sub_category": [
        {
          "name": "運動及戶外",
          "order": 1,
          "short_code": "g1",
          "sub_category": [
            {
              "name": "運動產品",
              "order": 1,
              "short_code": "g11"
            },
            {
              "name": "健身",
              "order": 2,
              "short_code": "g12"
            },
            {
              "name": "戶外野營",
              "order": 3,
              "short_code": "g13"
            },
            {
              "name": "單車",
              "order": 4,
              "short_code": "g14"
            },
            {
              "name": "足球",
              "order": 5,
              "short_code": "g15"
            },
            {
              "name": "劍擊",
              "order": 6,
              "short_code": "g16"
            },
            {
              "name": "水上運動",
              "order": 7,
              "short_code": "g17"
            },
            {
              "name": "籃球",
              "order": 8,
              "short_code": "g18"
            },
            {
              "name": "羽毛球",
              "order": 9,
              "short_code": "g19"
            },
            {
              "name": "排球",
              "order": 10,
              "short_code": "g110"
            },
            {
              "name": "乒乓球",
              "order": 11,
              "short_code": "g111"
            },
            {
              "name": "射箭",
              "order": 12,
              "short_code": "g112"
            },
            {
              "name": "其他",
              "order": 13,
              "short_code": "g1or"
            }
          ]
        },
        // {
        //   "name": "專業服務",
        //   "order": 2,
        //   "short_code": "g2",
        //   "sub_category": [
        //     {
        //       "name": "法律",
        //       "order": 2,
        //       "short_code": "g22"
        //     },
        //     {
        //       "name": "平面設計",
        //       "order": 3,
        //       "short_code": "g23"
        //     },
        //     {
        //       "name": "程式開發",
        //       "order": 4,
        //       "short_code": "g24"
        //     },
        //     {
        //       "name": "陪月",
        //       "order": 5,
        //       "short_code": "g25"
        //     },
        //     {
        //       "name": "醫療",
        //       "order": 6,
        //       "short_code": "g26"
        //     },
        //     {
        //       "name": "商務",
        //       "order": 7,
        //       "short_code": "g27"
        //     },
        //     {
        //       "name": "市場推廣",
        //       "order": 8,
        //       "short_code": "g28"
        //     },
        //     {
        //       "name": "鐘點服務",
        //       "order": 9,
        //       "short_code": "g29"
        //     },
        //     {
        //       "name": "其他",
        //       "order": 10,
        //       "short_code": "g2or"
        //     }
        //   ]
        // },
        {
          "name": "情報",
          "order": 3,
          "short_code": "g3",
          "sub_category": [
            {
              "name": "其他",
              "order": 1,
              "short_code": "g3or"
            }
          ]
        },
        {
          "name": "其他",
          "order": 4,
          "short_code": "g4",
          "sub_category": [
            {
              "name": "寵物",
              "order": 1,
              "short_code": "g41"
            },
            {
              "name": "水族用品",
              "order": 2,
              "short_code": "g42"
            },
            {
              "name": "慈善",
              "order": 3,
              "short_code": "g43"
            },
            {
              "name": "其他",
              "order": 4,
              "short_code": "g4or"
            }
          ]
        }
      ]
    }
  ]
}