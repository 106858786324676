import * as React from 'react'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import cn from 'classnames'
import FillButton from 'components/presenter/buttons/FillButton'
import WebpImage from 'components/presenter/image/WebpImage'
import * as styles from './styles.module.css'


const BannerSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.bannerSection}>
        <div className={styles.overlay} />
        <WebpImage className={styles.banner} src='/images/share.jpg' alt={t('landing.bannerAlt')} />
        <div className={styles.bannerContent}>
          <h1 className={styles.bannerTitle}>
            {t('landing.bannderTitle')}
          </h1>
          <h2 className={styles.bannerSubTitle}>
            {t('landing.bannerSubTitle')}
          </h2>
          <FillButton
            className={cn(styles.btnPromote, 'mui-btn-link')}>
            <Link
              to='/signup/'>
              {t('landing.join')}
            </Link>
          </FillButton>
        </div>
      </div>
    </>
  )
}

export default BannerSection