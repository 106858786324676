import create from 'zustand'
import { createAuthSlice } from './auth'
import { createUserSlice } from './user'

export const useStore = create((...a) => ({
  ...createAuthSlice(...a),
  ...createUserSlice(...a),
}))


export const useAuthStore = () => useStore(state => state.auth)
export const useUserStore = () => useStore(state => state.user)