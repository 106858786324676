import * as React from 'react'
import FillButton from 'components/presenter/buttons/FillButton'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { EmptyValidator, PasswordValidator, EqualFieldsValidator } from 'libs/form/validator'
import { NotMatchConfirmPassword } from 'libs/error/errorCodes'
import { useSnackbar } from 'libs/ui/snackbar'
import FormTextField from 'components/presenter/form/FormTextField'
import Form from 'components/presenter/form/Form'
import cn from 'classnames'
import { useUserStore } from 'stores'
import { useForm } from 'libs/form/formHook'
import * as styles from './editPassword.module.css'

const oldPassword = 'old'
const newPassword = 'new'
const confirmPassword = 'confirm'

const EditPasswordSection = () => {
  const { t } = useTranslation()
  const userStore = useUserStore()
  const { showSuccess } = useSnackbar()

  const { onChangeValidation, getFormFieldState, requestAsyc, isFormReady, isLoading, formErrorCode } = useForm({
    [oldPassword]: {
      validators: [EmptyValidator, PasswordValidator],
    },
    [newPassword]: {
      validators: [EmptyValidator, PasswordValidator],
    },
    [confirmPassword]: {
      validators: [EmptyValidator, PasswordValidator, EqualFieldsValidator([newPassword], NotMatchConfirmPassword)],
    },
  })
  

  async function updatePassword() {
    const response = await requestAsyc(userStore.updateUserPassword({
      oldPassword: getFormFieldState(oldPassword).value,
      newPassword: getFormFieldState(confirmPassword).value
    }))
    if (!response.error) {
      showSuccess(t('common.success'))
    }
  }
 
  return (
    <Form className={styles.form} isLoading={isLoading}>
      <h1 className={styles.passwordTitle}>{t('profile.changeYourPassword')}</h1>
      <FormTextField
        id={oldPassword}
        fieldData={getFormFieldState(oldPassword)}
        className={cn(styles.input)}
        label={t('editPassword.oldPassword')}
        type="password"
        onChange={onChangeValidation}
      />
      <FormTextField
        id={newPassword}
        fieldData={getFormFieldState(newPassword)}
        className={cn(styles.input)}
        label={t('editPassword.newPassword')}
        type="password"
        onChange={onChangeValidation}
      />
      <FormTextField
        id={confirmPassword}
        fieldData={getFormFieldState(confirmPassword)}
        className={cn(styles.input)}
        label={t('editPassword.confirmNewPassword')}
        type="password"
        onChange={onChangeValidation}
      />
      <p className={cn(styles.errorMsg)}>{formErrorCode ? `*${t(`errorCodes.${formErrorCode}`)}` : ''}</p>
      <FillButton 
        className={cn(styles.btnUpdate)}
        disabled={!isFormReady()}
        onClick={updatePassword}
      >
        {t('common.change')}
      </FillButton>
    </Form>
  )
}

export default EditPasswordSection