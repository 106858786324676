/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { useTranslation, useI18next, Link } from 'gatsby-plugin-react-i18next'
import BannerForm from 'components/presenter/form/banner/BannerForm'
import BannerFormContent from 'components/presenter/form/banner/BannerFormContent'
import FormTextField from 'components/presenter/form/FormTextField'
import { useForm } from 'libs/form/formHook'
import { EmptyValidator, EmailValidator } from 'libs/form/validator'
import { AUTH_FORGET_PASSOWRD } from 'api/coyns/graphql/mutations'
import FillButton from 'components/presenter/buttons/FillButton'
import cn from 'classnames'
import Page from 'components/presenter/page/Page'
import * as styles from './forget-password.module.css'

const emailId = 'email'


const ForgetPasswordPage = () => {
  const { t } = useTranslation()
  const { navigate, language } = useI18next()

  const { onChangeValidation, getFormFieldState, requestGraphQL, isFormReady, isLoading, formErrorCode } = useForm({
    [emailId]: {
      validators: [EmptyValidator, EmailValidator],
    },
  })

  /**
   *
   */
  const sendForgetPassword = async () => {
    const email = getFormFieldState(emailId).value
    const response = await requestGraphQL(AUTH_FORGET_PASSOWRD, {
      userName: getFormFieldState(emailId).value
    }, {
      lang: language
    })
    if (!response.error) {
      const urlParams = new URLSearchParams({
        email,
      })
      navigate(`/forget-password/success/?${urlParams.toString()}`)
    }
  }

  const handleKeyDown = React.useCallback(async (e) => {
    if (e.key === 'Enter' && isFormReady) {
      e.preventDefault()
      await sendForgetPassword()
    }
  }, [language])
  
  return (
    <div role="main" onKeyDown={handleKeyDown}>
      <Page seoMeta={{
        title: t('forgetPassword.title')
      }}>
        <BannerForm isLoading={isLoading}>
          <BannerFormContent
            title={t('forgetPassword.title')}
            subTitle={t('forgetPassword.description')}
          >
            <FormTextField
              id={emailId}
              className={cn(styles.inputEmail)}
              fieldData={getFormFieldState(emailId)}
              label={t('common.email')}
              type="email"
              onChange={onChangeValidation}
            />
            <p className={cn(styles.errorMsg)}>{formErrorCode ? `*${t(`errorCodes.${formErrorCode}`)}` : ''}</p>
            <FillButton 
              className={cn(styles.btn)}
              disabled={!isFormReady()}
              onClick={sendForgetPassword}
            >
              {t('forgetPassword.requestReset')}
            </FillButton>
            <Link className={`${styles.txtBack}`} to='/login/'>{t('forgetPassword.backToSignIn')}</Link>
          </BannerFormContent>
        </BannerForm>
      </Page>
    </div>
  )
}

export default ForgetPasswordPage