import { 
  InvalidEmail,
  NonEmptyField,
  MinPasswordLength,
  ExceedMaxLength,
  InvalidUrl,
  NotMatchPassword,
} from 'libs/error/errorCodes'

const isValidURL = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i') // fragment locator
  return !!pattern.test(str)
}

export const EmptyValidator = (value) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (!value || (Array.isArray(value) && value.length === 0)) {
    return NonEmptyField
  }
  return null
}

export const EmailValidator = (value) => {
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    return InvalidEmail
  }
  return null
}

export const PasswordValidator = (value = '') => {
  if (value.length < 6) {
    return MinPasswordLength
  }
  return null
}

export const UrlValidator = (value = '') => {
  if (value.length > 2048) {
    return ExceedMaxLength
  }

  if (!isValidURL(value)) {
    return InvalidUrl
  }

  return null
}

export const MaxLengthValidator = (maxLength) => (value) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (maxLength < value.length) {
    return ExceedMaxLength
  }
  return null
}

export const EqualFieldsValidator = (fieldIds, erorCode) => (newValue, oldValue, fields) => {
  const isValid = fieldIds.every((id) => fields[id].value === newValue)

  if (!isValid) {
    return erorCode ?? NotMatchPassword
  }
  
  return null
}