// extracted by mini-css-extract-plugin
export const form = "styles-module--form--rudUE";
export const input = "styles-module--input--A8TzW";
export const countryInput = "styles-module--countryInput--y5ax-";
export const dateTimeInput = "styles-module--dateTimeInput--XmCCO";
export const bizTxt = "styles-module--bizTxt--KVJEm";
export const errorMsg = "styles-module--errorMsg--KbD4d";
export const btnUpdate = "styles-module--btnUpdate--elZzi";
export const title = "styles-module--title--JIJkf";
export const profileSection = "styles-module--profileSection--tTMm-";
export const passwordTitle = "styles-module--passwordTitle--+4h3i";
export const bankSection = "styles-module--bankSection--C8wK8";
export const infoIcon = "styles-module--infoIcon--CcpLI";
export const popper = "styles-module--popper--vwi4l";
export const accountSection = "styles-module--accountSection--ci86T";
export const bankInput = "styles-module--bankInput--WVl6j";