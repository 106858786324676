import React from 'react'
import cn from 'classnames'
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Collapse, IconButton } from '@material-ui/core'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { useAuthStore, useUserStore } from 'stores'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as styles from './styles.module.css'

const useStyles = makeStyles((theme) => ({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }));

const RenderCategory = (affiliateData) => {
    const domain = affiliateData?.company.domain
    const rewards = affiliateData?.rewards || []
    rewards.sort((a, b) => {
        const cashbackA = a.cashback_value_changed || a.cashback_value;
        const cashbackB = b.cashback_value_changed || b.cashback_value;
        return cashbackB - cashbackA;
    });
    const [changedStr, setChangedStr] = React.useState([])
    const { t } = useTranslation()
    const authStore = useAuthStore()
    const userStore = useUserStore()

    React.useEffect(() => {
        if (!authStore.isAnonymous()) {
            const userProfile = userStore.getUserProfile()
            if (userProfile) {
                const saleMode = userProfile.sale_mode
                const tempChangedStr = []
            
                if (saleMode === 2) {
                    tempChangedStr.push(t(`profile.saleMode2`))
                }
        
                const campaignPasses = userStore.getCampaignPass()
                const activeCampaignPasses = campaignPasses.filter(pass => pass.campaign.type === 1 && new Date(pass.expired_at) > new Date())
        
                if (activeCampaignPasses.length > 0) {
                    tempChangedStr.push(t(`common.campaignPass`))
                }
                setChangedStr(tempChangedStr)
            }
        }
    }, [authStore.isAnonymous(), userStore.getUserProfile()])

    const getCashbackText = (reward) => {
        const cashbackValue = reward.cashback_value_changed || reward.cashback_value
        if (reward.cashback_type === 1) {
            return `${cashbackValue}%`
        }
        return t(`affiliate.fixed_rate_per_ticket`, {
            cashback: cashbackValue,
            currency_unit: reward.currency_unit
        })
    }
    return (
        <div className={styles.categoryContainer}>
            {
                rewards.map((reward) => {
                    return (
                        <div className={styles.divCategory}>
                            <span className={styles.txtCategory}>{t(`product_category.${domain}.${reward.product_type}`)}</span>
                            <span className={styles.txtReward}>
                                {getCashbackText(reward)}
                            </span>
                        </div>
                        
                    )
                })
            }
            {changedStr.length > 0 && (
                <div className={styles.txtChanged}>{t(`affiliate.cashbackChangedHint`, {change: changedStr.join(', ')})}</div>
            )}
        </div>
    )
}

const RenderCategorySection = (expanded, affiliateData) => {
    return (
        <>
            <Collapse className="mobileOnly" in={expanded} timeout="auto" unmountOnExit>
                {RenderCategory(affiliateData)}
            </Collapse>
            <div className="deskTopOnly">
                {RenderCategory(affiliateData)}
            </div>
        </>
    )
}

const CashbackCategoryCard = ({className, affiliateData}) => {
    const { t } = useTranslation()
    const [expanded, setExpanded] = React.useState(false);
    const classes = useStyles()
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <Card className={cn(className, styles.card)}>
            <div className={
                cn(styles.titleSection)
            }>
                <span className={styles.txtTitle}>{t('affiliate.cashbackCategory')}</span>
                <IconButton
                    className={clsx(classes.expand, 'mobileOnly', {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </div>
            {RenderCategorySection(expanded, affiliateData)}
        </Card>
    )
}

export default React.memo(CashbackCategoryCard)