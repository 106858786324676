import React from 'react'
import {Helmet as ReactHelmet} from 'react-helmet'
import {useI18next} from 'gatsby-plugin-react-i18next'

// Workaround: SEO handling

export const Helmet = ({children, location, ...props}) => {
  const {languages, language, originalPath, defaultLanguage, siteUrl = ''} = useI18next()
  const createUrlWithLang = (lng) => {
    const url = `${siteUrl}${lng === defaultLanguage ? '' : `/${lng}`}${originalPath}`
    return `${url.endsWith('/') ? url : `${url}/`}${location ? location.search : ''}`
  }
  return (
    <ReactHelmet {...props}>
      <html lang={language} />
      <link rel="canonical" href={createUrlWithLang(language)} />
      {languages.map((lng) => (
        <link rel="alternate" key={lng} href={createUrlWithLang(lng)} hrefLang={lng} />
      ))}
      {/* adding a fallback page for unmatched languages */}
      <link rel="alternate" href={createUrlWithLang(defaultLanguage)} hrefLang="x-default" />
      {children}
    </ReactHelmet>
  )
}