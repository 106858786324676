import * as React from 'react'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import AppIcon from 'assets/svg/app_icon_vertical.inline.svg'
import cn from 'classnames'
import * as styles from './bannerFormContent.module.css'

const BannerFormContent = ({children, title, subTitle, showAppIcon = false}) => {
  const { t } = useTranslation()
  const { navigate } = useI18next()
  return (
    <>
      <span className={cn(styles.txtTitle)}>{title}</span>
      { showAppIcon ? <AppIcon className={cn(styles.loginAppIcon)} alt={t('login.welcomeAlt')} onClick={() => navigate('/')} /> : ''}
      { subTitle ? <p className={cn(styles.txtSubTitle)}>{subTitle}</p> : ''}
      {children}
    </>
  )
}

export default BannerFormContent