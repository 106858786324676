// Required for gatsby-plugin-react-i18next
import { graphql } from 'gatsby'
import ResetPasswordSuccessPage from 'components/container/page/reset-password/reset-password-success'

export default ResetPasswordSuccessPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`