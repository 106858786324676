const localStorage = typeof window !== `undefined` && typeof window.localStorage !== `undefined`
    ? window.localStorage 
    : null

export const themeLocalKey = '__THEME_MODE__'
export const accessTokenKey = '__ACCESS_TOKEN__'

export const setLocalCache = (key, value) => {
  if(!localStorage) {
    return
  }
  localStorage.setItem(key, value)
}

export const getLocalCache = (key) => {
  if(!localStorage) {
    return null
  }
  return localStorage.getItem(key)
}
