import React from 'react'
import cn from 'classnames'
import Card from '@material-ui/core/Card'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { useI18next, useTranslation, Link } from 'gatsby-plugin-react-i18next'
import InfiniteScroll from 'components/presenter/list/InfiniteScroll'
import { displayDate } from 'libs/date/dateUtil'
import { LoginDialog, useLoginDialog } from '../LoginDialog'
import { GET_AFFILIATE_PROMO_CODE, GET_AFFILIATE_DEALS } from 'api/coyns/graphql/queries'
import { useApiCall } from 'api'
import { useAuthStore } from 'stores'
import * as styles from './styles.module.css'
import { useStoreLink } from 'libs/affiliate'
import {navigate as gatsbyNavigate} from 'gatsby';

const TabPanel = (props) => {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
        >
            {value === index && (
                <div className={props.className}>{children}</div>
            )}
        </div>
    );
}

const DealPanel = ({affiliateData, dealData, loadMore, onDialogOpen}) => {
    const { t } = useTranslation()
    const authStore = useAuthStore()
    const records = dealData?.records || []
    const companyName = affiliateData?.company?.name
    const logoUrl = affiliateData?.company?.company_logo?.url
    const { generateStoreLink } = useStoreLink()

    if (records.length === 0) {
        return (
            <Card className={styles.emptyCard}>
                <span>{t('affiliate.noDeals')}</span>
            </Card>
        )
    }

    return (
        <>
            { 
                records.map((record) => {
                    const handleClick = (event) => {
                        event.preventDefault()
                        if (authStore.isLoggedIn()) {
                            window.open(storeLink, '_blank')
                        } else {
                            onDialogOpen()
                        }
                    }

                    const storeLink = React.useMemo(() => {
                        return generateStoreLink({
                            storeId: affiliateData._id,
                            companyName,
                            logoUrl,
                            redirect_url: record.affiliate_url
                        })
                    })

                    return (
                        <Card key={record._id} className={cn(styles.dealCard)}>
                            <Link
                                className={styles.link}
                                alt={record.title}
                                to={storeLink}
                                onClick={handleClick}>
                                <img src={record.image_url} alt={record.title} />
                                <div className={styles.dealCardContent}>
                                    <span className={styles.dealCardTitle}>{record.title}</span>
                                    <span className={styles.dealCardExpired}>{t('affiliate.validDateFormat', {
                                        endAt: displayDate(record.end_at)
                                    })}</span>
                                    <span className={styles.dealCardBody}>{record.sub_title}</span>
                                </div>
                            </Link>
                        </Card>
                    )
                })
            }
            <InfiniteScroll loadMore={loadMore} />
        </>
    )
}

const PromoCodeButton = ({affiliateData, record, onDialogOpen}) => {
    const { t } = useTranslation()
    const [isCopied, setIsCopied] = React.useState(false);
    const authStore = useAuthStore()
    const isLoggedIn = authStore.isLoggedIn()
    const { generateStoreLink } = useStoreLink()

    const copyToClipboard = (text) => {
        if (!isLoggedIn) {
            onDialogOpen()
            return
        }

        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('promoId', record._id);

        
        gatsbyNavigate(generateStoreLink({
            storeId: affiliateData._id,
            companyName: affiliateData.company.name,
            logoUrl: affiliateData.company.company_logo.url,
            skipLink: currentUrl.toString()
        }))
    

        // if (window.clipboardData && window.clipboardData.setData) {
        //     setIsCopied(true)
        //     // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        //     return window.clipboardData.setData("Text", text)
        // }
        // if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        //     const textarea = document.createElement("textarea")
        //     textarea.textContent = text
        //     textarea.style.position = "fixed"  // Prevent scrolling to bottom of page in Microsoft Edge.
        //     document.body.appendChild(textarea)
        //     textarea.select()
        //     try {
        //         const isSuccess = document.execCommand("copy") 
        //         setIsCopied(true)
        //          return isSuccess
        //     }
        //     finally {
        //         document.body.removeChild(textarea)
        //     }
        // }
        return false
      }

    return (
        <Button
            variant='outlined'
            className={styles.promoCodeButton}
            onClick={() => copyToClipboard(record.code)}
        >
            {isCopied ? t('common.copied') : t('affiliate.getCode')}
        </Button>
    )
}

const TermButton = ({record}) => {
    const { t } = useTranslation()
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    }

    const handleTooltipOpen = () => {
        setOpen(true);
    }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
            PopperProps={{
                disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableHoverListener
            disableTouchListener
            title={record.terms}
        >
            <Button className={cn(styles.promoCodeTerms)} onClick={handleTooltipOpen}>{t('common.terms')}</Button>
        </Tooltip>
    </ClickAwayListener>
  )
}

const PromoCodesPanel = ({affiliateData, promoCodesData, loadMore, onDialogOpen}) => {
    const { t } = useTranslation()
    const records = promoCodesData?.records || []

    if (records.length === 0) {
        return (
            <Card className={styles.emptyCard}>
                <span>{t('affiliate.noPromoCodes')}</span>
            </Card>
        )
    }

    return (
        <>
            {records.map((record) => {
                return (
                    <Card key={record._id} className={cn(styles.promoCodeCard)}>
                        <div className={styles.promoCodeCardContent}>
                            <span className={styles.promoCodeCardTitle}>{record.discount}</span>
                            <span className={styles.promoCodeCardExpired}>{t('affiliate.validDateFormat', {
                                endAt: displayDate(record.end_at)
                            })}</span>
                            <span className={styles.promoCodeCardBody}>{record.description}</span>
                        </div>
                        <div className={styles.promoCodeButtonSection}>
                            <PromoCodeButton affiliateData={affiliateData} record={record} onDialogOpen={onDialogOpen} />
                            <TermButton record={record} />
                        </div>
                        
                    </Card>
                )
            })}
            <InfiniteScroll loadMore={loadMore} />
        </>
    )
}

const RewardPanel = ({className, affiliateData, initAffiliateDealData, initAfiliatePromoCodesData}) => {
    const { t } = useTranslation()
    const { language } = useI18next()
    const { open, handleClickOpen, onClose, onLogin } = useLoginDialog()
    const [isLoading, setLoading] = React.useState(false)
    const [tabIndex, setTabIndex] = React.useState(initAfiliatePromoCodesData.total > 0 && initAffiliateDealData.total === 0 ? 1 : 0)
    const [affiliateDealData, setAffiliateDealData] = React.useState(initAffiliateDealData)
    const [afiliatePromoCodesData, setAfiliatePromoCodesData] = React.useState(initAfiliatePromoCodesData)
    const { requestGraphQL } = useApiCall()

    const handleChange = React.useCallback((event, newValue) => {
        setTabIndex(newValue)
    })

    const loadMoreDeal = React.useCallback(async () => {
        const records = affiliateDealData.records
        if (isLoading || affiliateDealData.total === records.length) {
            return
        }
        const lastRecord = records[records.length - 1]
        setLoading(true)
        const response = await requestGraphQL(GET_AFFILIATE_DEALS, {
            affiliateId: affiliateData._id,
            countryCode: "HK",
            limit: 4,
            lastId: lastRecord._id
          }, {
            lang: language
          })
        if (!response.error) {
            const payload = response.get_affiliate_deals

            affiliateDealData.records.push(...payload.records)

            setAffiliateDealData({
                ...affiliateDealData
            })
        }
        setLoading(false)
    })
    
    const loadMorePromoCode = React.useCallback(async () => {
        const records = afiliatePromoCodesData.records
        if (isLoading || afiliatePromoCodesData.total === records.length) {
            return
        }
        const lastRecord = records[records.length - 1]
        setLoading(true)
        const response = await requestGraphQL(GET_AFFILIATE_PROMO_CODE, {
            affiliateId: affiliateData._id,
            countryCode: "HK",
            limit: 15,
            lastId: lastRecord._id
          }, {
            lang: language
          })
        if (!response.error) {
            const payload = response.get_affiliate_promo_codes

            afiliatePromoCodesData.records.push(...payload.records)

            setAfiliatePromoCodesData({
                ...afiliatePromoCodesData
            })
        }
        setLoading(false)
    })

    return (
        <>
            <div className={className}>
                <Card className={cn(styles.tabCard)}>
                    <Tabs className={cn(styles.tab)} value={tabIndex} onChange={handleChange}>
                        <Tab label={t('affiliate.dealFormat', {
                            numOfDeal: affiliateDealData?.total
                        })} />
                        <Tab label={t('affiliate.promoCodeFormat', {
                            numOfPromoCode: afiliatePromoCodesData?.total
                        })} />
                    </Tabs>
                </Card>
                
                <TabPanel className={styles.tabPanel} value={tabIndex} index={0}>
                    <DealPanel affiliateData={affiliateData} dealData={affiliateDealData} loadMore={loadMoreDeal} onDialogOpen={handleClickOpen} />
                </TabPanel>
                <TabPanel className={styles.promoCodePanel} value={tabIndex} index={1}>
                    <PromoCodesPanel affiliateData={affiliateData} promoCodesData={afiliatePromoCodesData} loadMore={loadMorePromoCode} onDialogOpen={handleClickOpen}/>
                </TabPanel>
            </div>
            <LoginDialog 
                open={open}
                onClose={onClose}
                onLogin={onLogin}
            />
        </>
        
    )
}

export default React.memo(RewardPanel)