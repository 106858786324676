// extracted by mini-css-extract-plugin
export const root = "profile-module--root--EkOR-";
export const paper = "profile-module--paper--S++DM";
export const form = "profile-module--form--MQXK2";
export const input = "profile-module--input--Raaom";
export const inputFile = "profile-module--inputFile--VlteL";
export const errorMsg = "profile-module--errorMsg--jRyyt";
export const btnUpdate = "profile-module--btnUpdate--5g4+2";
export const profileImage = "profile-module--profileImage--VtRbZ";
export const profileSection = "profile-module--profileSection--7JYyb";
export const btnUpload = "profile-module--btnUpload--DPPsV";