// extracted by mini-css-extract-plugin
export const footer = "styles-module--footer--E+nBG";
export const separator = "styles-module--separator--A8X1Y";
export const section = "styles-module--section--wMLoZ";
export const langSection = "styles-module--langSection--z4yYz";
export const flag = "styles-module--flag--CpZ5c";
export const content = "styles-module--content--hxujS";
export const contentSectionTitle = "styles-module--contentSectionTitle--udoAr";
export const followSection = "styles-module--followSection--M3bpr";
export const menuSection = "styles-module--menuSection--Kacg7";
export const linkContainer = "styles-module--linkContainer--n9gL6";
export const link = "styles-module--link--eZdCh";