import React from 'react'
import Page from 'components/presenter/page/Page'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useAuthStore } from 'stores'
import { GET_AFFILIATE_PROGRAM_DETAIL, GET_PROMO_CODES} from 'api/coyns/graphql/queries'
// import { VIEW_NEWS } from 'api/coyns/graphql/mutations'
import { useApiCall } from 'api'
import cn from 'classnames'
import { useSnackbar } from 'libs/ui/snackbar'
import _ from 'lodash'
import { usePageLoad } from 'libs/ui/pageLoad'
import CompanyCard from './CompanyCard'
import CashbackCategoryCard from './CashbackCategoryCard'
import TermsCard from './TermsCard'
import RewardPanel from './RewardPanel'
import { CopyPromoCodeDialog } from 'components/container/page/StoreDetailPage/CopyPromoCodeDialog'

import * as styles from './styles.module.css'

const StoreDetailPage = ({location, affiliateId, pageContext}) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const { showInfo } = useSnackbar()
  const { requestGraphQL } = useApiCall()
  const authStore = useAuthStore()
  
  // Bug: For load wrong page data twice
  // Workaround: force to reload

  let affiliateSSR = pageContext.affiliate
  // if (newsSSR && affiliateId && newsSSR._id !== affiliateId) {
  //   newsSSR = null
  // }

  // Backward compatiable query
  // TODO: Remove in the future
  const searchParams = new URLSearchParams(location.search)
  const _affiliateId = affiliateId || searchParams.get('id')
  const _promoId = searchParams.get('promoId')

  // const [ affiliateData, setAffiliateData ] = React.useState(affiliateSSR || {})
  const [ affiliateProgramData, setAffiliateProgramData ] = React.useState({})
  const [ affiliateDealData, setAffiliateDealData ] = React.useState({
    records: [],
    total: 0
  })
  const [ afiliatePromoCodesData, setAffiliatePromoCodesData ] = React.useState({
    records: [],
    total: 0
  })
  const [ promoCodeData, setPromoCodeData ] = React.useState(null)


  const fetchPromoCodeDetail = async () => {
    if (!_promoId) {
      return
    }
    const response = await requestGraphQL(GET_PROMO_CODES, {
      id: _promoId
    }, {
      lang: language
    })
    if (!response.error) {
      setPromoCodeData(response.get_promo_codes)
    }
  }

  const fetchAffiliateProgramDetail = async () => {
    const response = await requestGraphQL(GET_AFFILIATE_PROGRAM_DETAIL, {
      affiliateId: _affiliateId,
      countryCode: "HK",
      limit: 15,
    }, {
      lang: language
    })
    if (!response.error) {
      setAffiliateProgramData({
        ...affiliateProgramData,
        ...response.get_affiliate_program,
      })

      setAffiliateDealData({
        ...affiliateDealData,
        ...response.get_affiliate_deals,
      })
      
      setAffiliatePromoCodesData({
        ...afiliatePromoCodesData,
        ...response.get_affiliate_promo_codes,
      })
    }
  }

  const handlePageLoad = async () => {
    await fetchAffiliateProgramDetail()
  }

  const { isPageLoaded } = usePageLoad({
    pageLoadDeps: [authStore.isInitial()],
    onPageLoad: handlePageLoad
  })

  const companyName = affiliateProgramData?.company?.name
  React.useEffect(() => {
    if (authStore.isInitial() && authStore.isLoggedIn()) {
      fetchPromoCodeDetail()
    }
  }, [authStore.isInitial(), authStore.isLoggedIn()])

  return (
    <Page 
      className={cn(styles.page, 'container')}
      location={location}
      seoMeta={{
        title: companyName,
        // author: newsData?.company?.name,
        // description: newsData?.description,
      }}
      isLoading={!isPageLoaded}>
        {
          !_.isEmpty(affiliateProgramData) ?
            <>
              <div className={styles.generalSection}>
                <CompanyCard className={styles.card} affiliateData={affiliateProgramData} />
                <CashbackCategoryCard className={styles.card} affiliateData={affiliateProgramData} />
                {/* <TermsCard className={styles.card} affiliateData={affiliateProgramData} /> */}
              </div>
              <div className={styles.panelSection}>
                <RewardPanel affiliateData={affiliateProgramData} initAffiliateDealData={affiliateDealData} initAfiliatePromoCodesData={afiliatePromoCodesData} />
              </div>
            </>
          : <></>
        }
        <CopyPromoCodeDialog
          open={!!promoCodeData}
          onClose={() => setPromoCodeData(null)}
          promoCodeRecord={promoCodeData}
        />
    </Page>
  )
}

export default StoreDetailPage