import React from 'react'
import { Card } from '@material-ui/core'
import { useI18next } from 'gatsby-plugin-react-i18next'

const TermsCard = ({className, affiliateData}) => {
    const { language } = useI18next()
    return (
        <Card className={className}>
            TermsCard
        </Card>
    )
}

export default React.memo(TermsCard)