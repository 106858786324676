/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import { Trans, useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next'
import FillButton from 'components/presenter/buttons/FillButton'
import FormTextField from 'components/presenter/form/FormTextField'
import BannerForm from 'components/presenter/form/banner/BannerForm'
import BannerFormContent from 'components/presenter/form/banner/BannerFormContent'
import Checkbox from 'components/presenter/input/Checkbox'
import { EmptyValidator, EmailValidator, PasswordValidator, EqualFieldsValidator } from 'libs/form/validator'
import { useForm } from 'libs/form/formHook'
import { AUTH_REGISTER } from 'api/coyns/graphql/mutations'
import cn from 'classnames'
import Page from 'components/presenter/page/Page'
import * as styles from './signup.module.css'

const companyNameId = 'company-name'
const emailId = 'email'
const passwordId = 'password'
const confirmPasswordId = 'confirm-password'
const tncId = 'tnc'

const SigupPage = () => {
  const { t } = useTranslation()
  const { language, navigate } = useI18next()

  const { onChangeValidation, getFormFieldState, isFormReady, requestGraphQL, isLoading, formErrorCode } = useForm({
    [companyNameId]: {
      validators: [EmptyValidator],
    },
    [emailId]: {
      validators: [EmptyValidator, EmailValidator],
    },
    [passwordId]: {
      validators: [EmptyValidator, PasswordValidator],
    },
    [confirmPasswordId]:{
      validators: [EmptyValidator, PasswordValidator, EqualFieldsValidator(['password'])],
    },
    [tncId]: {
      validators: [EmptyValidator],
    },
  })

  /**
   *
   */
  async function register() {
    const email = getFormFieldState(emailId).value
    const response = await requestGraphQL(AUTH_REGISTER, {
      input: {
        username: email,
        full_name: getFormFieldState(companyNameId).value,
        password: getFormFieldState(passwordId).value
      }
    }, {
      lang: language
    })
    if (!response.error) {
      const urlParams = new URLSearchParams({
        email,
      })
      navigate(`/signup/success/?${urlParams.toString()}`)
    }
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && isFormReady) {
      e.preventDefault()
      await register()
    }
  }

  return (
    <div role="main" onKeyDown={handleKeyDown}>
      <Page seoMeta={{
        title: t('signup.title')
      }}>
        <BannerForm isLoading={isLoading}>
          <BannerFormContent
            title={t('signup.title')}
            subTitle={t('signup.fillIn')}
          >
            <FormTextField
              id={companyNameId}
              fieldData={getFormFieldState(companyNameId)}
              className={cn(styles.input)}
              label={t('profile.fullName')}
              onChange={onChangeValidation}
            />
            <FormTextField
              id={emailId}
              fieldData={getFormFieldState(emailId)}
              className={cn(styles.input)}
              label={t('common.email')}
              type="email"
              onChange={onChangeValidation}
            />
            <FormTextField
              id={passwordId}
              fieldData={getFormFieldState(passwordId)}
              className={cn(styles.input)}
              label={t('common.password')}
              type="password"
              onChange={onChangeValidation}
            />
            <FormTextField
              id={confirmPasswordId}
              fieldData={getFormFieldState(confirmPasswordId)}
              className={cn(styles.input)}
              label={t('common.confirmPassword')}
              type="password"
              onChange={onChangeValidation}
            />
            <div className={`${styles.tAndCGroup}`}>
              <Checkbox
                id={tncId}
                checked={getFormFieldState(tncId).value}
                onChange={(e) => onChangeValidation(tncId, e.target.checked)} 
                name={tncId}
              />
              <div className={`${styles.txtTermPrivacy}`}>
                <Trans
                  i18nKey="signup.signupTnCFormat"
                  components={{
                    term: <Link to='/terms/' />,
                    privacy: <Link to='/privacy/' />,
                  }}
                />
              </div>
            </div>
            <p className={cn(styles.errorMsg)}>{formErrorCode ? `*${t(`errorCodes.${formErrorCode}`)}` : ''}</p>
            <FillButton
              className={cn(styles.btnSignup)}
              disabled={!isFormReady()}
              onClick={register}
            >
              {t('signup.signup')}
            </FillButton>
            <Link className={cn(styles.txtHaveAcc)} to='/login/'>{t('signup.haveAccount')}</Link>
          </BannerFormContent>
        </BannerForm>
      </Page>
    </div>
  )
}

export default SigupPage