import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'


const useStoreLink = () => {
    const { i18n } = useTranslation()

    const generateStoreLink = React.useCallback(({storeId, companyName, logoUrl, redirect_url, skipLink}) => {
        const params = new URLSearchParams()
        params.append('id', storeId)
    
        if (companyName) {
            params.append('name', companyName)
        }
    
        if (logoUrl) {
            params.append('logo', logoUrl)
        }
    
        if (redirect_url) {
            params.append('redirect_url', redirect_url)
        }
    
        if (skipLink) {
            params.append('skip_link', skipLink)
        }
    
        return `/${i18n.language}/store/link/?` + params.toString()
    }, [i18n.language])

    return {
        generateStoreLink
    }
}

export {
    useStoreLink
}
