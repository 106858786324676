import React from 'react'
import cn from 'classnames'
import { Card } from '@material-ui/core'
import FillButton from 'components/presenter/buttons/FillButton'
import MaxCashbackReward from 'components/presenter/text/MaxCashbackReward'
import { LoginDialog, useLoginDialog } from '../LoginDialog'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import { useAuthStore } from 'stores'
import * as styles from './styles.module.css'
import { useStoreLink } from 'libs/affiliate'

const CompanyCard = ({className, affiliateData}) => {
    const { t } = useTranslation()
    const authStore = useAuthStore()
    const { open, handleClickOpen, onClose, onLogin } = useLoginDialog()
    const companyName = affiliateData?.company?.name
    const logoUrl = affiliateData?.company?.company_logo?.url
    
    const { generateStoreLink } = useStoreLink()
    const storeLink = generateStoreLink({
        storeId: affiliateData._id,
        companyName,
        logoUrl
    })

    const handleClick = (event) => {
        if (!authStore.isLoggedIn()) {
            event.preventDefault()
            handleClickOpen()
        }
    }

    return (
        <>
            <Card className={cn(className, styles.card)}>
                <img className={styles.logo} src={logoUrl} />
                <span className={styles.txtName}>{companyName}</span>
                <MaxCashbackReward rewards={affiliateData.rewards} />
                <FillButton
                    className={cn(styles.btn, 'mui-btn-link')}>
                    <a
                        href={storeLink}
                        onClick={handleClick}
                        target="_blank"
                    >
                        {t('affiliate.shopNow')}
                    </a>
                </FillButton>
            </Card>
            <LoginDialog 
                open={open}
                onClose={onClose}
                onLogin={onLogin}
            />
        </>
        
    )
}

export default React.memo(CompanyCard)