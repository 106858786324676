// extracted by mini-css-extract-plugin
export const rightSection = "bannerForm-module--rightSection---Mson";
export const card = "bannerForm-module--card--J3964";
export const loading = "bannerForm-module--loading--LRuha";
export const overlay = "bannerForm-module--overlay--DCsfd";
export const form = "bannerForm-module--form--2b+9I";
export const leftSection = "bannerForm-module--leftSection--AnUdB";
export const banner = "bannerForm-module--banner--fhL6-";
export const bannerContainer = "bannerForm-module--bannerContainer--GazaC";
export const bannerContentContainer = "bannerForm-module--bannerContentContainer--f2M6e";
export const bannerAppIcon = "bannerForm-module--bannerAppIcon--Mn03l";
export const bannerSpace = "bannerForm-module--bannerSpace--xdEjY";
export const bannerSlogan1 = "bannerForm-module--bannerSlogan1--SJ2Sw";
export const bannerSlogan2 = "bannerForm-module--bannerSlogan2--B7Y+L";