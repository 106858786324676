/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react'
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next'
import FillButton from 'components/presenter/buttons/FillButton'
import BannerForm from 'components/presenter/form/banner/BannerForm'
import BannerFormContent from 'components/presenter/form/banner/BannerFormContent'
import FormTextField from 'components/presenter/form/FormTextField'
import { EmptyValidator, EmailValidator, PasswordValidator } from 'libs/form/validator'
import { useForm } from 'libs/form/formHook'
import { LoginMethod } from 'api/coyns/graphql/constant'
import { useAuthStore } from 'stores'
import Page from 'components/presenter/page/Page'
import cn from 'classnames'
import * as styles from './login.module.css'

const emailId = 'email'
const passwordId = 'password'

const SiginPage = ({location}) => {
  const { t } = useTranslation()
  const authStore = useAuthStore()
  const { navigate } = useI18next()
  const { onChangeValidation, getFormFieldState, requestAsyc, isFormReady, isLoading, formErrorCode } = useForm({
    [emailId]: {
      validators: [EmptyValidator, EmailValidator],
    },
    [passwordId]: {
      validators: [EmptyValidator, PasswordValidator],
    },
  })
  /**
   *
   */
  async function login() {
    const response = await requestAsyc(authStore.login({
      userName: getFormFieldState(emailId).value,
      password: getFormFieldState(passwordId).value,
      loginMethod: LoginMethod.email
    }))
    if (!response.error) {
      const searchParams = new URLSearchParams(location.search)
      const redirectUrl = searchParams.get('redirect_url')
      if (redirectUrl) {
        navigate(redirectUrl)
      } else {
        navigate('/')
      }
    }
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && isFormReady) {
      e.preventDefault()
      await login()
    }
  }
  return (
    <div role="main" onKeyDown={handleKeyDown}>
      <Page seoMeta={{
        title: t('login.welcomeAlt')
      }}>
        <BannerForm isLoading={isLoading}>
          <BannerFormContent
            title={t('login.welcomeTo')}
            showAppIcon
          >
            <FormTextField
              id={emailId}
              className={cn(styles.inputEmail)}
              fieldData={getFormFieldState(emailId)}
              label={t('common.email')}
              type="email"
              onChange={onChangeValidation}
            />
            <FormTextField
              id={passwordId}
              fieldData={getFormFieldState(passwordId)}
              className={cn(styles.inputPassword)}
              label={t('common.password')}
              type="password"
              onChange={onChangeValidation}
            />
            <Link className={`${styles.txtForgetPassword}`} to='/forget-password/'>{t('login.forgetPassword')}</Link>
            <p className={cn(styles.errorMsg)}>{formErrorCode ? `*${t(`errorCodes.${formErrorCode}`)}` : ''}</p>
            <FillButton 
              className={cn(styles.btnLogin)}
              disabled={!isFormReady()}
              onClick={login}
            >
              {t('login.login')}
            </FillButton>
            <div className={cn(styles.signupGroup)}>
              <p className={cn(styles.txtNoaccount)}>{t('login.noAccount')}</p>
              <Link className={cn(styles.txtSignup)} to='/signup/'>{process.env.GATSBY_RELEASE_TYPE === 'beta' ? t('login.signUpNowBeta') : t('login.signUpNow')}</Link>
            </div>
          </BannerFormContent>
        </BannerForm>
      </Page>
    </div>
  )
}

export default SiginPage