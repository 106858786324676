import React from 'react'
import cn from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import FillButton from 'components/presenter/buttons/FillButton'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import AppIcon from 'assets/svg/app_icon.inline.svg'
import * as styles from './styles.module.css'

const styles2 = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles2)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const useLoginDialog = () => {
    const [open, setOpen] = React.useState(false);
    const { navigate } = useI18next()
    const handleClickOpen = React.useCallback(() => {
        setOpen(true)
    })
    const handleClose = React.useCallback(() => {
        setOpen(false)
    })

    const handleOnLogin = React.useCallback(() => {
        handleClose()
        navigate("/login/")
    })
    
    return {
        open,
        handleClickOpen,
        onClose: handleClose,
        onLogin: handleOnLogin,
    }
}


export function LoginDialog({open, onClose, onLogin}) {
    const { t } = useTranslation()
    return (
        <Dialog className={styles.dialog} onClose={onClose} open={open}>
            <DialogTitle onClose={onClose} />
            <MuiDialogContent className={styles.dialogContent}>
                <AppIcon className={cn(styles.logo)} alt={t('common.appName')} />
                
                <Typography gutterBottom>
                    {t('affiliate.loginHint')}
                </Typography>
                <FillButton
                    className={cn(styles.btnLogin, 'mui-btn-link')}
                    onClick={onLogin}
                >
                    {t('login.login')}
                </FillButton>
            </MuiDialogContent>
        </Dialog>
    )
}