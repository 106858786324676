import * as React from "react"
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import FillButton from 'components/presenter/buttons/FillButton'
import cn from 'classnames'
import { Paper } from '@material-ui/core'
import Page from 'components/presenter/page/Page'
import * as styles from './404.module.css'

// markup
const NotFoundPage = () => {
  const { t } = useTranslation()
  const { navigate } = useI18next()

  /**
   *
   */
  function backToHome() {
    navigate('/')
  }

  return (
    <Page>
      <Paper className={cn(styles.card)} elevation={2}>
        <h1 className={cn(styles.txt404)}>404</h1>
        <p className={cn(styles.txtTitle)}>{t('404.pageNotFound')}</p>
        <p className={cn(styles.txtDes)}>{t('404.sorryDes')}</p>
        <FillButton
          className={cn(styles.btn)}
          onClick={backToHome}
        >
          {t('common.backToHome')}
        </FillButton>
      </Paper>
    </Page>
  )
}

export default NotFoundPage
