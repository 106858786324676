import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

export const useSearchBar = (location) => {
  const { navigate } = useI18next()

  const [searchData, setSearchData] = React.useState({
    text: null,
  })

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setSearchData({
      ...searchData,
      text: searchParams.get('q'),
    })
  }, [location])

  const onTextChange = (text) => setSearchData({
    ...searchData,
    text
  })

  const handleOnSearch = ({text}) => {
    if (text) {
      const searchParams = new URLSearchParams()
      if (text) {
        searchParams.append('q', text)
      }
      navigate(`/jetso/search/?${searchParams.toString()}`)
    }
  }

  return {
    searchData,
    onTextChange,
    handleOnSearch
  }
}