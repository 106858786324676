import { requestIdleCallback } from '../../timeout'

export default class Tracker {
  constructor () {
    this._initDone = false
    this._deferInitDone = false
    
    // Initialisation queue
    this._initQueue = []
  }

  init () {
    this._initDone = true
  }

  deferInit () {
    // Only initialize once
    if (!this._deferInitDone) {
      this._deferInitDone = true

      this.init()

      // Schedule executing callbacks in init queue
      this._initQueue.forEach((callback) => {
        requestIdleCallback(callback)
      })
    }
  }

  sendConversion (id) {
  }

  sendCustomEvent (eventName, eventVariables) {
  }

  sendPageViewEvent (pageData) {
  }

  setUserId (userId) {
  }

  setAppVersion (version) {
  }

  setUserProperties (properties) {
  }

  executeOrQuery(callback) {
    if (!this._deferInitDone) {
      // If tracker not initialised yet,
      // add send event callback to init queue
      this._initQueue.push(callback)
    } else {
      // Otherwise schedule sending event immediately
      requestIdleCallback(callback)
    }
  }
}
