import { useState } from 'react'

export const useLoading = () => {
  const [ isLoading, setLoading ] = useState(false)
  const processAsyc = async (promise) => {
    setLoading(true)
    const result = await promise
    setLoading(false)
    return result
  }

  return {
    isLoading,
    processAsyc
  }
}