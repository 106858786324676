import React from 'react'
import cn from 'classnames'
import { apiManager } from 'api'
import FillButton from 'components/presenter/buttons/FillButton'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useAuthStore } from 'stores'
import { displayDateWithTime } from 'libs/date/dateUtil'
import { Paper } from '@material-ui/core'
import Page from 'components/presenter/page/Page'
import * as styles from './styles.module.css'

const CampaignLinkPassPage = ({ location }) => {
  const { t } = useTranslation()
  const { navigate } = useI18next()
  const [campaign, setCampaign] = React.useState(null)
  const [message, setMessage] = React.useState(null)
  const { language } = useI18next()
  const queryParams = new URLSearchParams(location.search)
  const authStore = useAuthStore()

  const passCode = queryParams.get('passCode')

  /**
   *
   */
  const backToLogin = React.useCallback(() => {
    navigate('/login?redirect_url=' + location.href)
  }, [navigate])

  const backToHome = React.useCallback(() => {
    navigate('/')
  }, [navigate])

  const linkCampaignPass = React.useCallback(async () => {
    if (campaign && authStore.isInitial() && authStore.isLoggedIn()) {
      const res = await apiManager.linkCampaignPass(passCode)
      if (res.error) {
        setMessage(t('campaign.failToJoin', {message: res.error.errors[0].message}))
      } else {
        setMessage(t('campaign.successToJoin'))
      }
    }
  }, [passCode, campaign, authStore.isInitial(), authStore.isLoggedIn()])

  const renderActionButton = React.useMemo(() => {
    if (!authStore.isLoggedIn()) {
      return (<></>)
    }
    if (message) {
      return (
        <>
        <p>{message}</p>
        <FillButton
          className={cn(styles.btn)}
          onClick={backToHome}
          >
            {t('common.backToHome')}
        </FillButton>
      </>
    )}
    return (
      <>
        <FillButton
          className={cn(styles.btnGet)}
          onClick={linkCampaignPass}
        >
          {t('campaign.get')}
        </FillButton>
      </>
    )
  }, [linkCampaignPass, message])

  React.useEffect(async () => {
    if (authStore.isInitial()) {
      const res = await apiManager.getCampaignByPassId(passCode, language)

      setCampaign(res.get_campaign_by_passId)
    }
  }, [authStore.isInitial()])

  return (
    <Page>
      {campaign && (
      <Paper className={cn(styles.card)} elevation={2}>
        <h1 className={cn(styles.txtTitle)}>{campaign.name}</h1>
        <p className={cn(styles.txtDes)}>{t("campaign.expiredAtFormat", {expiredAt: displayDateWithTime(campaign.expired_at)})}</p>
        <p className={cn(styles.txtDes)}>{t("campaign.expiredHint", {day: campaign.pass_life_time})}</p>
        {authStore.isAnonymous() && (
          <>
            <FillButton
            className={cn(styles.btn)}
            onClick={backToLogin}
            >
              {t('campaign.loginToJoin')}
            </FillButton>
            <p className={cn(styles.txtHelper)}>{t("campaign.notRegisterYet")}</p>
          </>
        )}
        {
          renderActionButton
        }
        </Paper>
      )}
    </Page>
  )
}
export default CampaignLinkPassPage