// extracted by mini-css-extract-plugin
export const tab = "styles-module--tab--GXPug";
export const tabCard = "styles-module--tabCard--nZRFc";
export const tabPanel = "styles-module--tabPanel--6zxLt";
export const emptyCard = "styles-module--emptyCard--FViRn";
export const dealCard = "styles-module--dealCard--CLiGG";
export const dealCardContent = "styles-module--dealCardContent--IAUpy";
export const dealCardTitle = "styles-module--dealCardTitle--jV-Lo";
export const dealCardExpired = "styles-module--dealCardExpired--aruhQ";
export const dealCardBody = "styles-module--dealCardBody--iRg2L";
export const promoCodePanel = "styles-module--promoCodePanel--wpaN4";
export const promoCodeCard = "styles-module--promoCodeCard--CQ3ef";
export const promoCodeLeftSection = "styles-module--promoCodeLeftSection---RyZ-";
export const promoCodeCardContent = "styles-module--promoCodeCardContent--x-oid";
export const promoCodeCardTitle = "styles-module--promoCodeCardTitle--5wNDy";
export const promoCodeCardExpired = "styles-module--promoCodeCardExpired--v7IxQ";
export const promoCodeCardBody = "styles-module--promoCodeCardBody--tVju5";
export const promoCodeButtonSection = "styles-module--promoCodeButtonSection--nvIzr";
export const promoCodeButton = "styles-module--promoCodeButton--8aJ6Z";
export const promoCodeTerms = "styles-module--promoCodeTerms--6-eqe";
export const link = "styles-module--link--ITrxH";