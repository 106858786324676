import jwt from 'jsonwebtoken'

const getPayload = (jwtToken) => {
  if (!jwtToken) {
    return null
  }
  return jwt.decode(jwtToken)
}

const isExpired = (payload) => !payload || !(payload && payload.exp && Date.now() <= payload.exp * 1000)

export default {
  getPayload,
  isExpired,
}