import React from 'react'

const PlainLayout = ({ children }) => (
    <>
      <main className="main">
        {children}
      </main>
    </>
  )

export default PlainLayout