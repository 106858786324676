import * as React from 'react'
import cn from 'classnames'
import Page from 'components/presenter/page/Page'
import { Box } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { DataGrid } from '@mui/x-data-grid'
import { useI18next } from 'gatsby-plugin-react-i18next'
import * as styles from './profile.module.css'
import { displayDateWithTime } from 'libs/date/dateUtil'
import { useUserStore, useAuthStore } from 'stores'


const CampaignPassPage = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const authStore = useAuthStore()
  const userStore = useUserStore()
  
  const [loading, setLoading] = React.useState(true)

  const campaignPassList = userStore.getCampaignPass()

  React.useEffect(async () => {
    if (authStore.isInitial()) {
      setLoading(true)
      await userStore.fetchMyCampaignPass(language)
      setLoading(false)
    }
  }, [authStore.isInitial()])

  const columns = React.useMemo(() => [
    {
      field: 'campaign.campaignName',
      headerName: t('campaign.campaignName'),
      valueGetter: (_, row) => row.campaign?.name,
    },
    // {
    //   field: 'is_applied',
    //   headerName: t('common.isApplied'),
    //   valueGetter: (_, row) => row.is_applied ? t('common.yes') : t('common.no'),
    // },
    {
      field: 'created_at',
      headerName: t('campaign.createAt'),
      valueGetter: (value) => value ? displayDateWithTime(value) : '-',
    },
    {
      field: 'expired_at',
      headerName: t('campaign.expiredAt'),
      valueGetter: (value) => value ? displayDateWithTime(value) : '-',
    },
    {
      field: 'campaign.ratio',
      headerName: t('campaign.ratio'),
      valueGetter: (_, row) => row.campaign?.ratio ? `${row.campaign?.ratio} %` : '-',
    },
    {
      field: 'quota',
      headerName: t('campaign.quota'),
      valueGetter: (_, row) => row.quota === 0 ? '0' : (row.quota || '-'),
    },
    {
      field: 'campaign.type',
      headerName: t('campaign.type'),
      valueGetter: (_, row) => t(`campaign.type${row.campaign?.type}`),
    },
  ], [t, language])


  return (
    <Page className={cn(styles.root, 'container')} seoMeta={{
      title: t('common.campaignPass')
    }}>
      {campaignPassList && (
        <Box className={styles.table}>
          <DataGrid
            rows={campaignPassList}
            columns={columns}
            loading={loading}
            getRowId={(row) => row._id}
            disableColumnFilter
            disableColumnSorting
            disableColumnMenu
            disableRowSelectionOnClick
            autoHeight
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              expand: true,
            }}
          />
        </Box>
      )}
    </Page>
  )
}

export default CampaignPassPage