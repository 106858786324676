import React from "react";
import { useInView } from "react-intersection-observer";

const InfiniteScroll = ({ loadMore }) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
  });

  React.useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView, loadMore]);

  return <div ref={ref} style={{ height: "10px" }} />
};

export default InfiniteScroll;