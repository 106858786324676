import * as React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import Cropper from 'react-cropper'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import * as styles from './styles.module.css'

const CropperDialog = ({
  className = '',
  src = '',
  onCrop,
  onClose,
  isCropBoxFullSize = false,
  cropOption = {},
}) => {
  const { t } = useTranslation()
  const cropperRef = React.useRef(null)

  const aspectRatio = cropOption.croppedImageWidth / cropOption.croppedImageHeight

  // eslint-disable-next-line no-underscore-dangle
  const _onCrop = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    
    // Get the original image mime
    const mime = cropper.url.substring(cropper.url.indexOf(":") + 1, cropper.url.indexOf(";"))
    const quality = cropOption.quality || 0.7
    
    cropper.getCroppedCanvas({
      width: cropOption.croppedImageWidth,
      height: cropOption.croppedImageHeight,
    }).toBlob(blob => {
      if (onCrop) {
        onCrop(blob)
      }
    }, mime, quality)
  }

  const onCropperReady = () => {
    const imageElement = cropperRef?.current
    const cropper = imageElement?.cropper
    const imageData = cropper.getImageData()

    // Force crop box to full size when the ratio is matched
    if (isCropBoxFullSize && imageData.aspectRatio === aspectRatio) {
      cropper.setCropBoxData({
        left: 0,
        top: 0,
        height: imageData.height,
        width: imageData.width
      })
    }
  }
  return (
    <Dialog
      open
      className={className}
      onClose={onClose}
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">{t('common.crop')}</DialogTitle>
      <DialogContent>
        <Cropper
          className={styles.cropper}
          src={src}
          initialAspectRatio={aspectRatio}
          aspectRatio={aspectRatio}
          ref={cropperRef}
          ready={onCropperReady}
        />
      </DialogContent>
      <DialogActions>
        <Button className="mui-dialog-btn-nok" onClick={onClose}>
          {t('common.cancel')}
        </Button>
        <Button className="mui-dialog-btn-ok" onClick={_onCrop}>
          {t('common.crop')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CropperDialog