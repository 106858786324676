import * as React from 'react'
import cn from 'classnames'
import Page from 'components/presenter/page/Page'
import { Paper } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import EditBankTaxSection from './EditBankTaxSection'
import * as styles from './profile.module.css'

const PaymentPage = () => {
  const { t } = useTranslation()
  return (
    <Page className={cn(styles.root, 'container')} seoMeta={{
      title: t('common.payment')
    }}>
      <Paper className={styles.paper} elevation={2}>
        <EditBankTaxSection />
      </Paper>
    </Page>
  )
}

export default PaymentPage