import * as React from 'react'
import cn from 'classnames'
import Page from 'components/presenter/page/Page'
import { Paper, Box } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { DataGrid } from '@mui/x-data-grid'
import { useI18next } from 'gatsby-plugin-react-i18next'
import * as styles from './profile.module.css'
import { displayDate, displayDateWithTime } from 'libs/date/dateUtil'
import { apiManager } from 'api'
import { useAuthStore } from 'stores'

const ReportPage = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const authStore = useAuthStore()
  const [accountOverview, setAccountOverview] = React.useState(null)
  const [commissionReport, setCommissionReport] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(async () => {
    if (authStore.isInitial()) {
      setLoading(true)
      const res = await apiManager.getAccountOverview()
      setAccountOverview(res.get_account_overview)
      setCommissionReport(res.get_commission_report)
      setLoading(false)
    }
  }, [authStore.isInitial()])

  const totalClaimableCommission = accountOverview ? (accountOverview?.balance_by_purchase + accountOverview?.balance_by_share) : 0
  const totalPendingCommission = accountOverview ? (accountOverview?.incoming_by_purchase + accountOverview?.incoming_by_share) : 0

  const onPaginationModelChange = async (model) => {
    if (commissionReport.records.length >= (model.page + 1) * model.pageSize) {
      return
    }
    
    setLoading(true)
    const lastID = commissionReport.records[commissionReport.records.length - 1]._id
    const res = await apiManager.getCommissionReport({
      limit: 15,
      lastID,
    })
    commissionReport.records = [...commissionReport.records, ...res.get_commission_report.records]
    setCommissionReport(commissionReport)
    setLoading(false)
  }

  const columns = React.useMemo(() => [
    {
      field: 'order_at',
      headerName: t('report.orderAt'),
      valueGetter: (value) => displayDateWithTime(value),
    },
    {
      field: 'company',
      headerName: t('report.companyName'),
      valueGetter: (value) => value.name,
    },
    { field: 'order_id', headerName: t('report.orderId') },
    {
      field: 'transcation_state',
      headerName: t('report.status'),
      valueGetter: (_, row) => {
        const { transcation_state } = row

        switch (transcation_state) {
          case 0:
            return t('report.statusPending')
          case 1:
            return t('report.statusClaimable')
          case 2:
            return t('report.statusSettling')
          case 3:
            return t('report.statusSettled')
          case 4:
            return t('report.statusCancelled')
        }
      }
    },
    {
      field: 'sale_amount',
      headerName: t('report.saleAmount'),
      valueGetter: (_, row) => row.sale_amount ? `${row.sale_amount} ${row.sale_amount_currency}` : '-',
    },
    {
      field: 'commission_amount',
      headerName: t('report.commissionAmount'),
      valueGetter: (_, row) => row.commission_amount ? `${row.commission_amount} ${row.commission_amount_currency}` : '-',
    },
    {
      field: 'commission_rate',
      headerName: t('report.commissionRate'),
      valueGetter: (_, row) => {
        const { commission_type, commission_rate, commission_amount_currency } = row

        if (commission_rate) {
          if (commission_type === 1) {
            return `${commission_rate}%`
          } else if (commission_type === 2) {
            return `${commission_rate} ${commission_amount_currency}`
          }
        }
        return '-'
      }
    },
    {
      field: 'product_type',
      headerName: t('report.productType'),
      valueGetter: (_, row) => {
        const { product_type } = row
        const { domain } = row.company
        return product_type ? t(`product_category.${domain}.${product_type}`) : '-'
      }
    },
    {
      field: 'product_name',
      headerName: t('report.productName')
    },
    {
      field: 'activity_start_at',
      headerName: t('report.startAt'),
      valueGetter: (value) => value ? displayDate(value) : '-',
    },
    {
      field: 'activity_end_at',
      headerName: t('report.endAt'),
      valueGetter: (value) => value ? displayDate(value) : '-',
    },
  ], [t, language])

  return (
    <Page className={cn(styles.root, 'container')} seoMeta={{
      title: t('common.cashbackReport')
    }}>
      {accountOverview && (
        <>
          <div className={styles.container}>
            <Paper className={styles.stat} elevation={2}>
              <h2>
                {t('report.claimableCommission')}
              </h2>
              <p>{`${totalClaimableCommission} ${accountOverview?.balance_unit}`}</p>
            </Paper>

            <Paper className={styles.stat} elevation={2}>
              <h2>
                {t('report.pendingCommission')}
              </h2>
              <p>{`${totalPendingCommission} ${accountOverview?.incoming_unit}`}</p>
            </Paper>
          </div>
          <Box className={styles.table}>
            <DataGrid
              rows={commissionReport?.records}
              columns={columns}
              loading={loading}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              getRowId={(row) => row._id}
              onPaginationModelChange={onPaginationModelChange}
              disableColumnFilter
              disableColumnSorting
              disableColumnMenu
              disableRowSelectionOnClick
              autoHeight
              autosizeOnMount
              autosizeOptions={{
                includeHeaders: true,
                expand: true,
              }}
            />
          </Box>
        </>
      )}
    </Page>
  )
}

export default ReportPage