import React, { useEffect, useState } from 'react'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import BannerForm from 'components/presenter/form/banner/BannerForm'
import BannerFormContent from 'components/presenter/form/banner/BannerFormContent'
import FillButton from 'components/presenter/buttons/FillButton'
import { getFirstErrorCode } from 'api'
import { AccountAlreadyExist } from 'libs/error/errorCodes'
import jwt from 'libs/crypt/jwt'
import cn from 'classnames'
import { useAuthStore } from 'stores'
import { useLoading } from 'libs/form/loadingHook'
import Page from 'components/presenter/page/Page'
import * as styles from './email-verify.module.css'

const EamilVerifyPage = ({ location }) => {
  const { t } = useTranslation()
  const { navigate } = useI18next()
  const [ state, setState ] = useState({
    isActivating: true,
    title: t('email-verify.activating'),
    content: '',
    email: '',
    action: ''
  })
  const { isLoading, processAsyc } = useLoading()
  const authStore = useAuthStore()
  
  /**
   * @param {string} token jwt token for verification
   */
  async function callVerifyApi(token) {
    if (!token) {
      return
    }

    const response = await processAsyc(authStore.verifyEmail(token))

    const errorCode = getFirstErrorCode(response)

    if (errorCode) {
      setState({
        ...state,
        isActivating: false,
        title: errorCode === AccountAlreadyExist ? t(`errorCodes.${errorCode}`) : t('email-verify.failActivate'),
        content: errorCode === AccountAlreadyExist ? t('email-verify.description') : t('email-verify.failContent'),
        action: errorCode === AccountAlreadyExist ? 'post' : 'register'
      })
      return
    }

    setState({
      ...state,
      isActivating: false,
      title: t('email-verify.title'),
      content: t('email-verify.description'),
      action: 'post'
    })
  }

  /**
   *
   */
  function redirect() {
    navigate(state.action === 'register' ? '/signup/' : '/')
  }

  /**
   * @returns {string} token 
   */
  function decodeToken() {
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')
    if (!token) {
      setState({
        ...state,
        isActivating: false,
        title: t('email-verify.failActivate'),
        content: t('email-verify.failContent'),
        action: 'register'
      })
      return null
    }
    const decoded = jwt.getPayload(token)
    state.email = decoded.username
    setState({
      ...state,
    })
    return token
  }

  useEffect(() => {
    callVerifyApi(decodeToken())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page seoMeta={{
      title: t(state.title)
    }}>
      <BannerForm isLoading={isLoading}>
        <BannerFormContent
          title={state.title}
          subTitle={state.email}
        >
          { state.action && (
            <>
              <div className={cn(styles.content)}>{state.content}</div>
              <FillButton
                className={cn(styles.btnPost)}
                onClick={redirect}
              >
                {state.action === 'register' ? t('signup.signup') : t('email-verify.navigate')}
              </FillButton>
            </>
          )}
        </BannerFormContent>
      </BannerForm>
    </Page>
  )
}

export default EamilVerifyPage