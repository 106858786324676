import React from 'react'
import { Checkbox as MaterialCheckBox } from '@material-ui/core'
import cn from 'classnames'
import * as styles from'./checkbox.module.css'

const Checkbox = ({ className = '', name, isChecked, onChange }) => (
    <MaterialCheckBox
      className={cn(styles.checkbox, className)}
      checked={isChecked}
      onChange={onChange}
      name={name}
    />
  )
export default Checkbox