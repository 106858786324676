import './src/assets/styles/base.css'
import './src/assets/styles/material-ui.css'
import 'cropperjs/dist/cropper.css'
import 'url-search-params-polyfill'

// Import slick-carousel css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

// export { wrapPageElement, wrapRootElement } from './gatsby-ssr'
export { wrapRootElement } from './gatsby-ssr'

export const onClientEntry = () => {
  Sentry.init({
    debug: process.env.ENVIRONMENT === 'development',
    dsn: process.env.GATSBY_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.ENVIRONMENT,
    release: process.env.__APP_VERSION__,
    attachStacktrace: true,
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}