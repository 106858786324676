// extracted by mini-css-extract-plugin
export const sectionRoot = "styles-module--sectionRoot--57Iqc";
export const card = "styles-module--card--zWjLt";
export const subTitle = "styles-module--subTitle--W7OQA";
export const search = "styles-module--search--98db8";
export const input = "styles-module--input--ajNDb";
export const divider = "styles-module--divider--lhFMB";
export const txtError = "styles-module--txtError--YGVGV";
export const txtResult = "styles-module--txtResult--z8zXX";
export const txtMaxCashback = "styles-module--txtMaxCashback--2Kuyg";
export const txtHighlight = "styles-module--txtHighlight--yZwEL";
export const success = "styles-module--success--RLWvI";