import React from 'react'
import cn from 'classnames'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import * as styles from './styles.module.css'

const MaxCashbackText = ({ className, reward }) => {
    const { t } = useTranslation()
    const cashbackValue = reward.cashback_value
    const cashbackValueChanged = reward.cashback_value_changed

    if (cashbackValueChanged && cashbackValueChanged > 0) {
      const formatStr = reward.cashback_type === 2 ? 'cashback.maxCashbackWithCampaignFormatForFixedRatePerTicket' : 'cashback.maxCashbackWithCampaignFormat'
      return (
        <p className={cn(styles.txtMaxCashback, className)}>
          <Trans
            i18nKey={formatStr}
            values={{
              maxOldCashback: cashbackValue,
              maxNewCashback: cashbackValueChanged,
              currency_unit: reward.currency_unit
            }}
            components={{
              old: <span className={styles.oldCashback} />,
              new: <span className={styles.newCashback} />,
            }}
          />
        </p>
      )
    }

    const formatStr = reward.cashback_type === 2 ? 'cashback.maxCashbackFormatForFixedRatePerTicket' : 'cashback.maxCashbackFormat'

    const maxCashbackStr = t(formatStr, {
      maxCashback: cashbackValue,
      currency_unit: reward.currency_unit
    })
    return (
      <p className={cn(styles.txtMaxCashback, className)}>{maxCashbackStr}</p>
    )
}

export default MaxCashbackText