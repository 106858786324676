import React from 'react'
import {
  Dialog,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Box } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { DataGrid } from '@mui/x-data-grid'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { displayDate, displayDateWithTime } from 'libs/date/dateUtil'
import { apiManager } from 'api'
import { useAuthStore } from 'stores'
import * as styles from './styles.module.css'

const PopupDetailInvoicePage = ({
  invoiceId,
  onClose = () => {}
}) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const authStore = useAuthStore()
  const [commissionReport, setCommissionReport] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(async () => {
    if (authStore.isInitial()) {
      setLoading(true)
      const res = await apiManager.getInvoiceDetail(invoiceId)
      if (res.get_invoice_detail) {
        setCommissionReport(res.get_invoice_detail.commission_reports || [])
      }
      setLoading(false)
    }
  }, [authStore.isInitial()])


  const columns = React.useMemo(() => [
    {
      field: 'order_at',
      headerName: t('report.orderAt'),
      valueGetter: (value) => displayDateWithTime(value),
    },
    {
      field: 'company',
      headerName: t('report.companyName'),
      valueGetter: (value) => value.name,
    },
    { field: 'order_id', headerName: t('report.orderId') },
    {
      field: 'transcation_state',
      headerName: t('report.status'),
      valueGetter: (_, row) => {
        const { transcation_state } = row

        switch (transcation_state) {
          case 0:
            return t('report.statusPending')
          case 1:
            return t('report.statusClaimable')
          case 2:
            return t('report.statusSettling')
          case 3:
            return t('report.statusSettled')
          case 4:
            return t('report.statusCancelled')
        }
      }
    },
    {
      field: 'sale_amount',
      headerName: t('report.saleAmount'),
      valueGetter: (_, row) => row.sale_amount ? `${row.sale_amount} ${row.sale_amount_currency}` : '-',
    },
    {
      field: 'commission_amount',
      headerName: t('report.commissionAmount'),
      valueGetter: (_, row) => row.commission_amount ? `${row.commission_amount} ${row.commission_amount_currency}` : '-',
    },
    {
      field: 'commission_rate',
      headerName: t('report.commissionRate'),
      valueGetter: (_, row) => {
        const { commission_type, commission_rate, commission_amount_currency } = row

        if (commission_rate) {
          if (commission_type === 1) {
            return `${commission_rate}%`
          } else if (commission_type === 2) {
            return `${commission_rate} ${commission_amount_currency}`
          }
        }
        return '-'
      }
    },
    {
      field: 'product_type',
      headerName: t('report.productType'),
      valueGetter: (_, row) => {
        const { product_type } = row
        const { domain } = row.company
        return product_type ? t(`product_category.${domain}.${product_type}`) : '-'
      }
    },
    {
      field: 'product_name',
      headerName: t('report.productName')
    },
    {
      field: 'activity_start_at',
      headerName: t('report.startAt'),
      valueGetter: (value) => value ? displayDate(value) : '-',
    },
    {
      field: 'activity_end_at',
      headerName: t('report.endAt'),
      valueGetter: (value) => value ? displayDate(value) : '-',
    },
  ], [t, language])

  return (
    <Dialog
      fullScreen
      open>
        <div className={styles.base}>
          <IconButton
            className={styles.icon}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Box className={styles.table}>
          <DataGrid
            rows={commissionReport}
            columns={columns}
            loading={loading}
            getRowId={(row) => row._id}
            disableColumnFilter
            disableColumnSorting
            disableColumnMenu
            disableRowSelectionOnClick
            autoHeight
            autosizeOnMount
            autosizeOptions={{
              includeHeaders: true,
              expand: true,
            }}
          />
        </Box>
    </Dialog>
  )
}

export default PopupDetailInvoicePage