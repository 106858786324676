import moment from 'moment'
import 'moment/locale/zh-hk'


// Display time in local time. Input date/time in UTC format
const setDateLocale = (locale) => {
  let shortForm = null
  if (locale === 'en_US') {
    shortForm = {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s:  '1s',
        ss: '%ss',
        m:  '1m',
        mm: '%dm',
        h:  '1h',
        hh: '%dh',
        d:  '1d',
        dd: '%dd',
        M:  '1M',
        MM: '%dM',
        y:  '1Y',
        yy: '%dY'
      }
    }
  }
  moment.locale(locale.replace('_', '-').toLowerCase(), shortForm)
}

const displayDate = (date) => date ? moment.utc(date).format('YYYY-MM-DD') : null
const displayDateWithTime = (date) => date ? moment.utc(date).format('YYYY-MM-DD HH:mm') : null
const fromNow = (date) => date ? moment.utc(date).fromNow() : null

export {
  setDateLocale,
  displayDate,
  displayDateWithTime,
  fromNow
}