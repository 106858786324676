import * as React from 'react'
import FillButton from 'components/presenter/buttons/FillButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { EmptyValidator } from 'libs/form/validator'
import { useSnackbar } from 'libs/ui/snackbar'
import FormTextField from 'components/presenter/form/FormTextField'
import TextField from 'components/presenter/input/TextField'
import Checkbox from 'components/presenter/input/Checkbox'
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Form from 'components/presenter/form/Form'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import InputLabel from '@material-ui/core/InputLabel'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Select from '@material-ui/core/Select'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import InfoIcon from '@material-ui/icons/Info'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import cn from 'classnames'
import { useUserStore, useAuthStore } from 'stores'
import { useForm } from 'libs/form/formHook'
import { apiManager } from 'api'
import * as styles from './styles.module.css'

const bankLocationId = 'locationId'
const bankNameId = 'bankNameId'
const bankCodeId = 'bankCodeId'
const branchCodeId = 'branchCodeId'
const accountNameId = 'accountNameId'
const accountNumberId = 'accountNumberId'
const bankAddressId = 'bankAddressId'
const swiftCodeId = 'swiftCodeId'
const branchNameId = 'branchNameId'
const accountTypeId = 'accountTypeId'
const nationalityId = 'nationalityId'
const idTypeId = 'idTypeId'
const idNumberId = 'idNumberId'
const nameOnIdId = 'nameOnIdId'
const genderId = 'genderId'
const dateOfBirthId = 'dateOfBirthId'
const residentialAddressId = 'residentialAddressId'
const businessLicenseIdId = 'businessLicenseIdId'
const taxIdId = 'taxIdId'
const checkBoxId = 'checkBoxId'
const phoneNumberId = 'phoneNumberId'
const countryCodeId = 'countryCodeId'


const IdCardTypes = [1, 2, 3, 4, 5, 6, 7, 8, 9]
const Gender = [1, 2]

const CustomSelect = ({classes, required, disabled, label, value, onChange, children, id}) => {
  return (
    <FormControl required={required} disabled={disabled} variant="outlined" className={cn('mui-textfield', classes)}>
      <InputLabel>{label}</InputLabel>
      <Select
        id={id}
        value={value ?? ''}
        onChange={onChange}
        label={label}
      >
        {children}
      </Select>
    </FormControl>
  )
}

const CustomPopper = ({children, title}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  return (
    <div>
      <IconButton
        onClick={handleClick}
        edge="end"
      >
        {children}
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={styles.popper}>
              <p>{title}</p>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

// const CustomTooltip = ({children, title}) => {
//   const [open, setOpen] = React.useState(false);

//   const handleTooltipClose = () => {
//     setOpen(false);
//   };

//   const handleTooltipOpen = () => {
//     setOpen(true);
//   };
  
//   return (
//     <ClickAwayListener onClickAway={handleTooltipClose}>
//       <Tooltip
//         PopperProps={{
//           disablePortal: true,
//         }}
//         onClose={handleTooltipClose}
//         open={open}
//         disableFocusListener
//         disableHoverListener
//         disableTouchListener
//         title={title}
//       >
//         <IconButton
//             onClick={handleTooltipOpen}
//             edge="end"
//           >
//             {children}
//         </IconButton>
//       </Tooltip>
//     </ClickAwayListener>
//   )
// }

const TaxIndividal = ({userProfile, countryCodes, getFormFieldState, onChangeValidation}) => {
  const { t } = useTranslation()

  const countryCodesMenu = React.useMemo(() => {
    return countryCodes.map((countryCode) => <MenuItem id={countryCode.alpha_2} value={countryCode.alpha_2}>{`${countryCode.name} (${countryCode.phone_prefix})`}</MenuItem>)
  }, [countryCodes])

  const nationalityMenu = React.useMemo(() => {
    return countryCodes.map((countryCode) => <MenuItem id={countryCode.alpha_2} value={countryCode.alpha_2}>{countryCode.name}</MenuItem>)
  }, [countryCodes])

  const genderMenu = React.useMemo(() => {
    return Gender.map((gender) => <MenuItem id={gender} value={gender}>{t(`payment.gender_${gender}`)}</MenuItem>)
  }, [])

  const idCardTypesMenu = React.useMemo(() => {
    return IdCardTypes.map((type) => <MenuItem id={type} value={type}>{t(`payment.id_type_${type}`)}</MenuItem>)
  }, [])
  
  return (
    <>
      <div className={styles.bankSection}>
        <CustomSelect 
          id={nationalityId}
          required 
          disabled={userProfile.is_bank_tax_created}
          classes={cn(styles.input, styles.countryInput, styles.bankInput)}
          value={getFormFieldState(nationalityId).value}
          onChange={(e) => onChangeValidation(nationalityId, e.target.value)}
          label={t('payment.nationality')}
        >
          {
            nationalityMenu
          }
        </CustomSelect>
        <CustomSelect 
          id={idTypeId}
          required 
          disabled={userProfile.is_bank_tax_created}
          classes={cn(styles.input, styles.countryInput, styles.bankInput)}
          value={getFormFieldState(idTypeId).value}
          onChange={(e) => onChangeValidation(idTypeId, e.target.value)}
          label={t('payment.idType')}
        >
          {
            idCardTypesMenu
          }
        </CustomSelect>
        <FormTextField
          id={idNumberId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(idNumberId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.idNumber')}
          type="text"
          onChange={onChangeValidation}
        />
        <FormTextField
          id={nameOnIdId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(nameOnIdId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.nameOnId')}
          type="text"
          onChange={onChangeValidation}
        />
      </div>
      <div className={styles.bankSection}>
        <CustomSelect 
          id={countryCodeId}
          required 
          disabled={userProfile.is_bank_tax_created}
          classes={cn(styles.input, styles.countryInput, styles.bankInput)}
          value={getFormFieldState(countryCodeId).value}
          onChange={(e) => onChangeValidation(countryCodeId, e.target.value)}
          label={t('payment.countryCode')}
        >
            {
              countryCodesMenu
            }
        </CustomSelect>
        <FormTextField
          id={phoneNumberId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(phoneNumberId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.phoneNumber')}
          type="text"
          onChange={onChangeValidation}
        />
        <CustomSelect 
          id={genderId}
          required 
          disabled={userProfile.is_bank_tax_created}
          classes={cn(styles.input, styles.countryInput, styles.bankInput)}
          value={getFormFieldState(genderId).value}
          onChange={(e) => onChangeValidation(genderId, e.target.value)}
          label={t('payment.gender')}
        >
            {
              genderMenu
            }
        </CustomSelect>
        <KeyboardDatePicker
          id={dateOfBirthId}
          className={cn('mui-textfield', styles.input, styles.bankInput, styles.dateTimeInput)}
          variant="inline"
          disabled={userProfile.is_bank_tax_created}
          inputVariant="outlined"
          format="MM/dd/yyyy"
          margin="normal"
          label={t('payment.dateOfBirth')}
          value={getFormFieldState(dateOfBirthId).value}
          onChange={(date) => onChangeValidation(dateOfBirthId, date)}
        />
      </div>
      <div className={styles.bankSection}>
        <FormTextField
          id={residentialAddressId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(residentialAddressId)}
          className={cn(styles.input)}
          label={t('payment.residentialAddress')}
          type="text"
          onChange={onChangeValidation}
        />
      </div>
    </>
  )
}

const TaxBusiness = ({userProfile, getFormFieldState, onChangeValidation}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.bankSection}>
        <FormTextField
          id={businessLicenseIdId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(businessLicenseIdId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.businessLicenseId')}
          type="text"
          onChange={onChangeValidation}
        />
        <FormTextField
          id={taxIdId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(taxIdId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.taxId')}
          type="text"
          onChange={onChangeValidation}
        />
      </div>
      <p className={cn(styles.bizTxt)}>{t('payment.businessAccDescription')}</p>
    </>
  )
}

const TaxSection = ({userProfile, onChangeValidation, getFormFieldState, countryCodes}) => {
  const { t } = useTranslation()

  const accountType = getFormFieldState(accountTypeId).value
  if (!accountType) {
    return (
      <></>
    )
  }
  return (
    <>
      <h1 className={styles.title}>{t('payment.taxationInfo')}</h1>
      <div className={cn(styles.accountSection)}>
        <FormControl disabled={userProfile.is_bank_tax_created} component="fieldset">
          <FormLabel component="legend">{t('payment.accountType')}</FormLabel>
          <RadioGroup id={accountTypeId} row value={accountType} onChange={(e) => onChangeValidation(accountTypeId, parseInt(e.target.value, 10))}>
            <FormControlLabel value={1} control={<Radio />} label={t('payment.individual')} />
            <FormControlLabel value={2} control={<Radio />} label={t('payment.businessAccount')} />
          </RadioGroup>
        </FormControl>
      </div>
      {
        accountType === 1
        ? <TaxIndividal userProfile={userProfile} countryCodes={countryCodes} getFormFieldState={getFormFieldState} onChangeValidation={onChangeValidation} />
        : <TaxBusiness userProfile={userProfile} getFormFieldState={getFormFieldState} onChangeValidation={onChangeValidation} />
      }
    </>
  )
}

const BankSection = ({userProfile, onChangeValidation, getFormFieldState, countryCodes}) => {
  const { t } = useTranslation()

  // Only Support with Hong Kong Location
  const countryCodesMenu = React.useMemo(() => {
    return countryCodes.filter((countryCode) => countryCode.alpha_2 === "HK").map((countryCode) => <MenuItem id={countryCode.alpha_2} value={countryCode.alpha_2}>{countryCode.name}</MenuItem>)
  }, [countryCodes])

  return (
    <>
      <h1 className={styles.title}>{t('payment.financialInfo')}</h1>
      <div className={styles.bankSection}>
        <CustomSelect 
          id={bankLocationId}
          required
          disabled={userProfile.is_bank_tax_created}
          classes={cn(styles.input, styles.countryInput, styles.bankInput)}
          value={getFormFieldState(bankLocationId).value}
          onChange={(e) => onChangeValidation(bankLocationId, e.target.value)}
          label={t('payment.bankLocation')}
        >
            {
              countryCodesMenu
            }
        </CustomSelect>
      </div>
      <div className={styles.bankSection}>
        <FormTextField
          id={bankNameId}
          fieldData={getFormFieldState(bankNameId)}
          disabled={userProfile.is_bank_tax_created}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.bankName')}
          type="text"
          onChange={onChangeValidation}
        />
        <FormTextField
          id={bankCodeId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(bankCodeId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.bankCode')}
          type="text"
          onChange={onChangeValidation}
          InputProps={{
            endAdornment: <InputAdornment position="en">
              <CustomPopper
                title={t('payment.bankHint')}
              >
                <InfoIcon className={styles.infoIcon} /> 
              </CustomPopper>
            </InputAdornment>,
          }}
        />
        {/* <FormTextField
          id={branchCodeId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(branchCodeId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.branchCode')}
          type="text"
          onChange={onChangeValidation}
          InputProps={{
            endAdornment: <InputAdornment position="en">
              <CustomPopper
                title={t('payment.branchCodeHint')}
              >
                <InfoIcon className={styles.infoIcon} /> 
              </CustomPopper>
            </InputAdornment>,
          }}
        /> */}
        <FormTextField
          id={accountNameId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(accountNameId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.accountName')}
          type="text"
          onChange={onChangeValidation}
        />
        <FormTextField
          id={accountNumberId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(accountNumberId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.accountNumber')}
          type="text"
          InputProps={{
            endAdornment: <InputAdornment position="en">
              <CustomPopper
                title={t('payment.accountNumberHint')}
              >
                <InfoIcon className={styles.infoIcon} /> 
              </CustomPopper>
            </InputAdornment>,
          }}
          onChange={onChangeValidation}
        />
      </div>
      {/* <div className={styles.bankSection}>
        <FormTextField
          id={accountNumberId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(accountNumberId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.accountNumber')}
          type="text"
          InputProps={{
            endAdornment: <InputAdornment position="en">
              <CustomPopper
                title={t('payment.accountNumberHint')}
              >
                <InfoIcon className={styles.infoIcon} /> 
              </CustomPopper>
            </InputAdornment>,
          }}
          onChange={onChangeValidation}
        />
        <FormTextField
          id={swiftCodeId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(swiftCodeId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.swiftCode')}
          type="text"
          onChange={onChangeValidation}
        />
        <FormTextField
          id={branchNameId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(branchNameId)}
          className={cn(styles.input, styles.bankInput)}
          label={t('payment.branchName')}
          type="text"
          onChange={onChangeValidation}
        />
      </div> */}

      {/* <div className={styles.bankSection}>
        <FormTextField
          id={bankAddressId}
          disabled={userProfile.is_bank_tax_created}
          fieldData={getFormFieldState(bankAddressId)}
          className={cn(styles.input)}
          label={t('payment.bankAddress')}
          type="text"
          onChange={onChangeValidation}
        />
      </div> */}
    </>
  )
}

const EditBankTaxSection = () => {
  const { t } = useTranslation()
  const userStore = useUserStore()
  const authStore = useAuthStore()
  const { showSuccess } = useSnackbar()
  const { language } = useI18next()

  const [countryCodes, setCountryCodes] = React.useState([])

  const { onChangeValidation, getFormFieldState, requestAsyc, isFormReady, isLoading, formErrorCode, initForm } = useForm({
    [bankLocationId]: {
      validators: [EmptyValidator],
    },
    [bankNameId]: {
      validators: [EmptyValidator],
    },
    [bankCodeId]: {
      validators: [EmptyValidator],
    },
    // [branchCodeId]: {
    //   validators: [EmptyValidator],
    // },
    [accountNameId]: {
      validators: [EmptyValidator],
    },
    [accountNumberId]: {
      validators: [EmptyValidator],
    },
    // [swiftCodeId]: {
    //   validators: [EmptyValidator],
    // },
    // [branchNameId]: {
    //   validators: [EmptyValidator],
    // },
    [bankAddressId]: {
      validators: [EmptyValidator],
    },
    [accountTypeId]: {
      validators: [EmptyValidator],
    },
    [nationalityId]: {
      validators: [EmptyValidator],
    },
    [idTypeId]: {
      validators: [EmptyValidator],
    },
    [idNumberId]: {
      validators: [EmptyValidator],
    },
    [nameOnIdId]: {
      validators: [EmptyValidator],
    },
    [countryCodeId]: {
      validators: [EmptyValidator],
    },
    [phoneNumberId]: {
      validators: [EmptyValidator],
    },
    [genderId]: {
      validators: [EmptyValidator],
    },
    [dateOfBirthId]: {
      validators: [EmptyValidator],
    },
    [residentialAddressId]: {
      validators: [EmptyValidator],
    },
    [businessLicenseIdId]: {
      validators: [EmptyValidator],
    },
    [taxIdId]: {
      validators: [EmptyValidator],
    },
    [checkBoxId]: {
      validators: [EmptyValidator],
    },
  }, [{
      isCondition: false,
      deps: [
        bankLocationId, 
        bankNameId, 
        bankCodeId, 
        // branchCodeId, 
        accountNameId,
        accountNumberId, 
        // swiftCodeId, 
        // branchNameId, 
        // bankAddressId, 
        checkBoxId],
    }, {
      isCondition: true,
      rule: {
        targetFieldId: accountTypeId,
        condition: 2, //'businessAccount',
      },
      deps: [businessLicenseIdId, taxIdId],
    }, {
      isCondition: true,
      rule: {
        targetFieldId: accountTypeId,
        condition: 1, //'individual',
      },
      deps: [nationalityId, idTypeId, idNumberId, nameOnIdId, countryCodeId, phoneNumberId, genderId, dateOfBirthId, residentialAddressId],
    }],
  )

  const userProfile = userStore.getUserProfile()
  const bankAndTax = userStore.getBankAndTax()

  React.useEffect(async () => {
    if (authStore.isInitial()) {
      const result = await apiManager.getCountryCodes(language)
      setCountryCodes(result.get_country_list)
    }
  }, [authStore.isInitial()])

  React.useEffect(() => {
    if (bankAndTax) {
      let initState = {
        [bankLocationId]: {
          value: bankAndTax.bank.country_code,
          isChanged: true,
        },
        [bankNameId]: {
          value: bankAndTax.bank.bank_name,
          isChanged: true,
        },
        [bankCodeId]: {
          value: bankAndTax.bank.bank_code,
          isChanged: true,
        },
        // [branchCodeId]: {
        //   value: bankAndTax.bank.branch_code,
        //   isChanged: true,
        // },
        [accountNameId]: {
          value: bankAndTax.bank.account_name,
          isChanged: true,
        },
        [accountNumberId]: {
          value: bankAndTax.bank.account_number,
          isChanged: true,
        },
        // [swiftCodeId]: {
        //   value: bankAndTax.bank.swift_code,
        //   isChanged: true,
        // },
        // [branchNameId]: {
        //   value: bankAndTax.bank.branch_name,
        //   isChanged: true,
        // },
        // [bankAddressId]: {
        //   value: bankAndTax.bank.bank_address,
        //   isChanged: true,
        // },
      }

      if (bankAndTax.tax.account_type === 1) {
        initState = {
          ...initState,
          [accountTypeId]: {
            value: 1,
            isChanged: true,
          },
          [nationalityId]: {
            value: bankAndTax.tax.nationality,
            isChanged: true,
          },
          [idTypeId]: {
            value: bankAndTax.tax.id_type,
            isChanged: true,
          },
          [idNumberId]: {
            value: bankAndTax.tax.id_number,
            isChanged: true,
          },
          [nameOnIdId]: {
            value: bankAndTax.tax.name_on_id,
            isChanged: true,
          },
          [countryCodeId]: {
            value: bankAndTax.tax.country_code,
            isChanged: true,
          },
          [phoneNumberId]: {
            value: bankAndTax.tax.phone_number,
            isChanged: true,
          },
          [genderId]: {
            value: bankAndTax.tax.gender,
            isChanged: true,
          },
          [dateOfBirthId]: {
            value: bankAndTax.tax.date_of_birth,
            isChanged: true,
          },
          [residentialAddressId]: {
            value: bankAndTax.tax.residential_address,
            isChanged: true,
          }
        }
      } else {
        initState = {
          ...initState,
          [accountTypeId]: {
            value: 2,
            isChanged: true,
          },
          [businessLicenseIdId]: {
            value: bankAndTax.tax.business_license_id,
            isChanged: true,
          },
          [taxIdId]: {
            value: bankAndTax.tax.tax_id,
            isChanged: true,
          }
        }
      }
      initForm(initState)
    }
  }, [bankAndTax])

  React.useEffect(() => {
    if (userProfile && authStore.isLoggedIn()) {
      if (userProfile.is_bank_tax_created) {
        userStore.fetchUserBankNTax()
      } else {
        initForm({
          [accountTypeId]: {
            value: 1,
            isChanged: true,
          }
        })
      }
    }
  }, [userProfile, authStore.isLoggedIn()])

  const triggerCreateBankAndTax = React.useCallback(async () => {

    let tax = {
      account_type: getFormFieldState(accountTypeId).value,
    }

    if (tax.account_type === 1) {
      tax = {
        account_type: "Individual",
        id_type: getFormFieldState(idTypeId).value,
        id_number: getFormFieldState(idNumberId).value,
        nationality: getFormFieldState(nationalityId).value,
        name_on_id: getFormFieldState(nameOnIdId).value,
        country_code: getFormFieldState(countryCodeId).value,
        phone_number: getFormFieldState(phoneNumberId).value,
        gender: getFormFieldState(genderId).value,
        date_of_birth: getFormFieldState(dateOfBirthId).value,
        residential_address: getFormFieldState(residentialAddressId).value,
      }
    } else {
      tax = {
        account_type: "Business",
        business_license_id: getFormFieldState(businessLicenseIdId).value,
        tax_id: getFormFieldState(taxIdId).value,
      }
    }

    const response = await requestAsyc(userStore.createBankAndTax({
      bank: {
        account_name: getFormFieldState(accountNameId).value,
        account_number: getFormFieldState(accountNumberId).value,
        country_code: getFormFieldState(bankLocationId).value,
        bank_name: getFormFieldState(bankNameId).value,
        // swift_code: getFormFieldState(swiftCodeId).value,
        // branch_code: getFormFieldState(branchCodeId).value,
        bank_code: getFormFieldState(bankCodeId).value,
        // branch_name: getFormFieldState(branchNameId).value,
        // bank_address: getFormFieldState(bankAddressId).value,
      },
      tax
    }))
    if (!response.error) {
      showSuccess(t('common.success'))
    }
  })

  const isShow = userProfile && (!userProfile.is_bank_tax_created || (userProfile.is_bank_tax_created && bankAndTax));

  return (
    !isShow
    ? <></>
    : <Form className={styles.form} isLoading={isLoading}>
        <BankSection userProfile={userProfile} onChangeValidation={onChangeValidation} getFormFieldState={getFormFieldState} countryCodes={countryCodes} />
        <TaxSection userProfile={userProfile} onChangeValidation={onChangeValidation} getFormFieldState={getFormFieldState} countryCodes={countryCodes} />
        <FormControlLabel
          control={
            <Checkbox
              id={checkBoxId}
              disabled={userProfile.is_bank_tax_created}
              checked={getFormFieldState(checkBoxId).value}
              onChange={(e) => onChangeValidation(checkBoxId, e.target.checked)} 
              name={checkBoxId}
            />
          }
          label={t('payment.individualCheckBox')}
        />
        <p className={cn(styles.errorMsg)}>{formErrorCode ? `*${t(`errorCodes.${formErrorCode}`)}` : ''}</p>
        {
          !userProfile.is_bank_tax_created && <FillButton 
          className={cn(styles.btnUpdate)}
          disabled={!isFormReady()}
          onClick={triggerCreateBankAndTax}
        >
            {t('common.submit')}
          </FillButton>
        }
      </Form>
  )
}

export default EditBankTaxSection