import * as React from 'react'
import Page from 'components/presenter/page/Page'
import { Paper } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import EditProfileSection from 'components/container/page/profile/EditProfileSection'
import EditPasswordSection from 'components/container/page/profile/EditPasswordSection'
import * as styles from './profile.module.css'

const ProfilePage = () => {
  const { t } = useTranslation()
  return (
    <Page className={styles.root} seoMeta={{
      title: t('profile.yourProfile')
    }}>
      <Paper className={styles.paper} elevation={2}>
        <EditProfileSection />
      </Paper>
      <Paper className={styles.paper} elevation={2}>
        <EditPasswordSection />
      </Paper>
    </Page>
  )
}

export default ProfilePage