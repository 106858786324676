import React from 'react'
import MaxCashbackText from '../MaxCashbackText'

const MaxCashbackReward = ({ rewards }) => {
    const reward = rewards.filter(reward => reward.cashback_type === 1)
        .reduce((max, current) => {
            const currentValue = current.cashback_value_changed || current.cashback_value;
            const maxValue = max.cashback_value_changed || max.cashback_value;
            return currentValue > maxValue ? current : max;
        }, { cashback_value: 0, cashback_value_changed: 0 });
    return (
      <MaxCashbackText reward={reward} />
    )
}

export default MaxCashbackReward