// import { captureException, configureScope } from '@sentry/browser'
const Sentry = require('@sentry/react')
const { callAPI } = require('..')

function handleResponse(query, res) {
  // Graphql result is wrapped in a *data* layer by default
  if (res && res.data){
    // If the result is empty,
    // the request has triggered schema error in BE,
    // send exception to sentry
    if (!Object.keys(res.data).length) {
      const errorMsg = 'GraphQL schema error'

      Sentry.configureScope((scope) => {
        scope.setExtra('query', query)
      })
      Sentry.captureException(new Error(errorMsg))
      return {
        error: {
          payload: errorMsg,
        },
      }
    }

    // Otherwise return result
    return res.data
  }

  return res
}
exports.handleResponse = handleResponse

function generateFileFormData (query, variables, fileObj) {
  const formData = new FormData()
  const operations = {
    query,
    variables: {
      ...variables,
    }
  }
  const map = {}
  if (fileObj) {
    if (fileObj.file) {
      operations.variables[fileObj.fieldName] = null
      const variableArr = [`variables.${fileObj.fieldName}`]
      map[0] = variableArr
    } else if (fileObj.files) {
      // TODO: Workarround solution for graphql array [Upload]
      operations.variables[fileObj.fieldName] = fileObj.files.map(file => !!file)
      fileObj.files.forEach((item, index) => {
        const variableArr = [`variables.${fileObj.fieldName}.${index}`]
        map[index] = variableArr
      })
    }
  }
  
  formData.append("operations", JSON.stringify(operations))
  formData.append("map", JSON.stringify(map))

  if (fileObj) {
    if (fileObj.file) {
      formData.append(0, fileObj.file)
    } else if (fileObj.files) {
      fileObj.files.forEach((item, index) => {
        formData.append(index, item)
      })
    }
  }
  return formData
}

const apiDomain = process.env.GATSBY_WENJETSO_API_DOMAIN || ''
const endPoint = `${apiDomain}${process.env.GATSBY_GRAPH_PATH}`

exports.postPortalGQL = async (query, variables, token, options) => {
  const res = await callAPI(endPoint, {
    query,
    variables,
  }, token, options)
  return handleResponse(query, res)
}

// fileObj = {files, fieldName}
exports.postPortalGQLFile = async (query, variables, fileObj, token, options) => {
  const formData = generateFileFormData(query, variables, fileObj)
  const res = await callAPI(endPoint, formData, token, options)
  return handleResponse(query, res)
}
