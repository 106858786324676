import React from 'react'
import { 
  Select,
  MenuItem,
} from '@material-ui/core'
import { setDateLocale } from 'libs/date/dateUtil'
import { useI18next } from 'gatsby-plugin-react-i18next'


const languageMenu = [{
  locale: 'en_US',
  name: 'English'
}, {
  locale: 'zh_HK',
  name: '繁體中文'
}]

const LanguageSelect = ({onChange}) => {
  const [open, setOpen] = React.useState(false)
  const {language, changeLanguage} = useI18next()

  const handleCloseLanguageMenu = () => {
    setOpen(false)
  }

  const handleOpenLanguageMenu = () => {
    setOpen(true)
  }

  const handleChangeLanguage = (event) => {
    setDateLocale(event.target.value)
    changeLanguage(event.target.value)
    if (onChange) {
      onChange()
    }
  }

  return (
    <Select
      className='mui-select'
      labelId="select-language"
      id="select-language"
      open={open}
      onClose={handleCloseLanguageMenu}
      onOpen={handleOpenLanguageMenu}
      value={language}
      onChange={handleChangeLanguage}
      disableUnderline
    >
      {
        languageMenu.map((menu) => (
          <MenuItem key={menu.locale} value={menu.locale}>{menu.name}</MenuItem>
        ))
      }
    </Select>
  )
}
export default LanguageSelect