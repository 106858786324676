import { apiManager } from 'api'

const setUserProfile = (payload) => {
    return (state) => ({ 
        ...state,
        user: {
            ...state.user,
            profile: {
                ...state.user.profile,
                ...payload
            }
        }
    })
}

const setCampaignPass = (payload) => {
    return (state) => ({
        ...state,
        user: {
            ...state.user,
            campaignPass: payload
        }
    })
}

const setBankAndTax = (payload) => {
    return (state) => ({ 
        ...state,
        user: {
            ...state.user,
            bankAndTax: {
                ...state.user.bankAndTax,
                ...payload
            }
        }
    })
}

export const createUserSlice = (set, get) => ({
    user: {
        profile: null,
        clear: () => {
            set(setUserProfile(null))
            set(setCampaignPass(null))
            set(setBankAndTax(null))
        },
        getUserProfile: () => get().user.profile,
        getCampaignPass: () => get().user.campaignPass,
        getBankAndTax: () => get().user.bankAndTax,
        fetchProfile: async () => {
            const response = await apiManager.fetchUser()
            if (!response.error) {
              const payload = response.get_user_profile
              set(setUserProfile(payload))
            }
            return response
        },
        fetchUserInformation: async () => {
            const response = await apiManager.fetchUserInformation()
            if (!response.error) {
                const userProfilePayload = response.get_user_profile
                const campaignPassPayload = response.get_my_campaign_pass
                set(setUserProfile(userProfilePayload))
                set(setCampaignPass(campaignPassPayload))
            }
            return response
        },
        fetchMyCampaignPass: async (lang) => {
            const response = await apiManager.fetchMyCampaignPass({lang})
            if (!response.error) {
                const payload = response.get_my_campaign_pass
                set(setCampaignPass(payload))
            }
            return response
        },
        fetchUserBankNTax: async () => {
            const response = await apiManager.fetchUserBankNTax()
            if (!response.error) {
              const payload = response.get_user_bank_n_tax
              set(setBankAndTax(payload))
            }
            return response
        },
        updateProfile: async (fullName) => {
            const response = await apiManager.updateProfile(fullName)
            if (!response.error) {
                const payload = response.update_user_profile
                set(setUserProfile(payload))
            }
            return response
        },
        uploadProfileImage: async (file) => {
            const response = await apiManager.uploadProfileImage(file)
            if (!response.error) {
              const payload = response.update_user_profile
              set(setUserProfile(payload))
            }
            return response
        },
        updateUserPassword: async (input) => {
            return await apiManager.updateUserPassword(input)
        },
        createBankAndTax: async (input) => {
            const response = await apiManager.createBankAndTax(input)
            if (!response.error) {
              const payload = response.create_bank_and_tax
              set(setUserProfile({
                is_bank_tax_created: true
              }))
              set(setBankAndTax(payload))
            }
            return response
        }
    }
})

